import { ACTIONS } from "../contexts/quizState";

const quizReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_STATE: {
      return action.payload;
    }

    case ACTIONS.SET_RESPONSES: {
      const { quizResponses } = action.payload;

      return { ...state, quizResponses };
    }

    case ACTIONS.SET_SHOULD_SAVE_FOR_NEW_USER: {
      const { shouldSaveForNewUser } = action.payload;

      return { ...state, shouldSaveForNewUser };
    }

    case ACTIONS.SET_PERCENT_COMPLETE: {
      const { quizPercentComplete } = action.payload;

      return { ...state, quizPercentComplete };
    }

    case ACTIONS.SET_SKIN_TONE: {
      return {
        ...state,
        quizResponses: {
          ...state.quizResponses,
          skinTone: action.payload.skinTone,
        },
      };
    }

    case ACTIONS.SET_EYE_COLOR: {
      return {
        ...state,
        quizResponses: {
          ...state.quizResponses,
          eyeColor: action.payload.eyeColor,
        },
      };
    }

    case ACTIONS.SET_HAIR_COLOR: {
      return {
        ...state,
        quizResponses: {
          ...state.quizResponses,
          hairColor: action.payload.hairColor,
        },
      };
    }

    case ACTIONS.SET_SKIN_TYPE: {
      return {
        ...state,
        quizResponses: {
          ...state.quizResponses,
          skinType: action.payload.skinType,
        },
      };
    }

    case ACTIONS.SET_SKIN_CONCERN: {
      return {
        ...state,
        quizResponses: {
          ...state.quizResponses,
          skinConcerns: [
            ...state.quizResponses.skinConcerns,
            action.payload.skinConcern,
          ],
        },
      };
    }

    case ACTIONS.SET_MAKEUP_LEVEL: {
      return {
        ...state,
        quizResponses: {
          ...state.quizResponses,
          makeupLevel: action.payload.makeupLevel,
        },
      };
    }

    case ACTIONS.SET_MAKEUP_STYLE: {
      return {
        ...state,
        quizResponses: {
          ...state.quizResponses,
          makeupStyle: action.payload.makeupStyle,
        },
      };
    }

    case ACTIONS.SET_FAVORITE_MAKEUP: {
      return {
        ...state,
        quizResponses: {
          ...state.quizResponses,
          favoriteMakeup: [
            ...state.quizResponses.favoriteMakeup,
            action.payload.favoriteMakeup,
          ],
        },
      };
    }

    case ACTIONS.SET_FAVORITE_SKINCARE: {
      return {
        ...state,
        quizResponses: {
          ...state.quizResponses,
          favoriteSkincare: [
            ...state.quizResponses.favoriteSkincare,
            action.payload.favoriteSkincare,
          ],
        },
      };
    }

    case ACTIONS.SET_FAVORITE_HAIRCARE: {
      return {
        ...state,
        quizResponses: {
          ...state.quizResponses,
          favoriteHaircare: [
            ...state.quizResponses.favoriteHaircare,
            action.payload.favoriteHaircare,
          ],
        },
      };
    }

    case ACTIONS.SET_FAVORITE_TOOL: {
      return {
        ...state,
        quizResponses: {
          ...state.quizResponses,
          favoriteTools: [
            ...state.quizResponses.favoriteTools,
            action.payload.favoriteTool,
          ],
        },
      };
    }

    case ACTIONS.SET_MONTHLY_BEAUTY_BUDGET: {
      return {
        ...state,
        quizResponses: {
          ...state.quizResponses,
          monthlyBeautyBudget: action.payload.monthlyBeautyBudget,
        },
      };
    }

    case ACTIONS.SET_ETHNICITY: {
      return {
        ...state,
        quizResponses: {
          ...state.quizResponses,
          ethnicity: action.payload.ethnicity,
        },
      };
    }

    case ACTIONS.SET_REFERRER: {
      return {
        ...state,
        quizResponses: {
          ...state.quizResponses,
          referrers: [
            ...state.quizResponses.referrers,
            action.payload.referrer,
          ],
        },
      };
    }

    case ACTIONS.REMOVE_SKIN_CONCERN: {
      return {
        ...state,
        quizResponses: {
          ...state.quizResponses,
          skinConcerns: state.quizResponses.skinConcerns.filter(
            (skinConcern) => skinConcern !== action.payload.skinConcern
          ),
        },
      };
    }

    case ACTIONS.REMOVE_FAVORITE_MAKEUP: {
      return {
        ...state,
        quizResponses: {
          ...state.quizResponses,
          favoriteMakeup: state.quizResponses.favoriteMakeup.filter(
            (favoriteMakeup) => favoriteMakeup !== action.payload.favoriteMakeup
          ),
        },
      };
    }

    case ACTIONS.REMOVE_FAVORITE_SKINCARE: {
      return {
        ...state,
        quizResponses: {
          ...state.quizResponses,
          favoriteSkincare: state.quizResponses.favoriteSkincare.filter(
            (favoriteSkincare) =>
              favoriteSkincare !== action.payload.favoriteSkincare
          ),
        },
      };
    }

    case ACTIONS.REMOVE_FAVORITE_HAIRCARE: {
      return {
        ...state,
        quizResponses: {
          ...state.quizResponses,
          favoriteHaircare: state.quizResponses.favoriteHaircare.filter(
            (favoriteHaircare) =>
              favoriteHaircare !== action.payload.favoriteHaircare
          ),
        },
      };
    }

    case ACTIONS.REMOVE_FAVORITE_TOOL: {
      return {
        ...state,
        quizResponses: {
          ...state.quizResponses,
          favoriteTools: state.quizResponses.favoriteTools.filter(
            (favoriteTool) => favoriteTool !== action.payload.favoriteTool
          ),
        },
      };
    }

    case ACTIONS.REMOVE_REFERRER: {
      return {
        ...state,
        quizResponses: {
          ...state.quizResponses,
          referrers: state.quizResponses.referrers.filter(
            (referrer) => referrer !== action.payload.referrer
          ),
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default quizReducer;
