import { useState, useEffect } from "react";

export const usePathname = () => {
  const [pathname, setPathname] = useState("");

  const urlLocation = typeof window !== "undefined" ? window.location : null;

  useEffect(() => {
    if (urlLocation) {
      setPathname(urlLocation.pathname);
    }
  }, [urlLocation]);

  return {
    pathname,
  };
};
