import React, { useState, useEffect } from "react";
import { useGlobal } from "../services/hooks/useGlobal";
import roundNumber from "../util/roundNumber";

const TopLoadingBar = React.memo(() => {
  const { numApiRequestsPending, numApiRequestsCompleted } = useGlobal();
  const [percentComplete, setPercentComplete] = useState(0);

  useEffect(() => {
    if (numApiRequestsPending && numApiRequestsCompleted) {
      setPercentComplete(
        roundNumber((numApiRequestsCompleted / numApiRequestsPending) * 100)
      );
    }
  }, [numApiRequestsPending, numApiRequestsCompleted]);

  if (!numApiRequestsPending) {
    return null;
  }

  return (
    <div className="progress sticky-top">
      <div
        className="progress-bar bg-dark"
        role="progressbar"
        style={{ width: `${percentComplete}%` }}
        aria-valuenow={percentComplete}
        aria-valuemin={0}
        aria-valuemax={100}
      />
    </div>
  );
});

export default TopLoadingBar;
