import { ACTIONS } from "../contexts/trackingState";

const trackingReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_STATE: {
      return action.payload;
    }

    case ACTIONS.SET_TRACKING_ID: {
      const { trackingId } = action.payload;

      return { ...state, trackingId };
    }

    case ACTIONS.SET_IS_TRACKING: {
      const { isTracking } = action.payload;

      return { ...state, isTracking };
    }

    case ACTIONS.SET_TRACKING_ERROR: {
      const { trackingError } = action.payload;

      return { ...state, trackingError };
    }

    case ACTIONS.SET_TRACKING_INFO: {
      const { trackingInfo } = action.payload;

      return { ...state, trackingInfo };
    }

    case ACTIONS.SET_ACTIVELY_TRACKED_SHIPMENT: {
      const { activelyTrackedShipment } = action.payload;

      return { ...state, activelyTrackedShipment };
    }

    case ACTIONS.SET_ACTIVELY_TRACKED_SHIPMENT_INDEX: {
      const { activelyTrackedShipmentIndex } = action.payload;

      return { ...state, activelyTrackedShipmentIndex };
    }

    default:
      return state;
  }
};

export default trackingReducer;
