import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../state/contexts/authState";
import { useApiCall } from "./useApiCall";
import isValidUser from "../../util/isValidUser";
import isValidToken from "../../util/isValidToken";

export const useSignUp = () => {
  const { setUser, setAccessToken } = useContext(AuthContext);
  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();
  const [signUpError, setSignUpError] = useState("");
  const [signUpStatus, setSignUpStatus] = useState("idle");

  const signUp = (data) => {
    const requestOptions = {
      url: "/users/create",
      data,
      method: "POST",
    };

    executeApiCall(requestOptions);

    setSignUpStatus("pending");
    setSignUpError();
  };

  useEffect(() => {
    switch (apiCallStatus) {
      case "success": {
        if (apiCallResponse?.data?.data) {
          const { user, token } = apiCallResponse?.data?.data;

          setSignUpError();

          if (isValidUser(user)) {
            setUser({
              user,
            });
          }

          if (isValidToken(token)) {
            setAccessToken({
              accessToken: token,
            });
          }
        } else {
          setSignUpError(apiCallResponse?.data?.error?.userMessage);
        }

        setSignUpStatus("ready");

        break;
      }

      case "error": {
        setSignUpStatus("ready");
      }
    }
  }, [apiCallResponse, apiCallStatus]);

  return {
    signUp,
    signUpError,
    signUpStatus,
  };
};
