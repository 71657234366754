import { useState, useEffect } from "react";
import { useApiCall } from "./useApiCall";

export const useLoyaltyInfo = () => {
  const [loyaltyInfo, setLoyaltyInfo] = useState();
  const [loyaltyInfoError, setLoyaltyInfoError] = useState();
  const [loyaltyInfoStatus, setLoyaltyInfoStatus] = useState("idle");

  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();

  const fetchLoyaltyInfo = (loyaltyId) => {
    if (loyaltyId) {
      const requestOptions = {
        url: `/loyalty/${loyaltyId}`,
      };

      executeApiCall(requestOptions);

      setLoyaltyInfoStatus("pending");
    }
  };

  useEffect(() => {
    switch (apiCallStatus) {
      case "success":
        if (apiCallResponse.data.data) {
          const { loyalty } = apiCallResponse.data.data;

          setLoyaltyInfo(loyalty);
          setLoyaltyInfoStatus("ready");
        } else {
          setLoyaltyInfoError(apiCallResponse.data.error.userMessage);
          setLoyaltyInfoStatus("ready");
        }
        break;
      case "error":
        setLoyaltyInfoStatus("ready");
        break;
    }
  }, [apiCallStatus, apiCallResponse]);

  return {
    loyaltyInfo,
    loyaltyInfoError,
    loyaltyInfoStatus,
    fetchLoyaltyInfo,
  };
};
