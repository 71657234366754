import React, { useState, useEffect } from "react";
import Link from "next/link";
import AnnouncementBar from "./AnnouncementBar";
import TopLoadingBar from "./TopLoadingBar";

import { useAuth } from "../services/hooks/useAuth";
import { useGlobal } from "../services/hooks/useGlobal";
import { useStoresList } from "../services/hooks/useStoresList";
import { useCart } from "../services/hooks/useCart";

export default React.memo(function NavBar() {
  const { cartItems } = useCart();
  const [cartItemsCount, setCartItemsCount] = useState(cartItems.length);
  const { user, logout, isSubscribed } = useAuth();
  const { showNav } = useGlobal();
  const { storesList, fetchStoresList } = useStoresList(true);

  useEffect(() => {
    setCartItemsCount(cartItems.length);
  }, [cartItems]);

  useEffect(() => {
    fetchStoresList();
  }, []);

  return (
    <div id="site-nav-header" className="sticky-top sticky-l2">
      {/*Ajax Indicator*/}
      <TopLoadingBar />
      {/*End Ajax Indicator*/}

      {/* <AnnouncementBar
        message={
          <Link href="/surveys/355149968172996399">
            <a className="text-center text-white h6">
              <span className="d-block">CHOICE IS OPEN!</span>
              <span className="d-block">Click here to customize your box.</span>
            </a>
          </Link>
        }
        bgColor="dark"
        showPattern
      /> */}

      {showNav && (
        <>
          {/* NavBar */}
          <div className="navbar navbar-topbar navbar-expand-xl navbar-light bg-primary">
            <div className="container">
              {/* Promo */}
              <div className="mr-xl-8">
                <i className="fe fe-truck mr-2" />{" "}
                <span className="heading-xxxs">
                  Free shipping on orders $100+
                </span>
              </div>
              {/* Toggler */}
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#topbarCollapse"
                aria-controls="topbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              {/* Collapse */}
              <div className="collapse navbar-collapse" id="topbarCollapse">
                {/* Nav */}
                <ul className="nav navbar-nav">
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://help.chicbeautybox.com"
                      target="_blank"
                    >
                      FAQ
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://help.chicbeautybox.com/portal/en/newticket"
                      target="_blank"
                    >
                      Contact
                    </a>
                  </li>
                </ul>
                {/* Nav */}
                <ul className="nav navbar-nav flex-row">
                  <li className="nav-item ml-xl-n4">
                    <a
                      className="nav-link"
                      href="https://www.instagram.com/chicbeauty.box"
                      target="_blank"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      )}

      {/* NavBar */}
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container">
          {/* Brand */}
          <Link href="/">
            <a className="navbar-brand">CHIC BEAUTY BOX</a>
          </Link>

          {showNav && (
            <>
              {/* Toggler */}
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              {/* Collapse */}
              <div className="collapse navbar-collapse" id="navbarCollapse">
                {/* Nav */}
                <ul className="navbar-nav mx-auto">
                  {!isSubscribed && (
                    <li className="nav-item">
                      <Link href="/quiz">
                        <a className="nav-link">Subscribe</a>
                      </Link>
                    </li>
                  )}

                  <li className="nav-item dropdown">
                    <a className="nav-link" data-toggle="dropdown" href="#">
                      Shop
                    </a>
                    <div className="dropdown-menu">
                      <div className="card card-lg">
                        <div className="card-body">
                          <ul className="list-styled font-size-sm">
                            {storesList &&
                              storesList.map((store) => (
                                <li key={store.id} className="list-styled-item">
                                  <Link href={`/stores/${store.id}`}>
                                    <a className="list-styled-link">
                                      {store.name}
                                    </a>
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item">
                    <Link href="/boxes">
                      <a className="nav-link">Past Boxes</a>
                    </Link>
                  </li>

                  {!user && (
                    <li className="nav-item">
                      <Link href="/#how-it-works">
                        <a className="nav-link">How it Works</a>
                      </Link>
                    </li>
                  )}

                  {user && (
                    <li className="nav-item">
                      <Link href="/rewards">
                        <a className="nav-link">Earn Rewards</a>
                      </Link>
                    </li>
                  )}

                  {!user && (
                    <li className="nav-item">
                      <Link href="/login">
                        <a className="nav-link">Login</a>
                      </Link>
                    </li>
                  )}
                </ul>
                {/* Nav */}
                <ul className="navbar-nav flex-row">
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="modal"
                      href="#search-modal"
                    >
                      <i className="fe fe-search" />
                    </a>
                  </li>

                  {user ? (
                    <li className="nav-item dropdown ml-lg-n4">
                      <a className="nav-link" data-toggle="dropdown" href="#">
                        <i className="fe fe-user" />
                      </a>

                      <div className="dropdown-menu">
                        <div className="card card-lg">
                          <div className="card-body">
                            <ul className="list-styled font-size-sm">
                              <li className="list-styled-item">
                                <Link href="/account/personal">
                                  <a className="list-styled-link">Profile</a>
                                </Link>
                              </li>
                              <li className="list-styled-item">
                                <Link href="/account/choices">
                                  <a className="list-styled-link">
                                    Choice History
                                  </a>
                                </Link>
                              </li>
                              <li className="list-styled-item">
                                <Link href="/account/subscriptions">
                                  <a className="list-styled-link">
                                    Subscriptions
                                  </a>
                                </Link>
                              </li>
                              <li className="list-styled-item">
                                <Link href="/account/orders">
                                  <a className="list-styled-link">Orders</a>
                                </Link>
                              </li>
                              <li className="list-styled-item">
                                <Link href="/account/addresses">
                                  <a className="list-styled-link">
                                    Address Book
                                  </a>
                                </Link>
                              </li>
                              <li className="list-styled-item">
                                <Link href="/account/wallet">
                                  <a className="list-styled-link">Wallet</a>
                                </Link>
                              </li>
                              <li className="list-styled-item">
                                <a
                                  className="list-styled-link"
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    logout();
                                  }}
                                >
                                  Logout
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                  ) : (
                    <li className="nav-item dropdown ml-lg-n4">
                      <a className="nav-link" data-toggle="dropdown" href="#">
                        <i className="fe fe-user" />
                      </a>

                      <div className="dropdown-menu">
                        <div className="card card-lg">
                          <div className="card-body">
                            <ul className="list-styled font-size-sm">
                              <li className="list-styled-item">
                                <Link href="/login">
                                  <a className="list-styled-link">Login</a>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                  )}

                  <li className="nav-item ml-lg-n4">
                    <a
                      className="nav-link"
                      data-toggle="modal"
                      href="#modalShoppingCart"
                    >
                      <span data-cart-items={cartItemsCount}>
                        <i className="fe fe-shopping-cart" />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      </nav>
    </div>
  );
});
