import React from "react";
import { useCart } from "../services/hooks/useCart";
import CartItem from "./CartItem";

const CartItems = React.memo(({ flushX = true, flushY = false }) => {
  const { cartItems } = useCart();

  return (
    <ul
      className={`list-group list-group-lg ${
        flushX ? "list-group-flush-x" : ""
      } ${flushY ? "list-group-flush-y" : ""}  mb-7`}
    >
      {cartItems.map((cartItem, index) => (
        <CartItem key={index} {...cartItem} />
      ))}
    </ul>
  );
});

export default CartItems;
