import { useContext } from "react";
import { SnackbarContext } from "../../state/contexts/snackbarState";
import generateGenericId from "../../util/generateGenericId";

export const useSnackbar = () => {
  const { add, remove, update, snackbars } = useContext(SnackbarContext);

  const addSnackbar = (message, options = {}) => {
    const snackbarId = options?.id ?? generateGenericId();

    options.allowDuplicates = false || options?.allowDuplicates;

    add({
      id: snackbarId,
      message,
      options,
    });

    return snackbarId;
  };

  const removeSnackbar = (snackbarId) => {
    remove({
      id: snackbarId,
    });
  };

  const updateSnackbar = (snackbarId, options) => {
    update({
      id: snackbarId,
      options,
    });
  };

  return {
    addSnackbar,
    removeSnackbar,
    updateSnackbar,
    snackbars,
  };
};
