import getCookie from "../../util/getCookie";
import setCookie from "../../util/setCookie";
import deleteCookie from "../../util/deleteCookie";

export const useCookie = () => {

  return {
    getCookie,
    setCookie,
    deleteCookie,
  };
};
