import { useState, useRef, useEffect } from "react";
import time from "../time/time";

export const useTimeout = (cb, timeoutDelayMs) => {
  const [isTimeoutActive, setIsTimeoutActive] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const [startTime, setStartTime] = useState();
  const [remainingTime, setRemainingTime] = useState();
  const savedRefCallback = useRef();
  const isTimeoutActiveRef = useRef(isTimeoutActive);

  const startTimeout = () => {
    setIsTimeoutActive(true);
  };

  const stopTimeout = () => {
    setIsTimeoutActive(false);
  };

  const pauseTimeout = () => {
    stopTimeout();

    setRemainingTime(timeoutDelayMs - (time().unix() - startTime) * 1000);
  };

  const resumeTimeout = () => {
    startTimeout();
  };

  const callback = () => {
    if (isTimeoutActive) {
      savedRefCallback.current && savedRefCallback.current();

      stopTimeout();
    }
  };

  useEffect(() => {
    savedRefCallback.current = cb;
  }, [cb]);

  useEffect(() => {
    isTimeoutActiveRef.current = isTimeoutActive;
  }, [isTimeoutActive]);

  useEffect(() => {
    if (isTimeoutActive) {
      setStartTime(time().unix());

      const id = setTimeout(callback, remainingTime ?? timeoutDelayMs);

      setTimerId(id);

      return () => {
        clearTimeout(timerId);
      };
    } else if (timerId) {
      clearTimeout(timerId);

      setTimerId(null);
    }
  }, [isTimeoutActive, timeoutDelayMs]);

  return {
    stopTimeout,
    startTimeout,
    pauseTimeout,
    resumeTimeout,
    isTimeoutActiveRef,
    isTimeoutActive,
    timerId,
  };
};
