import { cloneDeep } from "lodash";

export default function setActiveContentOptionIsSelected({
  survey,
  activeContentId,
  optionId,
  isSelected,
}) {
  let optionChanged = false;

  survey.contents = survey.contents.map((content) => {
    if (content.id === activeContentId) {
      if (Array.isArray(content.options)) {
        content.options = content.options.map((option) => {
          if (option.id === optionId && option.isSelectable) {
            option.isSelected = isSelected;
            optionChanged = true;
          }

          return option;
        });

        if (!content.allowMultipleSelection && optionChanged) {
          content.options = content.options.map((option) => {
            if (option.id !== optionId) {
              option.isSelected = false;
            }

            return option;
          });
        }
      }
    }

    return content;
  });

  return cloneDeep(survey);
}
