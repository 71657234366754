import React, { createContext, useReducer, useEffect } from "react";
import accountReducer from "../reducers/accountReducer";
import { useLoyaltyInfo } from "../../services/hooks/useLoyaltyInfo";
import { useAuth } from "../../services/hooks/useAuth";
import { useQuiz } from "../../services/hooks/useQuiz";

//Actions
export const ACTIONS = {
  SET_STATE: "SetState",
  SET_TOTAL_REFERRALS: "SetTotalReferrals",
  SET_PERCENT_COMPLETE: "SetPercentComplete",
  SET_LOYALTY_POINTS: "SetLoyaltyPoints",
};

// Initial state
const initialState = {
  totalReferrals: 0,
  preferencesPercentComplete: 0,
  loyaltyPoints: 0,
};

// Create context
export const AccountContext = createContext(initialState);

// Provider component
export const AccountProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const { quizPercentComplete, fetchPreferences } = useQuiz();
  const { loyaltyInfo, fetchLoyaltyInfo } = useLoyaltyInfo();
  const { user, authStatus } = useAuth();

  const setTotalReferrals = (payload) => {
    dispatch({
      type: ACTIONS.SET_TOTAL_REFERRALS,
      payload,
    });
  };

  const setLoyaltyPoints = (payload) => {
    dispatch({
      type: ACTIONS.SET_LOYALTY_POINTS,
      payload,
    });
  };

  const resetAccount = () => {
    dispatch({
      type: ACTIONS.SET_STATE,
      payload: initialState,
    });
  };

  useEffect(() => {
    if (user && authStatus === "ready") {
      fetchLoyaltyInfo(user.loyaltyId);
    }
  }, [user, authStatus]);

  useEffect(() => {
    setTotalReferrals({
      totalReferrals: loyaltyInfo?.referred?.length ?? 0,
    });
  }, [loyaltyInfo]);

  useEffect(() => {
    setLoyaltyPoints({
      loyaltyPoints: user?.loyaltyPoints ?? 0,
    });
  }, [user?.loyaltyPoints]);

  const value = {
    ...state,
    quizPercentComplete,
    fetchPreferences,
    resetAccount,
  };

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
};
