import React, { useState } from "react";
import PhoneNumberInput from "../../components/PhoneNumberInput";

export const usePhoneNumberInput = ({
  required = false,
  placeholder = "Phone number",
  defaultValue = "",
  onBlur,
  onChange,
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(!required);

  const phoneNumberInput = (
    <PhoneNumberInput
      required={required}
      setPhoneNumber={setPhoneNumber}
      setValidity={setIsPhoneNumberValid}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onBlur={onBlur}
      onChange={onChange}
    />
  );

  return {
    phoneNumberInput,
    phoneNumber,
    isPhoneNumberValid,
  };
};
