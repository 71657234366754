import React, { useState, useEffect } from "react";
import { useGeolocation } from "../services/hooks/useGeolocation";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";

const PhoneNumberInput = React.memo(
  ({
    required = false,
    setPhoneNumber,
    setValidity,
    placeholder,
    defaultValue,
    onBlur,
    onChange,
    onKeyDown,
  }) => {
    const [value, setValue] = useState(defaultValue || "");
    const [isValid, setIsValid] = useState(!required);
    const [selectedRegion, setSelectedRegion] = useState();
    const [label, setLabel] = useState();
    const [callingCode, setCallingCode] = useState();
    const [countryCode, setCountryCode] = useState();
    const { country } = useGeolocation();

    const enforceFormat = (e) => {
      const formatter = new AsYouType({
        defaultCallingCode: callingCode,
        defaultCountry: countryCode,
      });

      setValue(formatter.input(e.target.value));

      setIsValid(formatter.isValid());
    };

    const handleChange = (e) => {
      if (typeof onChange === "function") {
        onChange(e);
      }

      enforceFormat(e);
    };

    const handleBlur = (e) => {
      if (typeof onBlur === "function") {
        onBlur(e);
      }
    };

    const handleKeyDown = (e) => {
      if (typeof onKeyDown === "function") {
        onKeyDown(e);
      }

      if (
        e.key === "Delete" ||
        e.key === "Backspace" ||
        e.keyCode === 8 ||
        e.keyCode === 46
      ) {
        if (e.target.selectionStart !== e.target.selectionEnd) {
          return;
        }

        e.preventDefault();

        e.target.value = e.target.value.substr(0, e.target.value.length - 1);

        handleChange(e);
      }
    };

    useEffect(() => {
      setSelectedRegion(
        document.querySelector(`[data-country-code="${country || "US"}"]`)
      );
    }, []);

    useEffect(() => {
      if (selectedRegion) {
        setCountryCode(selectedRegion.dataset.countryCode);
        setCallingCode(selectedRegion.dataset.callingCode);
        setLabel(selectedRegion.dataset.label);
      }
    }, [selectedRegion]);

    useEffect(() => {
      if (!value && !required) {
        setIsValid(true);
      }

      if (value) {
        try {
          const phoneNumber = parsePhoneNumber(value, countryCode);

          setPhoneNumber(phoneNumber.number);
        } catch {}
      } else {
        setPhoneNumber("");
      }
    }, [value, countryCode, required]);

    useEffect(() => {
      if (defaultValue) {
        try {
          const number = parsePhoneNumber(defaultValue);

          setIsValid(number.isValid());

          setValue(number.formatNational());
        } catch {}
      }
    }, [defaultValue]);

    useEffect(() => {
      setValidity(isValid);
    }, [isValid]);

    return (
      <>
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="dropdown">
              <button
                role="button"
                type="button"
                className="btn btn-outline-light"
                style={{
                  border: isValid ? "1px solid #e5e5e5" : "1px solid #bb0000",
                }}
                id="callingCodeDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {label}
              </button>
              <div
                className="dropdown-menu"
                style={{
                  height: "auto",
                  maxHeight: "200px",
                  overflowX: "hidden",
                }}
                aria-labelledby="callingCodeDropdown"
              >
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="AF"
                  data-calling-code={93}
                  data-label="🇦🇫"
                >
                  🇦🇫 Afghanistan (+93)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="AL"
                  data-calling-code={355}
                  data-label="🇦🇽"
                >
                  🇦🇽 Albania (+355)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="DZ"
                  data-calling-code={213}
                  data-label="🇩🇿"
                >
                  🇩🇿 Algeria (+213)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="AD"
                  data-calling-code={376}
                  data-label="🇦🇩"
                >
                  🇦🇩 Andorra (+376)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="AO"
                  data-calling-code={244}
                  data-label="🇦🇴"
                >
                  🇦🇴 Angola (+244)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="AI"
                  data-calling-code={1264}
                  data-label="🇦🇮"
                >
                  🇦🇮 Anguilla (+1264)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="AG"
                  data-calling-code={1268}
                  data-label="🇦🇬"
                >
                  🇦🇬 Antigua &amp; Barbuda (+1268)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="AR"
                  data-calling-code={54}
                  data-label="🇦🇷"
                >
                  🇦🇷 Argentina (+54)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="AM"
                  data-calling-code={374}
                  data-label="🇦🇲"
                >
                  🇦🇲 Armenia (+374)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="AW"
                  data-calling-code={297}
                  data-label="🇦🇼"
                >
                  🇦🇼 Aruba (+297)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="AC"
                  data-calling-code={247}
                  data-label="🇦🇨"
                >
                  🇦🇨 Ascension Island (+247)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="AU"
                  data-calling-code={61}
                  data-label="🇦🇺"
                >
                  🇦🇺 Australia (+61)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="AT"
                  data-calling-code={43}
                  data-label="🇦🇹"
                >
                  🇦🇹 Austria (+43)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="AZ"
                  data-calling-code={994}
                  data-label="🇦🇿"
                >
                  🇦🇿 Azerbaijan (+994)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="BS"
                  data-calling-code={1242}
                  data-label="🇧🇸"
                >
                  🇧🇸 Bahamas (+1242)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="BH"
                  data-calling-code={973}
                  data-label="🇧🇭"
                >
                  🇧🇭 Bahrain (+973)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="BD"
                  data-calling-code={880}
                  data-label="🇧🇩"
                >
                  🇧🇩 Bangladesh (+880)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="BB"
                  data-calling-code={1246}
                  data-label="🇧🇧"
                >
                  🇧🇧 Barbados (+1246)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="BY"
                  data-calling-code={375}
                  data-label="🇧🇾"
                >
                  🇧🇾 Belarus (+375)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="BE"
                  data-calling-code={32}
                  data-label="🇧🇪"
                >
                  🇧🇪 Belgium (+32)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="BZ"
                  data-calling-code={501}
                  data-label="🇧🇿"
                >
                  🇧🇿 Belize (+501)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="BJ"
                  data-calling-code={229}
                  data-label="🇧🇯"
                >
                  🇧🇯 Benin (+229)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="BM"
                  data-calling-code={1441}
                  data-label="🇧🇲"
                >
                  🇧🇲 Bermuda (+1441)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="BT"
                  data-calling-code={975}
                  data-label="🇧🇹"
                >
                  🇧🇹 Bhutan (+975)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="BO"
                  data-calling-code={591}
                  data-label="🇧🇴"
                >
                  🇧🇴 Bolivia (+591)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="BQ"
                  data-calling-code={599}
                  data-label="🇧🇶"
                >
                  🇧🇶 Bonaire, Saba and Sint Eustatius (+599)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="BA"
                  data-calling-code={387}
                  data-label="🇧🇦"
                >
                  🇧🇦 Bosnia Herzegovina (+387)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="BW"
                  data-calling-code={267}
                  data-label="🇧🇼"
                >
                  🇧🇼 Botswana (+267)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="BR"
                  data-calling-code={55}
                  data-label="🇧🇷"
                >
                  🇧🇷 Brazil (+55)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="IO"
                  data-calling-code={246}
                  data-label="🇮🇴"
                >
                  🇮🇴 British Indian Ocean Territory (+246)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="BN"
                  data-calling-code={673}
                  data-label="🇧🇳"
                >
                  🇧🇳 Brunei (+673)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="BG"
                  data-calling-code={359}
                  data-label="🇧🇬"
                >
                  🇧🇬 Bulgaria (+359)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="BF"
                  data-calling-code={226}
                  data-label="🇧🇫"
                >
                  🇧🇫 Burkina Faso (+226)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="BI"
                  data-calling-code={257}
                  data-label="🇧🇮"
                >
                  🇧🇮 Burundi (+257)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="KH"
                  data-calling-code={855}
                  data-label="🇰🇭"
                >
                  🇰🇭 Cambodia (+855)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="CM"
                  data-calling-code={237}
                  data-label="🇨🇲"
                >
                  🇨🇲 Cameroon (+237)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="CA"
                  data-calling-code={1}
                  data-label="🇨🇦"
                >
                  🇨🇦 Canada (+1)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="CV"
                  data-calling-code={238}
                  data-label="🇨🇻"
                >
                  🇨🇻 Cape Verde Islands (+238)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="KY"
                  data-calling-code={1345}
                  data-label="🇰🇾"
                >
                  🇰🇾 Cayman Islands (+1345)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="CF"
                  data-calling-code={236}
                  data-label="🇨🇫"
                >
                  🇨🇫 Central African Republic (+236)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="TD"
                  data-calling-code={235}
                  data-label="🇹🇩"
                >
                  🇹🇩 Chad (+235)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="CL"
                  data-calling-code={56}
                  data-label="🇨🇱"
                >
                  🇨🇱 Chile (+56)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="CN"
                  data-calling-code={86}
                  data-label="🇨🇳"
                >
                  🇨🇳 China (+86)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="CO"
                  data-calling-code={57}
                  data-label="🇨🇴"
                >
                  🇨🇴 Colombia (+57)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="KM"
                  data-calling-code={269}
                  data-label="🇰🇲"
                >
                  🇰🇲 Comoros (+269)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="CG"
                  data-calling-code={242}
                  data-label="🇨🇬"
                >
                  🇨🇬 Congo (+242)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="CD"
                  data-calling-code={243}
                  data-label="🇨🇩"
                >
                  🇨🇩 Congo, Democratic Republic of the (+243)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="CK"
                  data-calling-code={682}
                  data-label="🇨🇰"
                >
                  🇨🇰 Cook Islands (+682)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="CR"
                  data-calling-code={506}
                  data-label="🇨🇷"
                >
                  🇨🇷 Costa Rica (+506)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="HR"
                  data-calling-code={385}
                  data-label="🇭🇷"
                >
                  🇭🇷 Croatia (+385)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="CU"
                  data-calling-code={53}
                  data-label="🇨🇺"
                >
                  🇨🇺 Cuba (+53)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="CW"
                  data-calling-code={5999}
                  data-label="🇨🇼"
                >
                  🇨🇼 Curaçao (+5999)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="CY"
                  data-calling-code={357}
                  data-label="🇨🇾"
                >
                  🇨🇾 Cyprus (+357)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="CZ"
                  data-calling-code={420}
                  data-label="🇨🇿"
                >
                  🇨🇿 Czech Republic (+420)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="DK"
                  data-calling-code={45}
                  data-label="🇩🇰"
                >
                  🇩🇰 Denmark (+45)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="DJ"
                  data-calling-code={253}
                  data-label="🇩🇯"
                >
                  🇩🇯 Djibouti (+253)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="DM"
                  data-calling-code={1767}
                  data-label="🇩🇲"
                >
                  🇩🇲 Dominica (+1767)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="DO"
                  data-calling-code={1809}
                  data-label="🇩🇴"
                >
                  🇩🇴 Dominican Republic (+1809)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="TL"
                  data-calling-code={670}
                  data-label="🇹🇱"
                >
                  🇹🇱 East Timor (+670)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="EC"
                  data-calling-code={593}
                  data-label="🇪🇨"
                >
                  🇪🇨 Ecuador (+593)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="EG"
                  data-calling-code={20}
                  data-label="🇪🇬"
                >
                  🇪🇬 Egypt (+20)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="SV"
                  data-calling-code={503}
                  data-label="🇸🇻"
                >
                  🇸🇻 El Salvador (+503)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="GQ"
                  data-calling-code={240}
                  data-label="🇬🇶"
                >
                  🇬🇶 Equatorial Guinea (+240)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="ER"
                  data-calling-code={291}
                  data-label="🇪🇷"
                >
                  🇪🇷 Eritrea (+291)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="EE"
                  data-calling-code={372}
                  data-label="🇪🇪"
                >
                  🇪🇪 Estonia (+372)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="SZ"
                  data-calling-code={268}
                  data-label="🇸🇿"
                >
                  🇸🇿 Eswatini (+268)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="ET"
                  data-calling-code={251}
                  data-label="🇪🇹"
                >
                  🇪🇹 Ethiopia (+251)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="FK"
                  data-calling-code={500}
                  data-label="🇫🇰"
                >
                  🇫🇰 Falkland Islands (+500)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="FO"
                  data-calling-code={298}
                  data-label="🇫🇴"
                >
                  🇫🇴 Faroe Islands (+298)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="FJ"
                  data-calling-code={679}
                  data-label="🇫🇯"
                >
                  🇫🇯 Fiji (+679)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="FI"
                  data-calling-code={358}
                  data-label="🇫🇮"
                >
                  🇫🇮 Finland (+358)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="FR"
                  data-calling-code={33}
                  data-label="🇫🇷"
                >
                  🇫🇷 France (+33)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="GF"
                  data-calling-code={594}
                  data-label="🇬🇫"
                >
                  🇬🇫 French Guiana (+594)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="PF"
                  data-calling-code={689}
                  data-label="🇵🇫"
                >
                  🇵🇫 French Polynesia (+689)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="GA"
                  data-calling-code={241}
                  data-label="🇬🇦"
                >
                  🇬🇦 Gabon (+241)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="GM"
                  data-calling-code={220}
                  data-label="🇬🇲"
                >
                  🇬🇲 Gambia (+220)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="GE"
                  data-calling-code={995}
                  data-label="🇬🇪"
                >
                  🇬🇪 Georgia (+995)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="DE"
                  data-calling-code={49}
                  data-label="🇩🇪"
                >
                  🇩🇪 Germany (+49)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="GH"
                  data-calling-code={233}
                  data-label="🇬🇭"
                >
                  🇬🇭 Ghana (+233)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="GI"
                  data-calling-code={350}
                  data-label="🇬🇮"
                >
                  🇬🇮 Gibraltar (+350)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="GR"
                  data-calling-code={30}
                  data-label="🇬🇷"
                >
                  🇬🇷 Greece (+30)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="GL"
                  data-calling-code={299}
                  data-label="🇬🇱"
                >
                  🇬🇱 Greenland (+299)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="GD"
                  data-calling-code={1473}
                  data-label="🇬🇩"
                >
                  🇬🇩 Grenada (+1473)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="GP"
                  data-calling-code={590}
                  data-label="🇬🇵"
                >
                  🇬🇵 Guadeloupe (+590)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="GU"
                  data-calling-code={1671}
                  data-label="🇬🇺"
                >
                  🇬🇺 Guam (+1671)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="GT"
                  data-calling-code={502}
                  data-label="🇬🇹"
                >
                  🇬🇹 Guatemala (+502)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="GN"
                  data-calling-code={224}
                  data-label="🇬🇳"
                >
                  🇬🇳 Guinea (+224)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="GW"
                  data-calling-code={245}
                  data-label="🇬🇼"
                >
                  🇬🇼 Guinea - Bissau (+245)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="GY"
                  data-calling-code={592}
                  data-label="🇬🇾"
                >
                  🇬🇾 Guyana (+592)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="HT"
                  data-calling-code={509}
                  data-label="🇭🇹"
                >
                  🇭🇹 Haiti (+509)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="HN"
                  data-calling-code={504}
                  data-label="🇭🇳"
                >
                  🇭🇳 Honduras (+504)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="HK"
                  data-calling-code={852}
                  data-label="🇭🇰"
                >
                  🇭🇰 Hong Kong (+852)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="HU"
                  data-calling-code={36}
                  data-label="🇭🇺"
                >
                  🇭🇺 Hungary (+36)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="IS"
                  data-calling-code={354}
                  data-label="🇮🇸"
                >
                  🇮🇸 Iceland (+354)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="IN"
                  data-calling-code={91}
                  data-label="🇮🇳"
                >
                  🇮🇳 India (+91)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="ID"
                  data-calling-code={62}
                  data-label="🇮🇩"
                >
                  🇮🇩 Indonesia (+62)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="IR"
                  data-calling-code={98}
                  data-label="🇮🇷"
                >
                  🇮🇷 Iran (+98)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="IQ"
                  data-calling-code={964}
                  data-label="🇮🇶"
                >
                  🇮🇶 Iraq (+964)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="IE"
                  data-calling-code={353}
                  data-label="🇮🇪"
                >
                  🇮🇪 Ireland (+353)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="IL"
                  data-calling-code={972}
                  data-label="🇮🇱"
                >
                  🇮🇱 Israel (+972)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="IT"
                  data-calling-code={39}
                  data-label="🇮🇹"
                >
                  🇮🇹 Italy (+39)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="CI"
                  data-calling-code={225}
                  data-label="🇨🇮"
                >
                  🇨🇮 Ivory Coast (+225)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="JM"
                  data-calling-code={1876}
                  data-label="🇯🇲"
                >
                  🇯🇲 Jamaica (+1876)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="JP"
                  data-calling-code={81}
                  data-label="🇯🇵"
                >
                  🇯🇵 Japan (+81)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="JO"
                  data-calling-code={962}
                  data-label="🇯🇴"
                >
                  🇯🇴 Jordan (+962)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="KZ"
                  data-calling-code={7}
                  data-label="🇰🇿"
                >
                  🇰🇿 Kazakhstan (+7)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="KE"
                  data-calling-code={254}
                  data-label="🇰🇪"
                >
                  🇰🇪 Kenya (+254)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="KI"
                  data-calling-code={686}
                  data-label="🇰🇮"
                >
                  🇰🇮 Kiribati (+686)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="KP"
                  data-calling-code={850}
                  data-label="🇰🇵"
                >
                  🇰🇵 Korea, North (+850)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="KR"
                  data-calling-code={82}
                  data-label="🇰🇷"
                >
                  🇰🇷 Korea, South (+82)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="XK"
                  data-calling-code={383}
                  data-label="🇽🇰"
                >
                  🇽🇰 Kosovo (+383)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="KW"
                  data-calling-code={965}
                  data-label="🇰🇼"
                >
                  🇰🇼 Kuwait (+965)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="KG"
                  data-calling-code={996}
                  data-label="🇰🇬"
                >
                  🇰🇬 Kyrgyzstan (+996)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="LA"
                  data-calling-code={856}
                  data-label="🇱🇦"
                >
                  🇱🇦 Laos (+856)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="LV"
                  data-calling-code={371}
                  data-label="🇱🇻"
                >
                  🇱🇻 Latvia (+371)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="LB"
                  data-calling-code={961}
                  data-label="🇱🇧"
                >
                  🇱🇧 Lebanon (+961)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="LS"
                  data-calling-code={266}
                  data-label="🇱🇸"
                >
                  🇱🇸 Lesotho (+266)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="LR"
                  data-calling-code={231}
                  data-label="🇱🇷"
                >
                  🇱🇷 Liberia (+231)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="LY"
                  data-calling-code={218}
                  data-label="🇱🇾"
                >
                  🇱🇾 Libya (+218)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="LI"
                  data-calling-code={423}
                  data-label="🇱🇮"
                >
                  🇱🇮 Liechtenstein (+423)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="LT"
                  data-calling-code={370}
                  data-label="🇱🇹"
                >
                  🇱🇹 Lithuania (+370)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="LU"
                  data-calling-code={352}
                  data-label="🇱🇺"
                >
                  🇱🇺 Luxembourg (+352)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="MO"
                  data-calling-code={853}
                  data-label="🇲🇴"
                >
                  🇲🇴 Macao (+853)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="MK"
                  data-calling-code={389}
                  data-label="🇲🇰"
                >
                  🇲🇰 Macedonia (+389)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="MG"
                  data-calling-code={261}
                  data-label="🇲🇬"
                >
                  🇲🇬 Madagascar (+261)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="MW"
                  data-calling-code={265}
                  data-label="🇲🇼"
                >
                  🇲🇼 Malawi (+265)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="MY"
                  data-calling-code={60}
                  data-label="🇲🇾"
                >
                  🇲🇾 Malaysia (+60)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="MV"
                  data-calling-code={960}
                  data-label="🇲🇻"
                >
                  🇲🇻 Maldives (+960)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="ML"
                  data-calling-code={223}
                  data-label="🇲🇱"
                >
                  🇲🇱 Mali (+223)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="MT"
                  data-calling-code={356}
                  data-label="🇲🇹"
                >
                  🇲🇹 Malta (+356)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="MH"
                  data-calling-code={692}
                  data-label="🇲🇭"
                >
                  🇲🇭 Marshall Islands (+692)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="MQ"
                  data-calling-code={596}
                  data-label="🇲🇶"
                >
                  🇲🇶 Martinique (+596)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="MR"
                  data-calling-code={222}
                  data-label="🇲🇷"
                >
                  🇲🇷 Mauritania (+222)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="MU"
                  data-calling-code={230}
                  data-label="🇲🇺"
                >
                  🇲🇺 Mauritius (+230)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="YT"
                  data-calling-code={262}
                  data-label="🇾🇹"
                >
                  🇾🇹 Mayotte (+262)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="MX"
                  data-calling-code={52}
                  data-label="🇲🇽"
                >
                  🇲🇽 Mexico (+52)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="FM"
                  data-calling-code={691}
                  data-label="🇫🇲"
                >
                  🇫🇲 Micronesia (+691)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="MD"
                  data-calling-code={373}
                  data-label="🇲🇩"
                >
                  🇲🇩 Moldova (+373)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="MC"
                  data-calling-code={377}
                  data-label="🇲🇨"
                >
                  🇲🇨 Monaco (+377)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="MN"
                  data-calling-code={976}
                  data-label="🇲🇳"
                >
                  🇲🇳 Mongolia (+976)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="ME"
                  data-calling-code={382}
                  data-label="🇲🇪"
                >
                  🇲🇪 Montenegro (+382)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="MS"
                  data-calling-code={1664}
                  data-label="🇲🇸"
                >
                  🇲🇸 Montserrat (+1664)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="MA"
                  data-calling-code={212}
                  data-label="🇲🇦"
                >
                  🇲🇦 Morocco (+212)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="MZ"
                  data-calling-code={258}
                  data-label="🇲🇿"
                >
                  🇲🇿 Mozambique (+258)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="MN"
                  data-calling-code={95}
                  data-label="🇲🇲"
                >
                  🇲🇲 Myanmar (+95)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="NA"
                  data-calling-code={264}
                  data-label="🇳🇦"
                >
                  🇳🇦 Namibia (+264)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="NR"
                  data-calling-code={674}
                  data-label="🇳🇷"
                >
                  🇳🇷 Nauru (+674)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="NP"
                  data-calling-code={977}
                  data-label="🇳🇵"
                >
                  🇳🇵 Nepal (+977)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="NL"
                  data-calling-code={31}
                  data-label="🇳🇱"
                >
                  🇳🇱 Netherlands (+31)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="NC"
                  data-calling-code={687}
                  data-label="🇳🇨"
                >
                  🇳🇨 New Caledonia (+687)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="NZ"
                  data-calling-code={64}
                  data-label="🇳🇿"
                >
                  🇳🇿 New Zealand (+64)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="NI"
                  data-calling-code={505}
                  data-label="🇳🇮"
                >
                  🇳🇮 Nicaragua (+505)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="NE"
                  data-calling-code={227}
                  data-label="🇳🇪"
                >
                  🇳🇪 Niger (+227)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="NG"
                  data-calling-code={234}
                  data-label="🇳🇬"
                >
                  🇳🇬 Nigeria (+234)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="NU"
                  data-calling-code={683}
                  data-label="🇳🇺"
                >
                  🇳🇺 Niue (+683)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="NF"
                  data-calling-code={672}
                  data-label="🇳🇫"
                >
                  🇳🇫 Norfolk Islands (+672)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="NP"
                  data-calling-code={1670}
                  data-label="🇲🇵"
                >
                  🇲🇵 Northern Mariana Islands (+1670)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="NO"
                  data-calling-code={47}
                  data-label="🇳🇴"
                >
                  🇳🇴 Norway (+47)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="OM"
                  data-calling-code={968}
                  data-label="🇴🇲"
                >
                  🇴🇲 Oman (+968)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="PK"
                  data-calling-code={92}
                  data-label="🇵🇰"
                >
                  🇵🇰 Pakistan (+92)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="PW"
                  data-calling-code={680}
                  data-label="🇵🇼"
                >
                  🇵🇼 Palau (+680)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="PS"
                  data-calling-code={970}
                  data-label="🇵🇸"
                >
                  🇵🇸 Palestine (+970)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="PA"
                  data-calling-code={507}
                  data-label="🇵🇦"
                >
                  🇵🇦 Panama (+507)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="PG"
                  data-calling-code={675}
                  data-label="🇵🇬"
                >
                  🇵🇬 Papua New Guinea (+675)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="PY"
                  data-calling-code={595}
                  data-label="🇵🇾"
                >
                  🇵🇾 Paraguay (+595)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="PE"
                  data-calling-code={51}
                  data-label="🇵🇪"
                >
                  🇵🇪 Peru (+51)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="PH"
                  data-calling-code={63}
                  data-label="🇵🇭"
                >
                  🇵🇭 Philippines (+63)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="PL"
                  data-calling-code={48}
                  data-label="🇵🇱"
                >
                  🇵🇱 Poland (+48)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="PT"
                  data-calling-code={351}
                  data-label="🇵🇹"
                >
                  🇵🇹 Portugal (+351)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="PR"
                  data-calling-code={1787}
                  data-label="🇵🇷"
                >
                  🇵🇷 Puerto Rico (+1787)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="QA"
                  data-calling-code={974}
                  data-label="🇶🇦"
                >
                  🇶🇦 Qatar (+974)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="RE"
                  data-calling-code={262}
                  data-label="🇷🇪"
                >
                  🇷🇪 Réunion (+262)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="RO"
                  data-calling-code={40}
                  data-label="🇷🇴"
                >
                  🇷🇴 Romania (+40)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="RU"
                  data-calling-code={7}
                  data-label="🇷🇺"
                >
                  🇷🇺 Russia (+7)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="RW"
                  data-calling-code={250}
                  data-label="🇷🇼"
                >
                  🇷🇼 Rwanda (+250)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="WS"
                  data-calling-code={685}
                  data-label="🇼🇸"
                >
                  🇼🇸 Samoa (+685)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="SM"
                  data-calling-code={378}
                  data-label="🇸🇲"
                >
                  🇸🇲 San Marino (+378)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="ST"
                  data-calling-code={239}
                  data-label="🇸🇹"
                >
                  🇸🇹 São Tomé &amp; Principe (+239)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="SA"
                  data-calling-code={966}
                  data-label="🇸🇦"
                >
                  🇸🇦 Saudi Arabia (+966)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="SN"
                  data-calling-code={221}
                  data-label="🇸🇳"
                >
                  🇸🇳 Senegal (+221)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="CS"
                  data-calling-code={381}
                  data-label="🇷🇸"
                >
                  🇷🇸 Serbia (+381)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="SC"
                  data-calling-code={248}
                  data-label="🇸🇨"
                >
                  🇸🇨 Seychelles (+248)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="SL"
                  data-calling-code={232}
                  data-label="🇸🇱"
                >
                  🇸🇱 Sierra Leone (+232)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="SG"
                  data-calling-code={65}
                  data-label="🇸🇬"
                >
                  🇸🇬 Singapore (+65)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="SK"
                  data-calling-code={421}
                  data-label="🇸🇰"
                >
                  🇸🇰 Slovakia (+421)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="SI"
                  data-calling-code={386}
                  data-label="🇸🇮"
                >
                  🇸🇮 Slovenia (+386)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="SB"
                  data-calling-code={677}
                  data-label="🇸🇧"
                >
                  🇸🇧 Solomon Islands (+677)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="SO"
                  data-calling-code={252}
                  data-label="🇸🇴"
                >
                  🇸🇴 Somalia (+252)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="ZA"
                  data-calling-code={27}
                  data-label="🇿🇦"
                >
                  🇿🇦 South Africa (+27)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="SS"
                  data-calling-code={211}
                  data-label="🇸🇸"
                >
                  🇸🇸 South Sudan (+211)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="ES"
                  data-calling-code={34}
                  data-label="🇪🇸"
                >
                  🇪🇸 Spain (+34)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="LK"
                  data-calling-code={94}
                  data-label="🇱🇰"
                >
                  🇱🇰 Sri Lanka (+94)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="SH"
                  data-calling-code={290}
                  data-label="🇸🇭"
                >
                  🇸🇭 St. Helena (+290)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="KN"
                  data-calling-code={1869}
                  data-label="🇰🇳"
                >
                  🇰🇳 St. Kitts and Nevis (+1869)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="SC"
                  data-calling-code={1758}
                  data-label="🇱🇨"
                >
                  🇱🇨 St. Lucia (+1758)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="PM"
                  data-calling-code={508}
                  data-label="🇵🇲"
                >
                  🇵🇲 St. Pierre and Miquelon (+508)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="SD"
                  data-calling-code={249}
                  data-label="🇸🇩"
                >
                  🇸🇩 Sudan (+249)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="SR"
                  data-calling-code={597}
                  data-label="🇸🇷"
                >
                  🇸🇷 Suriname (+597)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="SE"
                  data-calling-code={46}
                  data-label="🇸🇪"
                >
                  🇸🇪 Sweden (+46)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="CH"
                  data-calling-code={41}
                  data-label="🇨🇭"
                >
                  🇨🇭 Switzerland (+41)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="SI"
                  data-calling-code={963}
                  data-label="🇸🇾"
                >
                  🇸🇾 Syria (+963)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="TW"
                  data-calling-code={886}
                  data-label="🇹🇼"
                >
                  🇹🇼 Taiwan (+886)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="TJ"
                  data-calling-code={992}
                  data-label="🇹🇯"
                >
                  🇹🇯 Tajikstan (+992)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="TZ"
                  data-calling-code={255}
                  data-label="🇹🇿"
                >
                  🇹🇿 Tanzania (+255)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="TH"
                  data-calling-code={66}
                  data-label="🇹🇭"
                >
                  🇹🇭 Thailand (+66)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="TG"
                  data-calling-code={228}
                  data-label="🇹🇬"
                >
                  🇹🇬 Togo (+228)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="TK"
                  data-calling-code={690}
                  data-label="🇹🇰"
                >
                  🇹🇰 Tokelau (+690)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="TO"
                  data-calling-code={676}
                  data-label="🇹🇴"
                >
                  🇹🇴 Tonga (+676)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="TT"
                  data-calling-code={1868}
                  data-label="🇹🇹"
                >
                  🇹🇹 Trinidad &amp; Tobago (+1868)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="TN"
                  data-calling-code={216}
                  data-label="🇹🇳"
                >
                  🇹🇳 Tunisia (+216)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="TR"
                  data-calling-code={90}
                  data-label="🇹🇷"
                >
                  🇹🇷 Turkey (+90)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="TM"
                  data-calling-code={993}
                  data-label="🇹🇲"
                >
                  🇹🇲 Turkmenistan (+993)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="TC"
                  data-calling-code={1649}
                  data-label="🇹🇨"
                >
                  🇹🇨 Turks &amp; Caicos Islands (+1649)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="TV"
                  data-calling-code={688}
                  data-label="🇹🇻"
                >
                  🇹🇻 Tuvalu (+688)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="UG"
                  data-calling-code={256}
                  data-label="🇺🇬"
                >
                  🇺🇬 Uganda (+256)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="UA"
                  data-calling-code={380}
                  data-label="🇺🇦"
                >
                  🇺🇦 Ukraine (+380)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="AE"
                  data-calling-code={971}
                  data-label="🇦🇪"
                >
                  🇦🇪 United Arab Emirates (+971)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="GB"
                  data-calling-code={44}
                  data-label="🇬🇧"
                >
                  🇬🇧 United Kingdom (+44)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="US"
                  data-calling-code={1}
                  data-label="🇺🇸"
                >
                  🇺🇸 United States of America (+1)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="UY"
                  data-calling-code={598}
                  data-label="🇺🇾"
                >
                  🇺🇾 Uruguay (+598)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="UZ"
                  data-calling-code={998}
                  data-label="🇺🇿"
                >
                  🇺🇿 Uzbekistan (+998)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="VU"
                  data-calling-code={678}
                  data-label="🇻🇺"
                >
                  🇻🇺 Vanuatu (+678)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="VA"
                  data-calling-code={39}
                  data-label="🇻🇦"
                >
                  🇻🇦 Vatican City (+39)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="VE"
                  data-calling-code={58}
                  data-label="🇻🇪"
                >
                  🇻🇪 Venezuela (+58)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="VN"
                  data-calling-code={84}
                  data-label="🇻🇳"
                >
                  🇻🇳 Vietnam (+84)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="VG"
                  data-calling-code={1284}
                  data-label="🇻🇬"
                >
                  🇻🇬 Virgin Islands - British (+1284)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="VI"
                  data-calling-code={1340}
                  data-label="🇻🇮"
                >
                  🇻🇮 Virgin Islands - US (+1340)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="WF"
                  data-calling-code={681}
                  data-label="🇼🇫"
                >
                  🇼🇫 Wallis &amp; Futuna (+681)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="EH"
                  data-calling-code={967}
                  data-label="🇪🇭"
                >
                  🇪🇭 Western Sahara (+212)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="YE"
                  data-calling-code={967}
                  data-label="🇾🇪"
                >
                  🇾🇪 Yemen (+967)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="ZM"
                  data-calling-code={260}
                  data-label="🇿🇲"
                >
                  🇿🇲 Zambia (+260)
                </button>
                <button
                  role="button"
                  type="button"
                  onClick={(e) => setSelectedRegion(e.target)}
                  className="dropdown-item"
                  data-country-code="ZW"
                  data-calling-code={263}
                  data-label="🇿🇼"
                >
                  🇿🇼 Zimbabwe (+263)
                </button>
              </div>
            </div>
          </div>

          <input
            id="phoneNumber"
            type="tel"
            className={`form-control ${!isValid ? "is-invalid" : ""}`}
            placeholder={placeholder}
            name="phoneNumber"
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            value={value}
          />
        </div>

        {!isValid && (
          <span className="input-error">
            Please enter a valid phone number for your selected region
          </span>
        )}
      </>
    );
  }
);

export default PhoneNumberInput;
