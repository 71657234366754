import React from "react";

const ProductQuantityCounter = React.memo(
  ({ quantity, handleIncrement, handleDecrement }) => {
    return (
      <div className="input-group-quantity-counter">
        <input
          type="number"
          className="form-control input-group-quantity-counter-control"
          readOnly
          value={quantity}
        />
        <div className="input-group-quantity-counter-toggle">
          <a
            className="input-group-quantity-counter-btn"
            href="#"
            onClick={handleDecrement}
          >
            <i className="fas fa-minus" />
          </a>
          <a
            className="input-group-quantity-counter-btn"
            href="#"
            onClick={handleIncrement}
          >
            <i className="fas fa-plus" />
          </a>
        </div>
      </div>
    );
  }
);

export default ProductQuantityCounter;
