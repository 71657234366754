import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../state/contexts/authState";
import { CartContext } from "../../state/contexts/cartState";
import { GlobalContext } from "../../state/contexts/globalState";
import { QuizContext } from "../../state/contexts/quizState";
import { AccountContext } from "../../state/contexts/accountState";
import { useRedirect } from "./useRedirect";
import { useSnackbar } from "./useSnackbar";
import { useGlobal } from "./useGlobal";

export const useLogout = (shouldRedirect = true) => {
  const [logoutError, setLogoutError] = useState("");
  const [logoutStatus, setLogoutStatus] = useState("idle");
  const { resetAuth } = useContext(AuthContext);
  const { resetCart } = useContext(CartContext);
  const { resetGlobal, apiBaseUrl } = useContext(GlobalContext);
  const { resetQuiz } = useContext(QuizContext);
  const { resetAccount } = useContext(AccountContext);

  const { redirect } = useRedirect();

  const { addSnackbar } = useSnackbar();

  const { showOverlayLoader, hideOverlayLoader } = useGlobal();

  const logout = () => {
    setLogoutStatus("pending");
  };

  const executeLogoutAndResetState = async () => {
    try {
      showOverlayLoader({
        type: "checkout",
        message: "Logging out...",
      });

      const response = await fetch(`${apiBaseUrl}/auth/logout`, {
        credentials: "include",
      });

      const { success, error } = await response.json();

      if (success === true) {
        resetAccount();
        resetQuiz();
        resetCart();
        resetAuth();
        resetGlobal();

        setLogoutStatus("success");

        addSnackbar("You're now logged out.", {
          type: "success",
        });

        if (shouldRedirect) {
          redirect("/");
        }
      }

      if (error) {
        setLogoutStatus("error");
        setLogoutError(error.userMessage);
      }
    } catch (e) {
      setLogoutStatus("error");
      setLogoutError("An unexpected error occurred.");
    }
  };

  useEffect(async () => {
    if (logoutStatus === "pending") {
      await executeLogoutAndResetState();
    } else {
      hideOverlayLoader();
    }
  }, [logoutStatus]);

  return {
    logout,
    logoutError,
    logoutStatus,
  };
};
