export default function setCookie(cname, cvalue, exdays = 1) {
  try {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();

    document.cookie =
      cname + "=" + JSON.stringify(cvalue, null, 2) + ";" + expires + ";path=/";
  } catch (e) {
    console.error(`Error setting cookie ${cname}`);
  }
}
