import React, { createContext, useReducer, useEffect, useState } from "react";
import surveyResponseReducer from "../reducers/surveyResponseReducer";

import { useFetchSurveyResponse } from "../../services/hooks/useFetchSurveyResponse";
import prepareSurveyContents from "../../services/lib/surveys/prepareSurveyContents";

//Actions
export const ACTIONS = {
  SET_STATE: "SetState",
};

// Initial state
const initialState = {
  id: null,
  surveyId: null,
  userId: null,
  contents: [],
  created: null,
};

// Create context
export const SurveyResponseContext = createContext(initialState);

// Provider component
export const SurveyResponseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(surveyResponseReducer, initialState);

  const {
    fetchSurveyResponse,
    resetFetchSurveyResponse,
    fetchedSurveyResponse,
    fetchSurveyResponseError,
  } = useFetchSurveyResponse();

  const [activeContent, setActiveContent] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [initialingSurveyResponseId, setInitialingSurveyResponseId] =
    useState(null);

  const setSurveyResponseState = (payload) => {
    dispatch({
      type: ACTIONS.SET_STATE,
      payload,
    });
  };

  const resetActiveSurveyResponse = (surveyResponseId) => {
    let canReset = true;

    if (surveyResponseId) {
      if (surveyResponseId !== state.id) {
        canReset = false;
      }
    }

    if (canReset) {
      setInitialingSurveyResponseId(null);
      setSurveyResponseState(initialState);
      resetFetchSurveyResponse();
    }
  };

  useEffect(() => {
    if (initialingSurveyResponseId) {
      fetchSurveyResponse(initialingSurveyResponseId);
    }
  }, [initialingSurveyResponseId]);

  useEffect(() => {
    if (fetchedSurveyResponse) {
      const { contents } = prepareSurveyContents({
        contents: fetchedSurveyResponse.contents,
      });

      setSurveyResponseState({
        ...fetchedSurveyResponse,
        contents,
      });
    }
  }, [fetchedSurveyResponse]);

  useEffect(() => {
    if (!activeContent && state.contents.length) {
      setCurrentStep(1);
    }
  }, [state.contents, activeContent]);

  useEffect(() => {
    if (currentStep && currentStep < state.contents.length) {
      setActiveContent(state.contents[currentStep - 1]);
    }
  }, [currentStep, state.contents]);

  const value = {
    ...state,
    currentStep,
    setCurrentStep,
    activeContent,
    setInitialingSurveyResponseId,
    surveyResponseError: fetchSurveyResponseError,
    resetActiveSurveyResponse,
  };

  return (
    <SurveyResponseContext.Provider value={value}>
      {children}
    </SurveyResponseContext.Provider>
  );
};
