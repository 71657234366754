export default function shuffleArray(arr) {
  for (let i = 0, len = arr.length; i < len; i++) {
    let j = ~~(Math.random() * (i + 1));

    let temp = arr[i];

    arr[i] = arr[j];

    arr[j] = temp;
  }

  return arr;
}
