import { useState, useEffect } from "react";
import { useApiCall } from "./useApiCall";

export const useStoresList = (autoFetch = false) => {
  const [storesList, setStoresList] = useState([]);
  const [storesListError, setStoresListError] = useState();
  const [storesListTotalLength, setStoresListTotalLength] = useState(1);
  const [storesListNumPages, setStoresListNumPages] = useState(1);
  const [storesListStatus, setStoresListStatus] = useState("idle");

  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();

  const fetchStoresList = (options = {}) => {
    const { page, limit } = options;

    let url = `/stores`;

    if (!page && autoFetch) {
      url = url + `?limit=all`;
    } else {
      if (page) {
        url = url + `?page=${page}`;
      }

      if (limit) {
        url = url + (page ? `&limit=${limit}` : `?limit=${limit}`);
      }
    }

    const requestOptions = {
      url,
    };

    executeApiCall(requestOptions);

    setStoresListStatus("pending");
  };

  useEffect(() => {
    if (autoFetch) {
      fetchStoresList();
    }
  }, [autoFetch]);

  useEffect(() => {
    switch (apiCallStatus) {
      case "success":
        if (apiCallResponse.data.data) {
          const { stores, recordsTotal, numPages } = apiCallResponse.data.data;

          setStoresList(stores);
          setStoresListTotalLength(recordsTotal);
          setStoresListNumPages(numPages);
          setStoresListStatus("ready");
        } else {
          setStoresListError(apiCallResponse.data.error.userMessage);
          setStoresListStatus("ready");
        }
        break;
      case "error":
        setStoresListStatus("ready");
        break;
    }
  }, [apiCallStatus, apiCallResponse]);

  return {
    storesList,
    storesListTotalLength,
    storesListNumPages,
    storesListError,
    storesListStatus,
    fetchStoresList,
  };
};
