import React from "react";

export default React.memo(function GetHelpButton() {
  return (
    <a
      href="https://help.chicbeautybox.com"
      className="btn btn-sm btn-primary btn-get-help"
      title="Get Help"
      target="_blank"
    >
      <i className="far fa-question-circle"></i> Help
    </a>
  );
});
