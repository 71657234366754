import React, { useState, useEffect } from "react";
import SurveyContent from "./SurveyContent";
import SurveyNavigation from "./SurveyNavigation";
import FadeIn from "./FadeIn";
import { useSurveys } from "../services/hooks/useSurveys";
import { useRemember } from "../services/hooks/useRemember";
import validateSurveyContentResponse from "../services/lib/surveys/validateSurveyContentResponse";
import { SURVEY_CONTENT_TYPES } from "../services/lib/surveys/constants";

export default React.memo(function SurveySideOut() {
  const {
    isSavingActiveSurveyResponse,
    surveyContentError,
    activeSurvey,
    activeContent,
    currentStep,
    setCurrentStep,
    setSurveyContentError,
    setActiveContentId,
    setActiveContentResponse,
    setActiveContentOptionIsSelected,
    saveActiveSurveyResponse,
  } = useSurveys();

  const { rememberLocalValue, retrieveLocalValue } = useRemember();

  const [isOpen, setIsOpen] = useState(true);
  const [isDismissed, setIsDismissed] = useState(false);

  const handleToggleSlideOut = (e) => {
    e.preventDefault();

    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleDismissSlideOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    setIsDismissed(true);

    const dismissedSurveys = retrieveLocalValue("dismissedSurveys") ?? [];

    dismissedSurveys.push(activeSurvey.id);

    rememberLocalValue({
      key: "dismissedSurveys",
      value: dismissedSurveys,
      ttl: 2.592e9, //30 days
    });
  };

  const onClickBack = () => {
    setCurrentStep({
      currentStep: currentStep - 1,
    });
  };

  const onClickNext = () => {
    //perform validations if any
    const { isValidResponse, responseError } =
      validateSurveyContentResponse(activeContent);

    if (responseError) {
      setSurveyContentError({
        surveyContentError: responseError,
      });
    }

    if (isValidResponse) {
      if (currentStep + 1 < activeSurvey.contents.length) {
        setCurrentStep({
          currentStep: currentStep + 1,
        });
      }
    }
  };

  const onClickSubmit = () => {
    saveActiveSurveyResponse();
  };

  const onClickThankYouBtn = () => {
    const { hasButton, buttonHasLink, buttonLink } = activeContent;

    if (hasButton && buttonHasLink && buttonLink) {
      window.open(buttonLink, "_blank");
    }
  };

  const onResponse = (response) => {
    setActiveContentResponse({
      response,
    });
  };

  const onSelectOption = (data) => {
    setActiveContentOptionIsSelected(data);
  };

  useEffect(() => {
    if (activeSurvey && !currentStep) {
      setCurrentStep({
        currentStep: 1,
      });
    }
  }, [activeSurvey, currentStep]);

  useEffect(() => {
    if (currentStep) {
      setActiveContentId({
        activeContentId: activeSurvey.contents[currentStep - 1].id,
      });
    }
  }, [currentStep]);

  if (!activeSurvey || !activeContent || isDismissed) {
    return null;
  }

  return (
    <FadeIn>
      <div className={`survey-side-out ${isOpen ? "" : "is-closed"}`}>
        <div className="survey-side-out-header" onClick={handleToggleSlideOut}>
          <div className="survey-side-out-header-icons">
            {isOpen ? (
              <i className="fa fa-chevron-up mr-3"></i>
            ) : (
              <i className="fa fa-chevron-down mr-3"></i>
            )}

            <i className="fa fa-times" onClick={handleDismissSlideOut}></i>
          </div>
        </div>

        <div className="survey-side-out-body">
          <div className="quiz">
            <SurveyContent
              {...activeContent}
              onResponse={onResponse}
              onSelectOption={onSelectOption}
              error={surveyContentError}
            />

            <SurveyNavigation
              onClickBack={onClickBack}
              onClickNext={onClickNext}
              onClickSubmit={onClickSubmit}
              onClickThankYouBtn={onClickThankYouBtn}
              hasThankYouBtn={
                activeContent.type === SURVEY_CONTENT_TYPES.ENDING_SCREEN
                  ? activeContent.hasButton
                  : false
              }
              currentStep={currentStep}
              totalSteps={activeSurvey.contents.length}
              nextBtnText={
                activeContent.ui.button.text
                  ? activeContent.ui.button.text
                  : activeContent.ui.button.default
              }
              thankYouBtnText={
                activeContent.ui.button.text
                  ? activeContent.ui.button.text
                  : activeContent.ui.button.default
              }
              disabled={isSavingActiveSurveyResponse}
            />
          </div>
        </div>
      </div>
    </FadeIn>
  );
});
