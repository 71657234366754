import React from "react";
import { IMAGE_ALIGNMENT_OPTIONS } from "../services/lib/surveys/constants";

export default function SurveyContentStatement(props) {
  const { content, image, imageAlignment, showQuotation } = props;

  if (image.src) {
    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.TOP) {
      return (
        <div className="survey-content">
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-img">
              <img src={image.src} />
            </div>

            <div className="survey-content-td">
              {content.text && (
                <div className="d-flex">
                  {showQuotation && <i className="fa fa-quote-left mr-3" />}

                  <h6>{content.text}</h6>

                  {showQuotation && <i className="fa fa-quote-right ml-3" />}
                </div>
              )}

              {content.description && (
                <p className="text-muted">{content.description}</p>
              )}
            </div>
          </div>
        </div>
      );
    }

    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.MIDDLE) {
      return (
        <div className="survey-content">
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-td">
              {content.text && (
                <div className="d-flex">
                  {showQuotation && <i className="fa fa-quote-left mr-3" />}

                  <h6>{content.text}</h6>

                  {showQuotation && <i className="fa fa-quote-right ml-3" />}
                </div>
              )}

              {content.description && (
                <p className="text-muted">{content.description}</p>
              )}
            </div>

            <div className="survey-content-img">
              <img src={image.src} />
            </div>
          </div>
        </div>
      );
    }

    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.LEFT) {
      return (
        <div className="survey-content">
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-img">
              <img src={image.src} />
            </div>

            <div className="survey-content-td-left">
              <div className="survey-content-td">
                {content.text && (
                  <div className="d-flex">
                    {showQuotation && <i className="fa fa-quote-left mr-3" />}

                    <h6>{content.text}</h6>

                    {showQuotation && <i className="fa fa-quote-right ml-3" />}
                  </div>
                )}

                {content.description && (
                  <p className="text-muted">{content.description}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.RIGHT) {
      return (
        <div className="survey-content">
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-td-right">
              <div className="survey-content-td">
                {content.text && (
                  <div className="d-flex">
                    {showQuotation && <i className="fa fa-quote-left mr-3" />}

                    <h6>{content.text}</h6>

                    {showQuotation && <i className="fa fa-quote-right ml-3" />}
                  </div>
                )}

                {content.description && (
                  <p className="text-muted">{content.description}</p>
                )}
              </div>
            </div>

            <div className="survey-content-img">
              <img src={image.src} />
            </div>
          </div>
        </div>
      );
    }
  }
  return (
    <div className="survey-content">
      <div className="survey-content-td-img">
        <div className="survey-content-td">
          {content.text && (
            <div className="d-flex">
              {showQuotation && <i className="fa fa-quote-left mr-3" />}

              <h6>{content.text}</h6>

              {showQuotation && <i className="fa fa-quote-right ml-3" />}
            </div>
          )}

          {content.description && (
            <p className="text-muted">{content.description}</p>
          )}
        </div>
      </div>
    </div>
  );
}
