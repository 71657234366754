import persistCartItems from "./persistCartItems";
import assertCartLimits from "./assertCartLimits";

export default function updateCartItemQty(cartItems, payload) {
  if (!assertCartLimits(cartItems, payload)) {
    return persistCartItems(cartItems);
  }

  const productId = payload?.product?.id ?? payload?.productId;

  const updatedCartItems = cartItems.map(({ product, quantity }) => {
    if (product?.id === productId) {
      const availableQuantity = Number(product?.inventory?.quantity);

      const policy = product?.inventory?.policy;

      if (
        policy === "continue" ||
        (availableQuantity && availableQuantity >= payload.quantity)
      ) {
        return {
          product,
          quantity: payload.quantity,
        };
      }
    }

    return { product, quantity };
  });

  return persistCartItems(updatedCartItems);
}
