import React from "react";
import Link from "next/link";
import ProductQuantityCounter from "./ProductQuantityCounter";
import Notice from "./Notice";
import { useCart } from "../services/hooks/useCart";
import currency from "currency.js";

const CartItem = React.memo(({ product, quantity, allowActions = true }) => {
  const { removeFromCart, updateCartItemQuantity, cartErrors } = useCart();

  const handleRemoveItem = (e) => {
    e.preventDefault();

    removeFromCart({
      productId: product.id,
    });
  };

  const incrementQuantity = (e) => {
    e.preventDefault();

    handleUpdateQuantity(quantity + 1);
  };

  const decrementQuantity = (e) => {
    e.preventDefault();

    handleUpdateQuantity(quantity - 1);
  };

  const handleUpdateQuantity = (newQuantity) => {
    if (newQuantity) {
      updateCartItemQuantity({
        productId: product.id,
        quantity: newQuantity,
      });
    }
  };

  return (
    <li className="list-group-item mx-1">
      {cartErrors[product.id] && (
        <>
          <div className="row">
            <div className="col-12">
              {cartErrors[product.id]?.errors?.map((error) => (
                <Notice
                  message={error}
                  type="danger"
                  dismissable={false}
                  scroll={false}
                />
              ))}
            </div>
          </div>
        </>
      )}

      <div
        className={`row align-items-center ${
          cartErrors[product.id] ? "product-unavailable" : ""
        }`}
      >
        <div className="col-3">
          {product.billingPeriod ? (
            <a href="#" onClick={(e) => e.preventDefault()}>
              <img src={product.images[0].src} className="img-fluid" />
            </a>
          ) : (
            <Link href={`/products/${product.handle || product.id}`}>
              <a>
                <img src={product.images[0].src} className="img-fluid" />
              </a>
            </Link>
          )}
        </div>
        <div className="col">
          <div className="d-flex mb-2 mr-3">
            {product.billingPeriod ? (
              <a
                href="#"
                className="text-body"
                onClick={(e) => e.preventDefault()}
              >
                <span className="text-uppercase font-weight-bold">
                  {product.brand}:
                </span>{" "}
                {product.name}
              </a>
            ) : (
              <Link href={`/products/${product.handle || product.id}`}>
                <a className="text-body">
                  <span className="text-uppercase font-weight-bold">
                    {product.brand}:
                  </span>{" "}
                  {product.name}
                </a>
              </Link>
            )}

            <span className="ml-auto font-weight-bold">
              {currency(product.price).format()}
            </span>
          </div>

          {product.billingPeriod && (
            <p className="mb-7 font-size-sm text-muted">
              {product?.metadata?.billingText}
              <br />
              <strong>Renews automatically</strong>
            </p>
          )}

          <div className="d-flex align-items-center">
            {(product.billingPeriod || !allowActions) && (
              <div className="text-muted">Quantity: {quantity}</div>
            )}

            {!product.billingPeriod && allowActions && (
              <ProductQuantityCounter
                quantity={quantity}
                handleIncrement={incrementQuantity}
                handleDecrement={decrementQuantity}
              />
            )}

            {allowActions && (
              <a
                className="font-size-xs text-gray-400 ml-auto mr-3"
                href="#"
                onClick={handleRemoveItem}
              >
                <i className="fe fe-x" /> Remove
              </a>
            )}
          </div>
        </div>
      </div>
    </li>
  );
});

export default CartItem;
