import roundNumber from "../../../../util/roundNumber";
import storeInLocalStorage from "../../../../util/storeInLocalStorage";

export default function getCartTotal(
  cartSubTotal,
  shippingFee,
  salesTaxAmount,
  discount
) {
  const cartTotal = roundNumber(
    cartSubTotal + shippingFee - discount + salesTaxAmount,
    2
  );

  storeInLocalStorage("cartTotal", cartTotal);

  return cartTotal;
}
