import React from "react";

import { IMAGE_ALIGNMENT_OPTIONS } from "../services/lib/surveys/constants";

export default function SurveyContentEmail(props) {
  const { content, image, imageAlignment, response, error, onResponse } = props;

  const handleResponseChange = (e) => {
    onResponse(e.target.value);
  };

  if (image.src) {
    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.TOP) {
      return (
        <div className="survey-content">
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-img">
              <img src={image.src} />
            </div>

            <div className="survey-content-td">
              {content.text && <h6>{content.text}</h6>}

              {content.description && (
                <p className="text-muted">{content.description}</p>
              )}
            </div>

            <div className="survey-content-input">
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="name@example.com"
                  value={response ?? ""}
                  onChange={handleResponseChange}
                />

                {error && <span className="input-error">{error}</span>}
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.MIDDLE) {
      return (
        <div className="survey-content">
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-td">
              {content.text && <h6>{content.text}</h6>}

              {content.description && (
                <p className="text-muted">{content.description}</p>
              )}
            </div>

            <div className="survey-content-img">
              <img src={image.src} />
            </div>

            <div className="survey-content-input">
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="name@example.com"
                  value={response ?? ""}
                  onChange={handleResponseChange}
                />

                {error && <span className="input-error">{error}</span>}
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.LEFT) {
      return (
        <div className="survey-content">
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-img">
              <img src={image.src} />
            </div>

            <div className="survey-content-td-left">
              <div className="survey-content-td">
                {content.text && <h6>{content.text}</h6>}

                {content.description && (
                  <p className="text-muted">{content.description}</p>
                )}
              </div>

              <div className="survey-content-input">
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="name@example.com"
                    value={response ?? ""}
                    onChange={handleResponseChange}
                  />

                  {error && <span className="input-error">{error}</span>}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.RIGHT) {
      return (
        <div className="survey-content">
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-td-right">
              <div className="survey-content-td">
                {content.text && <h6>{content.text}</h6>}

                {content.description && (
                  <p className="text-muted">{content.description}</p>
                )}
              </div>

              <div className="survey-content-input">
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="name@example.com"
                    value={response ?? ""}
                    onChange={handleResponseChange}
                  />

                  {error && <span className="input-error">{error}</span>}
                </div>
              </div>
            </div>

            <div className="survey-content-img">
              <img src={image.src} />
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="survey-content">
      <div className="survey-content-td-img">
        <div className="survey-content-td">
          {content.text && <h6>{content.text}</h6>}

          {content.description && (
            <p className="text-muted">{content.description}</p>
          )}
        </div>

        <div className="survey-content-input">
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="name@example.com"
              value={response ?? ""}
              onChange={handleResponseChange}
            />

            {error && <span className="input-error">{error}</span>}
          </div>
        </div>
      </div>
    </div>
  );
}
