import { ACTIONS, initialState } from "../contexts/cartState";
import addItemToCart from "./functions/cart/addItemToCart";
import setCartItems from "./functions/cart/setCartItems";
import getCartSubTotal from "./functions/cart/getCartSubTotal";
import getCartTotal from "./functions/cart/getCartTotal";
import determineDiscountAmount from "./functions/cart/determineDiscountAmount";
import removeItemFromCart from "./functions/cart/removeItemFromCart";
import emptyCartInLocalStorage from "./functions/cart/emptyCartInLocalStorage";
import updateCartItemQuantity from "./functions/cart/updateCartItemQuantity";
import getSalesTaxAmount from "./functions/cart/getSalesTaxAmount";
import cartHasSubscription from "./functions/cart/cartHasSubscription";
import getShippingMultiple from "./functions/cart/getShippingMultiple";
import setCartErrors from "./functions/cart/setCartErrors";
import roundNumber from "../../util/roundNumber";
const cartReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_STATE: {
      return action.payload;
    }

    case ACTIONS.SET_CART_ITEMS: {
      const cartItems = setCartItems(action.payload);

      const cartSubTotal = getCartSubTotal(cartItems);

      const discount = determineDiscountAmount(
        cartSubTotal,
        state.shippingFee,
        state.couponCode
      );

      const salesTaxAmount = getSalesTaxAmount(
        cartSubTotal,
        state.shippingFee,
        state.salesTaxRate,
        discount
      );

      const cartTotal = getCartTotal(
        cartSubTotal,
        state.shippingFee,
        salesTaxAmount,
        discount
      );

      const shippingMultiple = getShippingMultiple(cartItems);

      return {
        ...state,
        cartItems,
        cartSubTotal,
        cartTotal,
        salesTaxAmount,
        shippingMultiple,
        discount,
      };
    }

    case ACTIONS.ADD_TO_CART: {
      const cartItems = addItemToCart(state.cartItems, action.payload);

      const cartSubTotal = getCartSubTotal(cartItems);

      const discount = determineDiscountAmount(
        cartSubTotal,
        state.shippingFee,
        state.couponCode
      );

      const salesTaxAmount = getSalesTaxAmount(
        cartSubTotal,
        state.shippingFee,
        state.salesTaxRate,
        discount
      );

      const cartTotal = getCartTotal(
        cartSubTotal,
        state.shippingFee,
        salesTaxAmount,
        discount
      );

      const shippingMultiple = getShippingMultiple(cartItems);

      return {
        ...state,
        cartItems,
        cartSubTotal,
        cartTotal,
        salesTaxAmount,
        shippingMultiple,
        discount,
      };
    }

    case ACTIONS.REMOVE_ITEM_FROM_CART: {
      const cartItems = removeItemFromCart(state.cartItems, action.payload);

      if (cartItems.length === 0) {
        emptyCartInLocalStorage();

        return {
          ...initialState,
          hasInitializedCart: state.hasInitializedCart,
          cartValidationWorker: state.cartValidationWorker,
        };
      }

      const cartSubTotal = getCartSubTotal(cartItems);

      const discount = determineDiscountAmount(
        cartSubTotal,
        state.shippingFee,
        state.couponCode
      );

      const salesTaxAmount = getSalesTaxAmount(
        cartSubTotal,
        state.shippingFee,
        state.salesTaxRate,
        discount
      );

      const cartTotal = getCartTotal(
        cartSubTotal,
        state.shippingFee,
        salesTaxAmount,
        discount
      );

      const shippingMultiple = getShippingMultiple(cartItems);

      return {
        ...state,
        cartItems,
        cartSubTotal,
        cartTotal,
        salesTaxAmount,
        shippingMultiple,
        discount,
      };
    }

    case ACTIONS.EMPTY_CART: {
      emptyCartInLocalStorage();

      return {
        ...initialState,
        hasInitializedCart: state.hasInitializedCart,
        cartValidationWorker: state.cartValidationWorker,
      };
    }

    case ACTIONS.SET_SALES_TAX_RATE: {
      const salesTaxRate = action.payload.salesTaxRate;
      const salesTaxId = action.payload.salesTaxId;

      const discount = determineDiscountAmount(
        state.cartSubTotal,
        state.shippingFee,
        state.couponCode
      );

      const salesTaxAmount = getSalesTaxAmount(
        state.cartSubTotal,
        state.shippingFee,
        salesTaxRate,
        discount
      );

      const cartTotal = getCartTotal(
        state.cartSubTotal,
        state.shippingFee,
        salesTaxAmount,
        discount
      );

      return {
        ...state,
        cartTotal,
        salesTaxRate,
        salesTaxAmount,
        salesTaxId,
        discount,
      };
    }

    case ACTIONS.SET_SHIPPING_FEE: {
      let shippingFee = roundNumber(
        action.payload.shippingFee * state.shippingMultiple,
        2
      );

      if (state.freeShipping === true) {
        shippingFee = 0;
      }

      const discount = determineDiscountAmount(
        state.cartSubTotal,
        shippingFee,
        state.couponCode
      );

      //recalculate the tax amount
      const salesTaxAmount = getSalesTaxAmount(
        state.cartSubTotal,
        shippingFee,
        state.salesTaxRate,
        discount
      );

      const cartTotal = getCartTotal(
        state.cartSubTotal,
        shippingFee,
        salesTaxAmount,
        discount
      );

      return {
        ...state,
        cartTotal,
        shippingFee,
        salesTaxAmount,
        discount,
      };
    }

    case ACTIONS.SET_FREE_SHIPPING: {
      let { freeShipping } = action.payload;

      if (cartHasSubscription(state.cartItems)) {
        freeShipping = false;
      }

      const shippingFee = freeShipping ? 0 : state.shippingFee;

      const discount = determineDiscountAmount(
        state.cartSubTotal,
        shippingFee,
        state.couponCode
      );

      const salesTaxAmount = getSalesTaxAmount(
        state.cartSubTotal,
        shippingFee,
        state.salesTaxRate,
        discount
      );

      const cartTotal = getCartTotal(
        state.cartSubTotal,
        shippingFee,
        salesTaxAmount,
        discount
      );

      return {
        ...state,
        freeShipping,
        shippingFee,
        salesTaxAmount,
        cartTotal,
        discount,
      };
    }

    case ACTIONS.SET_SHIPPING_MULTIPLE: {
      const shippingMultiple = action.payload.shippingMultiple;

      const shippingFee = roundNumber(
        (state.shippingFee / state.shippingMultiple) * shippingMultiple
      );

      const discount = determineDiscountAmount(
        state.cartSubTotal,
        shippingFee,
        state.couponCode
      );

      const salesTaxAmount = getSalesTaxAmount(
        state.cartSubTotal,
        shippingFee,
        state.salesTaxRate,
        discount
      );

      const cartTotal = getCartTotal(
        state.cartSubTotal,
        shippingFee,
        salesTaxAmount,
        discount
      );

      return {
        ...state,
        shippingFee,
        shippingMultiple,
        salesTaxAmount,
        cartTotal,
        discount,
      };
    }

    case ACTIONS.UPDATE_CART_ITEM_QUANTITY: {
      const cartItems = updateCartItemQuantity(state.cartItems, action.payload);

      if (cartItems.length === 0) {
        emptyCartInLocalStorage();

        return {
          ...initialState,
          hasInitializedCart: state.hasInitializedCart,
          cartValidationWorker: state.cartValidationWorker,
        };
      }

      const cartSubTotal = getCartSubTotal(cartItems);

      const discount = determineDiscountAmount(
        cartSubTotal,
        state.shippingFee,
        state.couponCode
      );

      const salesTaxAmount = getSalesTaxAmount(
        cartSubTotal,
        state.shippingFee,
        state.salesTaxRate,
        discount
      );

      const cartTotal = getCartTotal(
        cartSubTotal,
        state.shippingFee,
        salesTaxAmount,
        discount
      );

      return {
        ...state,
        cartItems,
        cartSubTotal,
        salesTaxAmount,
        cartTotal,
        discount,
      };
    }

    case ACTIONS.APPLY_COUPON: {
      const { couponCode } = action.payload;

      const discount = determineDiscountAmount(
        state.cartSubTotal,
        state.shippingFee,
        couponCode
      );

      const salesTaxAmount = getSalesTaxAmount(
        state.cartSubTotal,
        state.shippingFee,
        state.salesTaxRate,
        discount
      );

      //calculate new cart total
      const cartTotal = getCartTotal(
        state.cartSubTotal,
        state.shippingFee,
        salesTaxAmount,
        discount
      );

      return {
        ...state,
        cartTotal,
        discount,
        salesTaxAmount,
        couponCode,
      };
    }

    case ACTIONS.REMOVE_COUPON: {
      const couponCode = {};

      const discount = determineDiscountAmount(
        state.cartSubTotal,
        state.shippingFee,
        couponCode
      );

      const salesTaxAmount = getSalesTaxAmount(
        state.cartSubTotal,
        state.shippingFee,
        state.salesTaxRate,
        discount
      );

      const cartTotal = getCartTotal(
        state.cartSubTotal,
        state.shippingFee,
        salesTaxAmount,
        discount
      );

      return {
        ...state,
        discount,
        cartTotal,
        salesTaxAmount,
        couponCode,
      };
    }

    case ACTIONS.SET_WAITLIST_TOTAL_DUE: {
      const { waitlistTotalDue } = action.payload;

      return { ...state, waitlistTotalDue };
    }

    case ACTIONS.SET_IS_VALID_CART: {
      const { isValidCart } = action.payload;

      return { ...state, isValidCart };
    }

    case ACTIONS.SET_CART_ERRORS: {
      const { cartErrors } = action.payload;

      return { ...state, cartErrors };
    }

    case ACTIONS.SET_HAS_INITIALIZED_CART: {
      const { hasInitializedCart } = action.payload;

      return { ...state, hasInitializedCart };
    }

    case ACTIONS.SET_CART_VALIDATION_WORKER: {
      const { cartValidationWorker } = action.payload;

      return { ...state, cartValidationWorker };
    }

    case ACTIONS.SET_CART_RETENTION_WORKER: {
      const { cartRetentionWorker } = action.payload;

      return { ...state, cartRetentionWorker };
    }

    case ACTIONS.SET_CART_HAS_SUBSCRIPTION: {
      const { cartHasSubscription } = action.payload;

      return { ...state, cartHasSubscription };
    }

    case ACTIONS.SET_IS_LOYALTY_POINTS_APPLIED: {
      const { isLoyaltyPointsApplied } = action.payload;

      return { ...state, isLoyaltyPointsApplied };
    }

    case ACTIONS.SET_HAS_UPDATED_LOYALTY_POINTS_BALANCE: {
      const { hasUpdatedLoyaltyPointsBalance } = action.payload;

      return { ...state, hasUpdatedLoyaltyPointsBalance };
    }

    default: {
      return state;
    }
  }
};

export default cartReducer;
