export default function openModal(selector, options = {}) {
  if (selector && typeof selector === "string") {
    $(selector).modal("show");

    const { onShow, onHide } = options;

    if (typeof onShow === "function") {
      $(selector).on("show.bs.modal", onShow);
    }

    if (typeof onHide === "function") {
      $(selector).on("hide.bs.modal", onHide);
    }
  }
}
