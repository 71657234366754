import { useState, useEffect } from "react";
import { useSnackbar } from "./useSnackbar";

export const useIsOnline = () => {
  const [isOnline, setIsOnline] = useState(true);
  const [hasLostConnectionPreviously, setHasLostConnectionPreviously] =
    useState(false);
  const [offlineSnackbarId, setOfflineSnackbarId] = useState("");

  const { addSnackbar, removeSnackbar } = useSnackbar();

  useEffect(() => {
    if (isOnline) {
      if (hasLostConnectionPreviously) {
        removeSnackbar(offlineSnackbarId);

        addSnackbar("You're back online.", {
          type: "success",
        });
      }
    } else {
      setOfflineSnackbarId(
        addSnackbar("You're offline! Please connect to the internet.", {
          type: "danger",
          autoDismiss: false,
        })
      );

      setHasLostConnectionPreviously(true);
    }
  }, [isOnline]);

  useEffect(() => {
    setIsOnline(window.navigator.onLine);
  }, [typeof window !== "undefined" && window.navigator.onLine]);

  return {
    isOnline,
  };
};
