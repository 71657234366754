import React, { createContext, useEffect, useReducer } from "react";
import trackingReducer from "../reducers/trackingReducer";
import { useGlobal } from "../../services/hooks/useGlobal";
import { usePathname } from "../../services/hooks/usePathname";
import { useRouter } from "next/router";

//Actions
export const ACTIONS = {
  SET_STATE: "SetState",
  SET_TRACKING_ID: "SetTrackingId",
  SET_IS_TRACKING: "SetIsTracking",
  SET_TRACKING_ERROR: "SetTrackingError",
  SET_TRACKING_INFO: "SetTrackingInfo",
  SET_ACTIVELY_TRACKED_SHIPMENT: "SetActivelyTrackedShipment",
  SET_ACTIVELY_TRACKED_SHIPMENT_INDEX: "SetActivelyTrackedShipmentIndex",
};

// Initial state
const initialState = {
  trackingId: null,
  isTracking: false,
  trackingError: null,
  trackingInfo: null,
  activelyTrackedShipment: null,
  activelyTrackedShipmentIndex: null,
};

// Create context
export const TrackingContext = createContext(initialState);

// Provider component
export const TrackingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(trackingReducer, initialState);

  const { apiBaseUrl } = useGlobal();

  const { pathname } = usePathname();

  const router = useRouter();

  const setTrackingId = (payload) => {
    dispatch({
      type: ACTIONS.SET_TRACKING_ID,
      payload,
    });
  };

  const setIsTracking = (payload) => {
    dispatch({
      type: ACTIONS.SET_IS_TRACKING,
      payload,
    });
  };

  const setTrackingError = (payload) => {
    dispatch({
      type: ACTIONS.SET_TRACKING_ERROR,
      payload,
    });
  };

  const setTrackingInfo = (payload) => {
    dispatch({
      type: ACTIONS.SET_TRACKING_INFO,
      payload,
    });
  };

  const setActivelyTrackedShipment = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVELY_TRACKED_SHIPMENT,
      payload,
    });
  };

  const setActivelyTrackedShipmentIndex = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVELY_TRACKED_SHIPMENT_INDEX,
      payload,
    });
  };

  const track = async (trackingId) => {
    try {
      setIsTracking({
        isTracking: true,
      });

      const response = await fetch(
        `${apiBaseUrl}/tracking?trackingId=${trackingId}`,
        {
          credentials: "include",
        }
      );

      const { data, error } = await response.json();

      setIsTracking({
        isTracking: false,
      });

      if (data) {
        const { trackingInfo } = data;

        if (trackingInfo?.length) {
          setTrackingInfo({
            trackingInfo,
          });

          setTrackingError({
            trackingError: null,
          });
        } else {
          setTrackingInfo({
            trackingInfo: null,
          });

          setTrackingError({
            trackingError:
              "There are no shipments associated with the tracking ID you provided. Please check back at a later date or subscribe to shipping notifications on the order status page to receive updates via SMS.",
          });
        }
      }

      if (error) {
        setTrackingInfo({
          trackingInfo: null,
        });

        setTrackingError({
          trackingError: error.userMessage,
        });
      }
    } catch {
      setIsTracking({
        isTracking: false,
      });

      setTrackingInfo({
        trackingInfo: null,
      });

      setTrackingError({
        trackingError:
          "This service is unavailable at this time. Please try again later. If the problem persists, please contact support.",
      });
    }
  };

  const resetTracking = () => {
    dispatch({
      type: ACTIONS.SET_STATE,
      payload: initialState,
    });

    if (router.query.trackingId) {
      router.push(`/${pathname}`);
    }
  };

  useEffect(() => {
    if (router.query.trackingId) {
      setTrackingId({
        trackingId: router.query.trackingId,
      });
    } else {
      setTrackingId({
        trackingId: null,
      });
    }
  }, [router]);

  useEffect(() => {
    if (state.trackingId) {
      track(state.trackingId);
    } else {
      resetTracking();
    }
  }, [state.trackingId]);

  useEffect(() => {
    if (
      state.trackingInfo &&
      typeof state.activelyTrackedShipmentIndex === "number"
    ) {
      setActivelyTrackedShipment({
        activelyTrackedShipment:
          state.trackingInfo[state.activelyTrackedShipmentIndex] ?? null,
      });
    } else {
      setActivelyTrackedShipment({
        activelyTrackedShipment: null,
      });
    }
  }, [state.trackingInfo, state.activelyTrackedShipmentIndex]);

  const value = {
    ...state,
    setTrackingId,
    setActivelyTrackedShipment,
    setActivelyTrackedShipmentIndex,
    resetTracking,
  };

  return (
    <TrackingContext.Provider value={value}>
      {children}
    </TrackingContext.Provider>
  );
};
