import React from "react";
import GetHelpButton from "./GetHelpButton";

export default React.memo(function AppBase() {
  return (
    <>
      <GetHelpButton />
    </>
  );
});
