export default function retrieveFromLocalStorage(key) {
  if (typeof window === "undefined") return;

  const itemStr = localStorage?.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();

  if (item.expiry) {
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage?.removeItem(key);
      return null;
    }
  }

  return item.value;
}
