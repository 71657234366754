export const addSnackbar = (snackbars, { id, message, options }) => {
  if (!message) {
    return;
  }

  const { allowDuplicates } = options;

  for (const snackbar of snackbars) {
    if (snackbar?.id === id) {
      //snackbar already added
      return snackbars;
    }

    if (!allowDuplicates) {
      if (snackbar?.message === message) {
        return snackbars;
      }
    }
  }

  return [...snackbars, { id, message, ...options }];
};

export default addSnackbar;
