import { ACTIONS } from "../contexts/snackbarState";

const surveyResponseReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_STATE: {
      return action.payload;
    }

    default:
      return state;
  }
};

export default surveyResponseReducer;
