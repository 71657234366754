import React from "react";

export default React.memo(function SurveyNavigation({
  onClickBack,
  onClickNext,
  onClickSubmit,
  onClickThankYouBtn,
  hasThankYouBtn,
  currentStep,
  totalSteps,
  backBtnText = "BACK",
  nextBtnText = "NEXT",
  submitBtnText = "SUBMIT",
  thankYouBtnText = "Home",
  disabled = false,
}) {
  return (
    <>
      {currentStep > 1 && currentStep !== totalSteps && (
        <button
          className="btn btn-outline-dark mr-4 mb-5"
          onClick={onClickBack}
          disabled={disabled}
        >
          {backBtnText}
        </button>
      )}

      {currentStep < totalSteps - 1 && (
        <button
          className="btn btn-dark mr-4 mb-5"
          onClick={onClickNext}
          disabled={disabled}
        >
          {nextBtnText}
        </button>
      )}

      {currentStep === totalSteps - 1 && (
        <button
          className="btn btn-dark mr-4 mb-5"
          onClick={onClickSubmit}
          disabled={disabled}
        >
          {submitBtnText}
        </button>
      )}

      {currentStep === totalSteps && hasThankYouBtn && (
        <button className="btn btn-dark mr-4 mb-5" onClick={onClickThankYouBtn}>
          {thankYouBtnText}
        </button>
      )}
    </>
  );
});
