import React, { useState, useEffect } from "react";
import { useRedirect } from "../services/hooks/useRedirect";
import { useTimeout } from "../services/hooks/useTimeout";
import { useSnackbar } from "../services/hooks/useSnackbar";

const TYPE_REGEX =
  /^gray|gray-dark|primary|secondary|success|info|warning|danger|light|dark$/;
const CTA_BTN_REGEX =
  /^gray|gray-dark|primary|secondary|success|info|warning|danger|light|dark$/;
const ICON_REGEX =
  /^check-circle|info|exclamation-circle|exclamation-triangle|fa-exclamation$/;

const Snackbar = React.memo(
  ({
    id,
    previousSnackbarId,
    message,
    type,
    dismissable = true,
    callToAction,
    callToActionLink,
    callToActionFn,
    callToActionBtnType = "light",
    autoDismiss = true,
    dismissAfter = 3000,
    onDismiss,
    icon,
    placement = "bottom-center",
  }) => {
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarType, setSnackbarType] = useState(
      "border border-dark text-dark"
    );
    const [snackbarCallToAction, setSnackbarCallToAction] = useState();
    const [snackbarCallToActionLink, setSnackbarCallToActionLink] = useState();
    const [snackbarCallToActionFn, setSnackbarCallToActionFn] = useState();
    const [snackbarCallToActionBtnType, setSnackbarCallToActionBtnType] =
      useState("btn btn-sm btn-light");
    const [snackbarIcon, setSnackbarIcon] = useState();
    const [isDismissable, setIsDismissable] = useState(!!dismissable);
    const [isDismissed, setIsDismissed] = useState(false);

    const { redirect } = useRedirect();

    const { removeSnackbar } = useSnackbar();

    const handleOnDismiss = () => {
      if (typeof onDismiss === "function") {
        onDismiss();
      }
    };

    const { startTimeout, pauseTimeout, resumeTimeout } = useTimeout(() => {
      handleOnDismiss();
      removeSnackbar(id);
    }, dismissAfter);

    const handleDismiss = (e) => {
      e.preventDefault();

      handleOnDismiss();

      setIsDismissed(true);
    };

    const handleCallToActionClick = (e) => {
      e.preventDefault();

      if (callToActionFn) {
        callToActionFn();
      } else if (callToActionLink) {
        redirect(callToActionLink);
      }

      if (isDismissable && !isDismissed) {
        handleOnDismiss();

        setIsDismissed(true);
      }
    };

    const handleMouseleave = () => {
      if (autoDismiss) {
        resumeTimeout();
      }
    };

    const handleMouseEnter = () => {
      if (autoDismiss) {
        pauseTimeout();
      }
    };

    //STYLING
    let styles = {};

    const previousSnackbar = previousSnackbarId
      ? document.getElementById(`snackbar-${previousSnackbarId}`)
      : null;

    let bottomTopOffset = `${
      previousSnackbar
        ? previousSnackbar.getBoundingClientRect().bottom + 30
        : 30
    }px`;

    let leftRightOffset = "10px";

    switch (placement) {
      case "bottom-left": {
        styles.left = leftRightOffset;
        styles.bottom = bottomTopOffset;
        styles.marginLeft = "0";

        break;
      }

      case "bottom-right": {
        styles.right = leftRightOffset;
        styles.bottom = bottomTopOffset;

        break;
      }

      case "bottom-center": {
        styles.left = "50%";
        styles.bottom = bottomTopOffset;

        break;
      }

      case "top-left": {
        styles.left = leftRightOffset;
        styles.top = bottomTopOffset;
        styles.marginLeft = "0";

        break;
      }

      case "top-right": {
        styles.right = leftRightOffset;
        styles.top = bottomTopOffset;

        break;
      }

      case "top-center": {
        styles.left = `50%`;
        styles.top = bottomTopOffset;

        break;
      }

      default: {
        styles.left = "50%";
        styles.bottom = bottomTopOffset;

        break;
      }
    }

    useEffect(() => {
      if (autoDismiss) {
        startTimeout();
      }
    }, [autoDismiss]);

    useEffect(() => {
      if (isDismissed) {
        removeSnackbar(id);
      }
    }, [isDismissed]);

    useEffect(() => {
      setSnackbarMessage(message);
    }, [message]);

    useEffect(() => {
      if (TYPE_REGEX.test(type)) {
        setSnackbarType(`border border-${type} text-${type}`);

        if (!icon) {
          switch (type) {
            case "success": {
              setSnackbarIcon("fas fa-check-circle");
              break;
            }

            case "danger": {
              setSnackbarIcon("fas fa-exclamation-circle");
              break;
            }

            case "warning": {
              setSnackbarIcon("fas fa-exclamation-triangle");
              break;
            }

            case "info": {
              setSnackbarIcon("fas fa-exclamation");
              break;
            }

            default: {
            }
          }
        }
      }
    }, [type]);

    useEffect(() => {
      setSnackbarCallToAction(callToAction);
    }, [callToAction]);

    useEffect(() => {
      setSnackbarCallToActionLink(callToActionLink);
    }, [callToActionLink]);

    useEffect(() => {
      if (typeof callToActionFn === "function") {
        setSnackbarCallToActionFn(() => callToActionFn);
      }
    }, [callToActionFn]);

    useEffect(() => {
      if (typeof dismissable === "boolean") {
        setIsDismissable(dismissable);
      }
    }, [dismissable]);

    useEffect(() => {
      if (ICON_REGEX.test(icon)) {
        setSnackbarIcon(`fas fa-${icon}`);
      }
    }, [icon]);

    useEffect(() => {
      if (CTA_BTN_REGEX.test(callToActionBtnType)) {
        setSnackbarCallToActionBtnType(
          `btn btn-xxs btn-${callToActionBtnType}`
        );
      }
    }, [callToActionBtnType]);

    if (!snackbarMessage) {
      return null;
    }

    return (
      <div
        id={`snackbar-${id}`}
        className={`snackbar ${snackbarType}`}
        style={styles}
        onMouseLeave={handleMouseleave}
        onMouseEnter={handleMouseEnter}
      >
        <div className="snackbar-body">
          {snackbarIcon && (
            <i className={`${snackbarIcon} ml-3 font-size-h4`}></i>
          )}

          <span className="ml-3">{snackbarMessage}</span>

          {snackbarCallToAction &&
            (snackbarCallToActionLink || snackbarCallToActionFn) && (
              <a
                href="#"
                className={`${snackbarCallToActionBtnType} ml-3`}
                onClick={handleCallToActionClick}
              >
                {snackbarCallToAction}
              </a>
            )}

          {isDismissable &&
            !(snackbarCallToActionLink || snackbarCallToActionFn) && (
              <i
                className="fas fa-times ml-3 clickable float-right"
                onClick={handleDismiss}
              ></i>
            )}
        </div>
      </div>
    );
  }
);

export default Snackbar;
