import { ACTIONS } from "../contexts/authState";

const authReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_STATE: {
      return action.payload;
    }

    case ACTIONS.SET_ACCESS_TOKEN: {
      const { accessToken } = action.payload;

      return { ...state, accessToken };
    }

    case ACTIONS.SET_USER: {
      const { user } = action.payload;

      return { ...state, user };
    }

    case ACTIONS.SET_AUTH_STATUS: {
      const { authStatus } = action.payload;

      return { ...state, authStatus };
    }

    case ACTIONS.SET_REFRESH_STATUS: {
      const { refreshStatus } = action.payload;

      return { ...state, refreshStatus };
    }

    case ACTIONS.SET_IS_SUBSCRIBED: {
      const { isSubscribed } = action.payload;

      return {
        ...state,
        isSubscribed,
      };
    }

    case ACTIONS.SET_SUBSCRIPTION_STATUS: {
      const { subscriptionStatus } = action.payload;

      return {
        ...state,
        subscriptionStatus,
      };
    }

    case ACTIONS.SET_AUTH_TOKEN_VERIFICATION_STATUS: {
      const { authTokenVerificationStatus } = action.payload;

      return {
        ...state,
        authTokenVerificationStatus,
      };
    }

    case ACTIONS.SET_IS_AUTH_TOKEN_VERIFIED: {
      const { isAuthTokenVerified } = action.payload;

      return {
        ...state,
        isAuthTokenVerified,
      };
    }

    case ACTIONS.SET_NEXT_ACCESS_TOKEN_REFRESH_AT: {
      const { nextAccessTokenRefreshAt } = action.payload;

      return {
        ...state,
        nextAccessTokenRefreshAt,
      };
    }

    case ACTIONS.SET_AUTH_REFRESH_WORKER: {
      const { authRefreshWorker } = action.payload;

      return {
        ...state,
        authRefreshWorker,
      };
    }

    default:
      return state;
  }
};

export default authReducer;
