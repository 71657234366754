import { useState, useEffect } from "react";
import { useApiCall } from "./useApiCall";
import { useRouter } from "next/router";

export const useSurvey = (autoFetch = false) => {
  const [survey, setSurvey] = useState();
  const [surveyStatus, setSurveyStatus] = useState("idle");
  const [surveyError, setSurveyError] = useState();
  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();
  const router = useRouter();

  const fetchSurvey = (surveyId) => {
    if (surveyId) {
      setSurveyStatus("pending");

      const requestOptions = {
        url: `/surveys/${surveyId}`,
      };

      executeApiCall(requestOptions);

      setSurveyError();
    }
  };

  useEffect(() => {
    if (autoFetch === true) {
      const { surveyId } = router.query;

      if (surveyId) {
        fetchSurvey(surveyId);
      }
    }
  }, [router, autoFetch]);

  useEffect(() => {
    switch (apiCallStatus) {
      case "success":
        if (apiCallResponse?.data?.data) {
          const { survey } = apiCallResponse.data.data;

          setSurvey(survey);
          setSurveyStatus("ready");
        }

        if (apiCallResponse?.data?.error) {
          const { error } = apiCallResponse.data;

          setSurveyError(error.userMessage);
          setSurveyStatus("ready");
        }
        break;
      case "error":
        setSurveyStatus("ready");
        break;
    }
  }, [apiCallStatus, apiCallResponse]);

  return {
    fetchSurvey,
    survey,
    surveyStatus,
    surveyError,
  };
};
