import { ACTIONS } from "../contexts/snackbarState";
import { addSnackbar } from "./functions/snackbar/addSnackbar";
import { removeSnackbar } from "./functions/snackbar/removeSnackbar";
import { updateSnackbar } from "./functions/snackbar/updateSnackbar";

const snackbarReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_STATE: {
      return action.payload;
    }

    case ACTIONS.ADD_SNACKBAR: {
      const snackbars = addSnackbar(state.snackbars, action.payload);

      return { ...state, snackbars };
    }

    case ACTIONS.REMOVE_SNACKBAR: {
      const snackbars = removeSnackbar(state.snackbars, action.payload);

      return { ...state, snackbars };
    }

    case ACTIONS.UPDATE_SNACKBAR: {
      const snackbars = updateSnackbar(state.snackbars, action.payload);

      return { ...state, snackbars };
    }

    default:
      return state;
  }
};

export default snackbarReducer;
