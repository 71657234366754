import isValidEmail from "../../../util/isValidEmail";

export default function validateSurveyContentResponse(activeContent) {
  if (!activeContent) {
    return {
      isValidResponse: false,
      responseError: "Something went wrong, please reload the page.",
    };
  }

  const { type, options, response, required, maxCharacters } = activeContent;

  switch (type) {
    case "email": {
      if (required) {
        if (!response) {
          return {
            isValidResponse: false,
            responseError: "This question is required",
          };
        }
      }

      if (response) {
        if (!isValidEmail(response)) {
          return {
            isValidResponse: false,
            responseError: "Please enter a valid email address",
          };
        }
      }

      return {
        isValidResponse: true,
        responseError: null,
      };
    }

    case "multiple-choice": {
      if (required) {
        const isOptionSelected = options.some((option) => option.isSelected);

        if (!isOptionSelected) {
          return {
            isValidResponse: false,
            responseError: "This question is required",
          };
        }
      }

      return {
        isValidResponse: true,
        responseError: null,
      };
    }

    case "picture-choice": {
      if (required) {
        const isOptionSelected = options.some((option) => option.isSelected);

        if (!isOptionSelected) {
          return {
            isValidResponse: false,
            responseError: "This question is required",
          };
        }
      }

      return {
        isValidResponse: true,
        responseError: null,
      };
    }

    case "short-text": {
      if (required) {
        if (!response) {
          return {
            isValidResponse: false,
            responseError: "This question is required",
          };
        }
      }

      if (response) {
        if (
          maxCharacters?.shouldLimitMaxCharacters &&
          typeof maxCharacters?.limit === "number"
        ) {
          if (response.length > maxCharacters.limit) {
            return {
              isValidResponse: false,
              responseError: `You're only allowed ${maxCharacters.limit} characters for this answer`,
            };
          }
        }
      }

      return {
        isValidResponse: true,
        responseError: null,
      };
    }

    case "long-text": {
      if (required) {
        if (!response) {
          return {
            isValidResponse: false,
            responseError: "This question is required",
          };
        }
      }

      if (response) {
        if (
          maxCharacters?.shouldLimitMaxCharacters &&
          typeof maxCharacters?.limit === "number"
        ) {
          if (response.length > maxCharacters.limit) {
            return {
              isValidResponse: false,
              responseError: `You're only allowed ${maxCharacters.limit} characters for this answer`,
            };
          }
        }
      }

      return {
        isValidResponse: true,
        responseError: null,
      };
    }
  }

  return {
    isValidResponse: true,
    responseError: null,
  };
}
