import countWordsInString from "./countWordsInString";

export default function calculateSurveyTimeToComplete({ survey }) {
  try {
    let wordCount = 0;

    const contents = survey.contents;

    for (const { content, options, ui, image } of contents) {
      const { text, description } = content;

      if (text) {
        wordCount += countWordsInString(text);
      }

      if (description) {
        wordCount += countWordsInString(description);
      }

      if (options) {
        for (const option of options) {
          if (option.value) {
            wordCount += countWordsInString(option.value);
          }

          if (option.label) {
            wordCount += countWordsInString(option.label);
          }

          if (option.image && option.image.src) {
            wordCount += 10;
          }
        }
      }

      if (image.src) {
        wordCount += 10;
      }

      if (ui && ui.button && ui.button.text) {
        wordCount += countWordsInString(ui.button.text);
      }
    }

    return parseInt(Math.ceil(wordCount / 200));
  } catch (e) {
    throw e;
  }
}
