import time from "../../time/time";
import canApplyCoupon from "./canApplyCoupon";

export default function isValidCoupon(coupon, { user, cartItems }) {
  //Validity checks

  if (!coupon.valid) {
    return {
      error: `The coupon code ${coupon.id.toUpperCase()} is no longer valid.`,
      isValid: false,
    };
  } else if (!canApplyCoupon(cartItems, coupon)) {
    return {
      error: `The coupon code ${coupon.id.toUpperCase()} is not valid for some of the item(s) in your cart.`,
      isValid: false,
    };
  } else if (
    user &&
    coupon.metadata?.applies_to?.users?.length &&
    coupon.metadata?.applies_to?.users?.indexOf(user.id) === -1
  ) {
    return {
      error: `Sorry! The coupon code ${coupon.id.toUpperCase()} is not valid for this account.`,
      isValid: false,
    };
  } else if (!user && coupon?.metadata?.applies_to?.users?.length) {
    return {
      error: `Sorry! The coupon code ${coupon.id.toUpperCase()} is not valid for this account.`,
      isValid: false,
    };
  } else if (
    coupon.max_redemptions &&
    coupon.times_redeemed >= coupon.max_redemptions
  ) {
    return {
      error: `Sorry! The coupon code ${coupon.id.toUpperCase()} can no longer be redeemed.`,
      isValid: false,
    };
  } else if (coupon.redeem_by && coupon.redeem_by < time().unix()) {
    return {
      error: `Sorry! The coupon code ${coupon.id.toUpperCase()} has expired.`,
      isValid: false,
    };
  }

  return { error: null, isValid: true };
}
