import { useState, useEffect } from "react";
import { useApiCall } from "./useApiCall";
import { useRouter } from "next/router";

export const useFetchSurveyResponse = (autoFetch = false) => {
  const [fetchedSurveyResponse, setFetchedSurveyResponse] = useState();
  const [fetchSurveyResponseStatus, setFetchSurveyResponseStatus] =
    useState("idle");
  const [fetchSurveyResponseError, setFetchSurveyResponseError] = useState();
  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();
  const router = useRouter();

  const fetchSurveyResponse = (surveyResponseId) => {
    if (surveyResponseId) {
      setFetchSurveyResponseStatus("pending");

      const requestOptions = {
        url: `/surveys/responses/${surveyResponseId}`,
      };

      executeApiCall(requestOptions);

      setFetchSurveyResponseError();
    }
  };

  const resetFetchSurveyResponse = () => {
    setFetchedSurveyResponse();
    setFetchSurveyResponseError();
    setFetchSurveyResponseStatus("idle");
  };

  useEffect(() => {
    if (autoFetch === true) {
      const { surveyResponseId } = router.query;

      if (surveyResponseId) {
        fetchSurveyResponse(surveyResponseId);
      }
    }
  }, [router, autoFetch]);

  useEffect(() => {
    switch (apiCallStatus) {
      case "success":
        if (apiCallResponse?.data?.data) {
          const { surveyResponse } = apiCallResponse.data.data;

          setFetchedSurveyResponse(surveyResponse);
          setFetchSurveyResponseStatus("ready");
        }

        if (apiCallResponse?.data?.error) {
          const { error } = apiCallResponse.data;

          setFetchSurveyResponseError(error.userMessage);
          setFetchSurveyResponseStatus("ready");
        }
        break;
      case "error":
        setFetchSurveyResponseStatus("ready");
        break;
    }
  }, [apiCallStatus, apiCallResponse]);

  return {
    fetchSurveyResponse,
    resetFetchSurveyResponse,
    fetchedSurveyResponse,
    fetchSurveyResponseStatus,
    fetchSurveyResponseError,
  };
};
