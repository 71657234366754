import { useState, useEffect } from "react";
import { useApiCall } from "./useApiCall";

export const useSurveysList = (autoFetch = false) => {
  const [surveysList, setSurveysList] = useState([]);
  const [surveysListError, setSurveysListError] = useState();
  const [surveysListTotalLength, setSurveysListTotalLength] = useState(1);
  const [surveysListNumPages, setSurveysListNumPages] = useState(1);
  const [surveysListStatus, setSurveysListStatus] = useState("idle");

  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();

  const fetchSurveysList = (options = {}) => {
    let url = `/surveys`;

    const { page, limit } = options;

    if (!page && autoFetch) {
      url = url + `?limit=all`;
    } else {
      if (page) {
        url = url + `?page=${page}`;
      }

      if (limit) {
        url = url + (page ? `&limit=${limit}` : `?limit=${limit}`);
      }
    }

    const requestOptions = {
      url,
    };

    executeApiCall(requestOptions);

    setSurveysListStatus("pending");
  };

  useEffect(() => {
    if (autoFetch) {
      fetchSurveysList();
    }
  }, [autoFetch]);

  useEffect(() => {
    switch (apiCallStatus) {
      case "success":
        if (apiCallResponse.data.data) {
          const { surveys, recordsTotal, numPages } = apiCallResponse.data.data;

          setSurveysList(surveys);
          setSurveysListTotalLength(recordsTotal);
          setSurveysListNumPages(numPages);
          setSurveysListStatus("ready");
        } else {
          setSurveysListError(apiCallResponse.data.error.userMessage);
          setSurveysListStatus("ready");
        }
        break;
      case "error":
        setSurveysListStatus("ready");
        break;
    }
  }, [apiCallStatus, apiCallResponse]);

  return {
    surveysList,
    surveysListTotalLength,
    surveysListNumPages,
    surveysListError,
    surveysListStatus,
    fetchSurveysList,
  };
};
