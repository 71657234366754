import React, { createContext, useReducer } from "react";
import snackbarReducer from "../reducers/snackbarReducer";
import Snackbar from "../../components/Snackbar";

//Actions
export const ACTIONS = {
  SET_STATE: "SetState",
  ADD_SNACKBAR: "AddSnackbar",
  REMOVE_SNACKBAR: "RemoveSnackbar",
  UPDATE_SNACKBAR: "UpdateSnackbar",
};

// Initial state
const initialState = {
  snackbars: [],
};

// Create context
export const SnackbarContext = createContext(initialState);

// Provider component
export const SnackbarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(snackbarReducer, initialState);

  const add = (payload) => {
    dispatch({
      type: ACTIONS.ADD_SNACKBAR,
      payload,
    });
  };

  const remove = (payload) => {
    dispatch({
      type: ACTIONS.REMOVE_SNACKBAR,
      payload,
    });
  };

  const update = (payload) => {
    dispatch({
      type: ACTIONS.UPDATE_SNACKBAR,
      payload,
    });
  };

  const resetSnackbars = () => {
    dispatch({
      type: ACTIONS.SET_STATE,
      payload: initialState,
    });
  };

  const value = {
    ...state,
    add,
    remove,
    update,
    resetSnackbars,
  };

  return (
    <SnackbarContext.Provider value={value}>
      {children}

      {state.snackbars.map((snackbar, index) => (
        <Snackbar
          key={snackbar.id}
          index={index}
          previousSnackbarId={index > 0 ? state.snackbars[index - 1].id : null}
          {...snackbar}
        />
      ))}
    </SnackbarContext.Provider>
  );
};
