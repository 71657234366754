import { useRouter } from "next/router";

export const useRedirect = () => {
  const router = useRouter();

  const redirect = (url) => {
    const { next } = router.query;

    if (next) {
      router.push(next);
    } else {
      router.push(url);
    }
  };

  return { redirect };
};
