//GLOBAL CSS
import "../public/libs/@fontawesome/fontawesome-free/css/all.min.css";
import "../public/libs/@fancyapps/fancybox/dist/jquery.fancybox.min.css";
import "../public/libs/highlightjs/styles/vs2015.css";
import "../public/libs/flickity/dist/flickity.css";
import "../public/libs/flickity-fade/flickity-fade.css";
import "../public/fonts/feather/feather.css";
import "react-credit-cards/es/styles-compiled.css";

//EXTRA CSS
import "../public/css/theme.css";
import "../public/css/extras/main.css";
import "../public/css/extras/alert.css";
import "../public/css/extras/loader.css";
import "../public/css/extras/errors.css";
import "../public/css/extras/notice.css";
import "../public/css/extras/checkout.css";
import "../public/css/extras/account.css";
import "../public/css/extras/quiz.css";
import "../public/css/extras/social.css";
import "../public/css/extras/password-recovery.css";
import "../public/css/extras/overlays.css";
import "../public/css/extras/quantity-counter.css";
import "../public/css/extras/snackbar.css";
import "../public/css/extras/surveys.css";
import "../public/css/extras/tracking.css";
import "../public/css/extras/cursor.css";
import "../public/css/extras/addToCalendar.css";

//REACT
import React, { useRef, useEffect, memo } from "react";

//HOOKS
import { useRouter } from "next/router";

//COMPONENTS
import AppShell from "../components/AppShell";

//Global Providers
import { GlobalProvider } from "../state/contexts/globalState";
import { AuthProvider } from "../state/contexts/authState";
import { CartProvider } from "../state/contexts/cartState";
import { AccountProvider } from "../state/contexts/accountState";
import { SnackbarProvider } from "../state/contexts/snackbarState";
import { QuizProvider } from "../state/contexts/quizState";
import { SurveysProvider } from "../state/contexts/surveysState";
import { SurveyResponseProvider } from "../state/contexts/surveyResponseState";
import { TrackingProvider } from "../state/contexts/trackingState";

//TODO: Determine routes to retain for production and the strategy to retain them because GlobalHead state doesn't change if routes are retained.

const ROUTES_TO_RETAIN = [
  // "/",
  // "/cart",
  // "/register",
  // "/login",
  // "/account/subscriptions",
  // "/account/orders",
  // "/account/addresses",
  // "/account/wallet",
  // "/account/personal",
  // "/refer",
  // "/loyalty",
];

const App = ({ Component, pageProps }) => {
  const router = useRouter();
  const retainedComponents = useRef({});

  const isRetainableRoute = ROUTES_TO_RETAIN.includes(router.asPath);

  // Add Component to retainedComponents if we haven't got it already
  if (isRetainableRoute && !retainedComponents.current[router.asPath]) {
    const MemoComponent = memo(Component);
    retainedComponents.current[router.asPath] = {
      component: <MemoComponent {...pageProps} />,
      scrollPos: 0,
    };
  }

  // Save the scroll position of current page before leaving
  const handleRouteChangeStart = (url) => {
    if (isRetainableRoute) {
      retainedComponents.current[router.asPath].scrollPos = window.scrollY;
    }
  };

  // Save scroll position - requires an up-to-date router.asPath
  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init("581228922711959"); // facebookPixelId
        ReactPixel.pageView();

        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();

          if (router.asPath.includes("cart")) {
            ReactPixel.track("AddToCart");
          }

          if (router.asPath.includes("checkout")) {
            ReactPixel.track("InitiateCheckout");
          }

          if (router.asPath.includes("status")) {
            ReactPixel.track("Purchase");
          }

          if (router.asPath.includes("account")) {
            ReactPixel.track("ViewContent");
          }

          if (router.asPath.includes("quiz")) {
            ReactPixel.track("ViewContent");
          }
        });
      });

    router.events.on("routeChangeStart", handleRouteChangeStart);
    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
    };
  }, [router.asPath]);

  // Scroll to the saved position when we load a retained component
  useEffect(() => {
    if (isRetainableRoute) {
      window.scrollTo(0, retainedComponents.current[router.asPath].scrollPos);
    }
  }, [Component, pageProps]);

  return (
    <GlobalProvider>
      <SnackbarProvider>
        <AuthProvider>
          <CartProvider>
            <QuizProvider>
              <SurveysProvider>
                <SurveyResponseProvider>
                  <TrackingProvider>
                    <AccountProvider>
                      <AppShell>
                        <section>
                          <div
                            style={{
                              display: isRetainableRoute ? "block" : "none",
                            }}
                          >
                            {Object.entries(retainedComponents.current).map(
                              ([path, c]) => (
                                <div
                                  key={path}
                                  style={{
                                    display:
                                      router.asPath === path ? "block" : "none",
                                  }}
                                >
                                  {c.component}
                                </div>
                              )
                            )}
                          </div>
                          {!isRetainableRoute && <Component {...pageProps} />}
                        </section>
                      </AppShell>
                    </AccountProvider>
                  </TrackingProvider>
                </SurveyResponseProvider>
              </SurveysProvider>
            </QuizProvider>
          </CartProvider>
        </AuthProvider>
      </SnackbarProvider>
    </GlobalProvider>
  );
};

export default App;
