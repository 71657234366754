import React, { useState, useEffect } from "react";
import { useApiCall } from "./useApiCall";

export const useRecoveryCode = () => {
  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();
  const [recoveryCodeError, setRecoveryCodeError] = useState();
  const [isRecoveryCodeSent, setIsRecoveryCodeSent] = useState(false);
  const [isRecoveryCodeVerified, setIsRecoveryCodeVerified] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [isRequestingRecoveryCode, setIsRequestingRecoveryCode] = useState(
    false
  );
  const [isVerifyingRecoveryCode, setIsVerifyingRecoveryCode] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [recoveryKey, setRecoveryKey] = useState();

  const requestRecoveryCode = (options) => {
    setRecoveryCodeError("");

    const requestOptions = {
      url: "/auth/recovery",
      data: options,
      method: "POST",
    };

    executeApiCall(requestOptions);

    setIsRequestingRecoveryCode(true);
  };

  const verifyRecoveryCode = (recoveryOptions) => {
    setRecoveryCodeError("");

    const requestOptions = {
      url: "/auth/recovery/verify",
      data: recoveryOptions,
      method: "POST",
    };

    executeApiCall(requestOptions);

    setIsVerifyingRecoveryCode(true);
  };

  const changePassword = (passwordInfo) => {
    setRecoveryCodeError("");

    const requestOptions = {
      url: "/auth/recovery/password",
      data: {
        ...passwordInfo,
        recoveryKey,
      },
      method: "POST",
    };

    executeApiCall(requestOptions);

    setIsChangingPassword(true);
  };

  useEffect(() => {
    switch (apiCallStatus) {
      case "success":
        if (apiCallResponse) {
          const { data: requestData } = apiCallResponse;

          const { error, success, data } = requestData;

          setIsRequestingRecoveryCode(false);
          setIsVerifyingRecoveryCode(false);
          setIsChangingPassword(false);

          if (error) {
            setRecoveryCodeError(error.userMessage);
          }

          if (success === true) {
            setRecoveryCodeError("");

            const { sent, verified, changed, key } = data;

            if (sent === true) {
              setIsRecoveryCodeSent(true);
            }

            if (verified === true) {
              setIsRecoveryCodeVerified(true);
            }

            if (changed === true) {
              setIsPasswordChanged(true);
            }

            if (key) {
              setRecoveryKey(key);
            }
          }
        }
        break;
    }
  }, [apiCallResponse, apiCallStatus]);

  return {
    requestRecoveryCode,
    verifyRecoveryCode,
    changePassword,
    recoveryCodeError,
    isRecoveryCodeSent,
    isRecoveryCodeVerified,
    isPasswordChanged,
    isRequestingRecoveryCode,
    isVerifyingRecoveryCode,
    isChangingPassword,
  };
};
