import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "../services/validations/resolvers/joiResolver";
import schema from "../services/validations/loginSchema";
import Notice from "./Notice";
import Link from "next/link";
import { useRemember } from "../services/hooks/useRemember";
import PasswordRecoveryModal from "./PasswordRecoveryModal";
import { usePhoneNumberInput } from "../services/hooks/usePhoneNumberInput";
import { useRecaptcha } from "../services/hooks/useRecaptcha";

export default React.memo(function LoginForm({
  onLogin,
  loginError,
  loginStatus,
}) {
  const { rememberLocalValue, retrieveLocalValue, removeLocalValue } =
    useRemember();

  const defaultEmail = retrieveLocalValue("emailAddress") || "";
  const defaultPhoneNumber = retrieveLocalValue("phoneNumber") || "";

  const [rememberMe, setRememberMe] = useState(
    defaultEmail || defaultPhoneNumber ? true : false
  );

  const { handleSubmit, register, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      email: defaultEmail,
    },
    resolver: joiResolver(schema),
  });

  const { phoneNumber, phoneNumberInput, isPhoneNumberValid } =
    usePhoneNumberInput({
      required: true,
      defaultValue: defaultPhoneNumber,
    });

  const [hasSelectedOption, setHasSelectedOption] = useState(false);
  const [shouldSignInThroughEmail, setShouldSignInThroughEmail] =
    useState(false);
  const [loginData, setLoginData] = useState();

  const { executeRecaptcha, isSafeRequest, recaptchaStatus } = useRecaptcha();

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const handleSignInThroughEmail = (e) => {
    e.preventDefault();

    setHasSelectedOption(true);
    setShouldSignInThroughEmail(true);
  };

  const handleSignInThroughPhone = (e) => {
    e.preventDefault();

    setHasSelectedOption(true);
    setShouldSignInThroughEmail(false);
  };

  const handleBack = (e) => {
    e.preventDefault();

    setHasSelectedOption(false);
  };

  const onSubmit = (data) => {
    if ((data.phoneNumber || data.email) && data.password) {
      executeRecaptcha("login_request");

      setLoginData(data);
    }
  };

  const login = (data) => {
    const { email } = data;

    if (rememberMe) {
      if (email) {
        rememberLocalValue({
          key: "emailAddress",
          value: data.email,
        });
      }

      if (phoneNumber) {
        rememberLocalValue({
          key: "phoneNumber",
          value: phoneNumber,
        });
      }
    } else {
      removeLocalValue("emailAddress");
      removeLocalValue("phoneNumber");
    }

    if (!shouldSignInThroughEmail && !isPhoneNumberValid) {
      return;
    }

    onLogin({
      ...data,
      email: shouldSignInThroughEmail ? email : null,
      phoneNumber: shouldSignInThroughEmail ? null : phoneNumber,
    });
  };

  useEffect(() => {
    if (recaptchaStatus === "ready" && isSafeRequest && loginData) {
      login(loginData);
    }
  }, [recaptchaStatus]);

  useEffect(() => {
    setIsSubmittingForm(
      loginStatus === "pending" ||
        recaptchaStatus === "pending" ||
        (recaptchaStatus === "ready" && loginStatus === "idle")
    );
  }, [recaptchaStatus, loginStatus]);

  if (!hasSelectedOption) {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <button
              className="btn btn-lg btn-outline-dark btn-block"
              type="button"
              role="button"
              onClick={handleSignInThroughEmail}
            >
              <i className="fa fa-envelope mr-2"></i> Sign in with email
            </button>

            <button
              className="btn btn-lg btn-outline-dark btn-block"
              type="button"
              role="button"
              onClick={handleSignInThroughPhone}
            >
              <i className="fa fa-phone mr-2"></i> Sign in with phone number
            </button>
          </div>
          <div className="col-12 mx-auto mt-2">
            <div className="form-group font-size-sm text-center">
              <span className="d-block">
                Don't have an account?{" "}
                <Link href="/register">
                  <a>Sign Up</a>
                </Link>
              </span>

              <span className="d-block text-muted">
                This site is protected by reCAPTCHA and the Google{" "}
                <a href="https://policies.google.com/privacy" target="_blank">
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a href="https://policies.google.com/terms" target="_blank">
                  Terms of Service
                </a>{" "}
                apply.
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <React.Fragment>
      <div className="mb-5">
        <a href="#" className="styled-underlined-link" onClick={handleBack}>
          <i className="fa fa-arrow-left"></i> back
        </a>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {loginError && (
          <Notice type="danger" message={loginError} dismissable={false} />
        )}
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="sr-only">
                {shouldSignInThroughEmail ? "Email" : "Phone number"}
              </label>

              {shouldSignInThroughEmail ? (
                <>
                  <input
                    ref={register}
                    className={`form-control form-control-sm ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    name="email"
                    type="email"
                    placeholder="Email address"
                    aria-label="Email address"
                  />
                  {errors.email && (
                    <span className="input-error">
                      Please enter a valid email address
                    </span>
                  )}
                </>
              ) : (
                phoneNumberInput
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="sr-only" htmlFor="password">
                Password
              </label>
              <input
                ref={register}
                className={`form-control form-control-sm ${
                  errors.password ? "is-invalid" : ""
                }`}
                id="password"
                name="password"
                type="password"
                placeholder="Password"
              />
              {errors.password && (
                <span className="input-error">Please enter a password</span>
              )}
            </div>
          </div>

          <div className="col-12 col-md">
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input"
                  id="loginRemember"
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <label className="custom-control-label" htmlFor="loginRemember">
                  Remember me
                </label>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-auto">
            <div className="form-group">
              <a
                className="font-size-sm text-reset"
                data-toggle="modal"
                href="#modalPasswordRecovery"
              >
                Forgot Password?
              </a>
            </div>
          </div>

          <div className="col-12">
            <button
              className="btn btn-sm btn-dark btn-block"
              type="submit"
              disabled={isSubmittingForm}
            >
              {isSubmittingForm ? "Please wait..." : "Log In"}
            </button>
          </div>

          <div className="col-12 mx-auto mt-2 mb-8 mb-nd-0">
            <div className="form-group font-size-sm text-center">
              <span className="d-block">
                Don't have an account?{" "}
                <Link href="/register">
                  <a>Sign Up</a>
                </Link>
              </span>

              <span className="d-block text-muted">
                This site is protected by reCAPTCHA and the Google{" "}
                <a href="https://policies.google.com/privacy" target="_blank">
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a href="https://policies.google.com/terms" target="_blank">
                  Terms of Service
                </a>{" "}
                apply.
              </span>
            </div>
          </div>
        </div>
      </form>

      <PasswordRecoveryModal />
    </React.Fragment>
  );
});
