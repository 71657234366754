import { useState, useContext } from "react";
import { GlobalContext } from "../../state/contexts/globalState";
import { AuthContext } from "../../state/contexts/authState";
import axios from "axios";
import errorCodes from "../errors/errorCodes";

export const useApiCall = () => {
  const [status, setStatus] = useState("idle");

  const [value, setValue] = useState(null);

  const [error, setError] = useState(null);

  const { apiBaseUrl, startApiRequest, completeApiRequest } =
    useContext(GlobalContext);

  const { accessToken } = useContext(AuthContext);

  const http = axios.create({
    baseURL: apiBaseUrl,
    headers: { Authorization: accessToken },
    validateStatus: (status) => {
      return status >= 200;
    },
    withCredentials: true,
  });

  http.interceptors.request.use(
    (config) => {
      startApiRequest();

      return config;
    },
    (error) => {
      completeApiRequest();

      return Promise.reject(error);
    }
  );

  http.interceptors.response.use(
    (response) => {
      completeApiRequest();

      return response;
    },
    (error) => {
      completeApiRequest();

      return Promise.reject(error);
    }
  );

  const execute = (requestOptions) => {
    setStatus("pending");

    setValue(null);

    setError(null);

    (async () => {
      try {
        const response = await http(requestOptions);

        setValue(response);

        setStatus("success");
      } catch (error) {
        const errorCode = errorCodes("UI-1001");

        setError(errorCode);

        setStatus("error");
      }
    })();
  };

  return {
    executeApiCall: execute,
    apiCallStatus: status,
    apiCallResponse: value,
    apiCallError: error,
  };
};
