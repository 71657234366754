import React, { useEffect, useState } from "react";

//COMPONETS
import Head from "next/head";

//HOOKS
import { useAuth } from "../services/hooks/useAuth";
import { usePathname } from "../services/hooks/usePathname";

//PLUGINS
import { hotjar } from "react-hotjar";
import ReactGA from "react-ga";

export default React.memo(function GlobalHead({ pageTitle, pageDescription }) {
  const [hasInitializedAnalytics, setHasInitializedAnalytics] = useState(false);

  const { user } = useAuth();
  const { pathname } = usePathname();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      //Hotjar session recording
      hotjar.initialize(2897944, 6);

      //Google Analytics
      ReactGA.initialize("151333920-1");

      setHasInitializedAnalytics(true);
    }
  }, []);

  useEffect(() => {
    if (hasInitializedAnalytics && pathname) {
      ReactGA.pageview(pathname);
    }
  }, [hasInitializedAnalytics, pathname]);

  useEffect(() => {
    if (hasInitializedAnalytics && user) {
      ReactGA.set({ userId: user.id });
    }
  }, [hasInitializedAnalytics, user]);

  return (
    <Head>
      <title>
        {pageTitle
          ? `${pageTitle} | Chic Beauty Box`
          : "A fully customizable beauty subscription box full of makeup | Chic Beauty Box"}
      </title>

      <meta name="p:domain_verify" content="3f43f9b1ec6b84b6ce0ac2f608760fdf" />

      <meta
        name="facebook-domain-verification"
        content="flyui0fwk1lthfz16iyftmhe0xe6wp"
      />

      <meta
        name="title"
        content={
          pageTitle
            ? `${pageTitle} | Chic Beauty Box`
            : "A fully customizable beauty subscription box full of makeup."
        }
      />
      <meta
        name="description"
        content={
          pageDescription ||
          "A fully customizable beauty subscription box full of makeup worth over $150 for only $34.99"
        }
      />
      <meta
        name="keywords"
        content="beauty box, subscription box, beauty subscription box, makeup box, subscription boxes, chic beauty, chic beauty box, customized beauty subscription, customized beauty box, personalized beauty box, personalized beauty subscription"
      />
      <meta name="robots" content="index, follow" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta name="revisit-after" content="1 days" />
      <meta name="author" content="Chic Beauty Box" />

      {/* Twitter Card data */}
      <meta property="twitter:card" content="product" />
      <meta property="twitter:site" content="@box_chicbeauty" />
      <meta
        property="twitter:description"
        content="A fully customizable beauty subscription box full of makeup worth over $150 for only $34.99"
      />
      <meta
        property="twitter:title"
        content="A fully customizable beauty subscription box full of makeup."
      />
      <meta
        property="twitter:image"
        content="https://chicbeautybox.com/img/branding/social-media/twitter/twitter-thumbnail.png"
      />
      <meta property="twitter:label1" content="Price" />
      <meta property="twitter:data1" content="$34.99" />

      {/* Open Graph data */}
      <meta
        property="og:title"
        content="A fully customizable beauty subscription box full of makeup"
      />
      <meta property="og:site_name" content="Chic Beauty Box" />
      <meta
        property="og:url"
        content={`https://chicbeautybox.com${pathname}`}
      />
      <meta
        property="og:description"
        content="A fully customizable beauty subscription box full of makeup worth over $100 for only $34.99"
      />
      <meta property="og:type" content="website" />

      <script src="/js/main.js" strategy="beforeInteractive" />
      <script
        src="https://www.google.com/recaptcha/api.js?render=6Lf0Pj4bAAAAAOspydNivBdva6INKylks9n4c19H"
        async
        defer
        strategy="afterInteractive"
      />
    </Head>
  );
});
