import { useEffect, useState } from "react";
import { useApiCall } from "./useApiCall";

export const useIsWaitlisting = () => {
  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();
  const [waitlistStatus, setWaitlistStatus] = useState("idle");
  const [isWaitlisting, setIsWaitlisting] = useState(false);

  useEffect(() => {
    const requestOptions = {
      url: `/waitlisting`,
    };

    executeApiCall(requestOptions);

    setWaitlistStatus("pending");
  }, []);

  useEffect(() => {
    switch (apiCallStatus) {
      case "success": {
        if (apiCallResponse.data.data) {
          const { waitlisting } = apiCallResponse.data.data;

          setIsWaitlisting(waitlisting);

          setWaitlistStatus("ready");
        }

        break;
      }
    }
  }, [apiCallStatus, apiCallResponse]);

  return {
    isWaitlisting,
    waitlistStatus,
  };
};
