import parseCouponMetadata from "./parseCouponMetadata";
import { flatten } from "lodash";

export default function (items, coupon) {
  coupon = parseCouponMetadata(coupon);

  let canApply = true;

  const observedStores = Array.from(
    new Set(flatten(items.map(({ product }) => product.stores)))
  );

  if (coupon?.metadata?.applies_to?.products?.length) {
    canApply = items.every(
      (item) =>
        coupon.metadata.applies_to.products.indexOf(item.product.id) !== -1
    );
  }

  if (!canApply) {
    return false;
  }

  if (coupon?.metadata?.applies_to?.stores?.length) {
    canApply = observedStores.every(
      (store) => coupon.metadata.applies_to.stores.indexOf(store) !== -1
    );
  }

  return canApply;
}
