export const CONTENT_RESTRICTION_TYPE = {
  LOGGED_IN: "loggedIn",
  UNSUBSCRIBED: "unsubscribed",
  SUBSCRIBED: "subscribed",
  CANCELED: "canceled",
};

export const CONTENT_RESTRICTION_MESSAGE = {
  LOGGED_IN: (content) =>
    `You must be signed in to access this ${content ?? "content"}.`,
  UNSUBSCRIBED: (content) =>
    `We're sorry but this ${content ?? "content"} is currently restricted.`,
  SUBSCRIBED: (content) =>
    `We're sorry but this ${
      content ?? "content"
    } is exclusive to Chic Beauty Box members only.`,
  CANCELED: (content) =>
    `We're sorry but this ${content ?? "content"} is currently restricted.`,
};
