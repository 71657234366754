import { useContext, useEffect } from "react";
import { AuthContext } from "../../state/contexts/authState";
import { useRouter } from "next/router";
import { useSignUp } from "./useSignUp";
import { useLogin } from "./useLogin";
import { useLogout } from "./useLogout";
import { usePathname } from "./usePathname";

export const useAuth = (
  shouldRedirect = false,
  redirectTo = "/login",
  next
) => {
  const { user, isSubscribed, accessToken, refreshAuth, authStatus } =
    useContext(AuthContext);
  const { signUp, signUpError, signUpStatus } = useSignUp();
  const { login, loginError, loginStatus } = useLogin();
  const { logout } = useLogout();

  const router = useRouter();

  const { pathname } = usePathname();

  useEffect(() => {
    router.prefetch(redirectTo);
  }, [redirectTo]);

  useEffect(() => {
    const redirectURI = next || pathname;

    let url = redirectTo;

    if (redirectURI) {
      url += `?next=${redirectURI}`;
    }

    if (authStatus === "ready" && !user && shouldRedirect) {
      router.push(url);
    }
  }, [shouldRedirect, redirectTo, user, authStatus]);

  return {
    accessToken,
    user,
    isSubscribed,
    authStatus,
    refreshAuth,
    signUp,
    signUpError,
    signUpStatus,
    login,
    loginError,
    loginStatus,
    logout,
  };
};
