import { useEffect, useState } from "react";
import { useApiCall } from "./useApiCall";
import { useCookie } from "./useCookie";
import { debounce } from "lodash";

export const useSearch = (searchInputRef, context) => {
  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();
  const [searchQueries, setSearchQueries] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [hasResults, setHasResults] = useState(false);
  const [searchResults, setSearchResults] = useState();
  const [isSearching, setIsSearching] = useState(false);

  const { getCookie } = useCookie();

  const resetSearch = () => {
    setHasSearched(false);
    setHasResults(false);
    setSearchResults();
    setIsSearching(false);

    if (searchInputRef?.current) {
      searchInputRef.current.value = "";
    }
  };

  const search = () => {
    if (searchInputRef && searchInputRef.current) {
      const query = searchInputRef.current.value;

      if (query) {
        let url = `/search?q=${query}`;

        if (context) {
          url += `&ctx=${context}`;
        }

        const requestOptions = {
          url,
        };

        executeApiCall(requestOptions);

        setIsSearching(true);
      } else {
        resetSearch();
      }
    }
  };

  useEffect(() => {
    if (searchInputRef.current) {
      const searchHandler = debounce(search, 300);

      searchInputRef.current.addEventListener("input", searchHandler, false);
      searchInputRef.current.addEventListener("change", searchHandler, false);

      return () => {
        if (searchInputRef.current) {
          searchInputRef.current.removeEventListener(
            "input",
            searchHandler,
            false
          );

          searchInputRef.current.removeEventListener(
            "change",
            searchHandler,
            false
          );
        }
      };
    }
  }, [searchInputRef.current]);

  useEffect(() => {
    if (typeof searchResults === "object") {
      if (Object.keys(searchResults).length) {
        for (const key in searchResults) {
          if (searchResults.hasOwnProperty(key) && searchResults[key].length) {
            setHasResults(true);

            break;
          } else {
            setHasResults(false);
          }
        }
      } else {
        setHasResults(false);
      }
    }

    setHasSearched(!!searchResults);

    setSearchQueries(getCookie("cbb_search_queries_B") || []);
  }, [searchResults]);

  useEffect(() => {
    switch (apiCallStatus) {
      case "success":
        if (apiCallResponse.data.error) {
          setIsSearching(false);
        } else {
          if (apiCallResponse.data.data) {
            const { results } = apiCallResponse.data.data;

            setIsSearching(false);
            setSearchResults(results);
          }
        }
        break;
      case "error":
        setIsSearching(false);
    }
  }, [apiCallStatus, apiCallResponse]);

  return {
    search,
    resetSearch,
    searchQueries,
    searchResults,
    isSearching,
    hasResults,
    hasSearched,
  };
};
