import React from "react";
import NavBar from "./NavBar";
import DataSettings from "./DataSettings";
import SidebarCartModal from "./SidebarCartModal";
import LoginModal from "./LoginModal";
import ConfirmPasswordModal from "./ConfirmPasswordModal";
import NewsletterModal from "./NewsletterModal";
import SearchModal from "./SearchModal";
import ReviewReportModal from "./ReviewReportModal";
import GlobalHead from "./GlobalHead";
import OverlayLoader from "./OverlayLoader";
import { useGlobal } from "../services/hooks/useGlobal";

export default React.memo(function AppCrest() {
  const { pageDescription, pageTitle } = useGlobal();

  return (
    <>
      {/*Global Head*/}
      <GlobalHead pageDescription={pageDescription} pageTitle={pageTitle} />
      {/*End Global Head*/}

      {/*Overlay Loader*/}
      <OverlayLoader />
      {/*End Overlay Loader*/}

      {/* Nav */}
      <NavBar />
      {/* End Nav */}

      {/* Global Modals */}
      <DataSettings />
      <SidebarCartModal />
      <SearchModal />
      <LoginModal />
      <ConfirmPasswordModal />
      <NewsletterModal />
      <ReviewReportModal />
      {/*End Global Modals */}
    </>
  );
});
