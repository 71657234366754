import React, { useEffect, useState } from "react";
import { useRecoveryCode } from "../services/hooks/useRecoveryCode";
import { useAuth } from "../services/hooks/useAuth";
import { useRedirect } from "../services/hooks/useRedirect";
import VerifyRecoveryCodeForm from "./VerifyRecoveryCodeForm";
import RequestRecoveryCodeForm from "./RequestRecoveryCodeForm";
import ChangePasswordForm from "./ChangePasswordForm";
import closeModals from "../util/closeModals";
import Notice from "./Notice";

export default React.memo(function ForgotPasswordModal() {
  const [recoveryOptions, setRecoveryOptions] = useState("");
  const [recoveryPassword, setRecoveryPassword] = useState("");

  const { login, loginStatus } = useAuth();

  const { redirect } = useRedirect();

  const {
    requestRecoveryCode,
    verifyRecoveryCode,
    changePassword,
    recoveryCodeError,
    isRecoveryCodeSent,
    isRecoveryCodeVerified,
    isPasswordChanged,
    isRequestingRecoveryCode,
    isVerifyingRecoveryCode,
    isChangingPassword,
  } = useRecoveryCode();

  const onRequestRecoveryCode = (options) => {
    setRecoveryOptions(options);
    requestRecoveryCode(options);
  };

  const onVerifyRecoveryCode = (recoveryCode) => {
    verifyRecoveryCode({
      ...recoveryOptions,
      recoveryCode,
    });
  };

  const onChangePassword = (passwordInfo) => {
    const { password } = passwordInfo;

    setRecoveryPassword(password);

    changePassword({
      ...recoveryOptions,
      ...passwordInfo,
    });
  };

  useEffect(() => {
    if (isPasswordChanged) {
      login({ ...recoveryOptions, password: recoveryPassword });
    }
  }, [isPasswordChanged]);

  useEffect(() => {
    if (loginStatus === "pending") {
      closeModals();
    }

    if (loginStatus === "success") {
      redirect("/account");
    }
  }, [loginStatus]);

  return (
    <div
      className="modal fade"
      id="modalPasswordRecovery"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="fe fe-x" aria-hidden="true"></i>
          </button>

          <div className="modal-header line-height-fixed font-size-lg">
            <strong className="mx-auto">
              {isRecoveryCodeSent && !isRecoveryCodeVerified
                ? "Enter Recovery Code"
                : isRecoveryCodeVerified
                ? "Change Password"
                : "Forgot Password?"}
            </strong>
          </div>

          <div className="modal-body text-center">
            {recoveryCodeError && (
              <Notice message={recoveryCodeError} type="danger" />
            )}

            {!isRecoveryCodeSent && !isRecoveryCodeVerified && (
              <p className="mb-7 font-size-sm text-gray-500">
                Please enter your email or phone number, and we'll send you a
                code to help recover your account.
              </p>
            )}

            {isRecoveryCodeSent && !isRecoveryCodeVerified && (
              <React.Fragment>
                <p className="mb-7 font-size-sm text-gray-500">
                  To continue, please enter the 6-digit recovery code sent to
                  your email or phone number.
                </p>

                <p className="mb-7 font-size-sm text-gray-500">
                  Didn't receive a code?{" "}
                  <a
                    className="styled-underlined-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      requestRecoveryCode(recoveryOptions);
                    }}
                  >
                    Resend.
                  </a>
                </p>
              </React.Fragment>
            )}

            {isRecoveryCodeVerified && (
              <p className="mb-7 font-size-sm text-gray-500">
                Create a new password for your account. If you have a problem
                resetting your password, please{" "}
                <a href="https://help.chicbeautybox.com" target="_blank">
                  contact support
                </a>
              </p>
            )}

            {!isRecoveryCodeSent && !isRecoveryCodeVerified && (
              <RequestRecoveryCodeForm
                onRequestCode={onRequestRecoveryCode}
                isProcessing={isRequestingRecoveryCode}
              />
            )}

            {isRecoveryCodeSent && !isRecoveryCodeVerified && (
              <VerifyRecoveryCodeForm
                onVerifyCode={onVerifyRecoveryCode}
                isProcessing={isVerifyingRecoveryCode}
                recoveryCodeError={recoveryCodeError}
              />
            )}

            {isRecoveryCodeVerified && (
              <ChangePasswordForm
                onChangePassword={onChangePassword}
                isProcessing={isChangingPassword}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
