import React, { useState, useEffect } from "react";
import CookieNotice from "./CookieNotice";
import { useCookie } from "../services/hooks/useCookie";
import closeModals from "../util/closeModals";
import noop from "../util/noop";

const COOKIE_NAME = "cbb_gdpr";
const COOKIE_EXPIRY_IN_DAYS = 365; //1 year

const DEFAULT_SETTINGS = {
  necessary: true,
  settings: false,
  social: false,
  statistics: false,
  targeting: false,
};

const ACCEPT_ALL_SETTINGS = {
  necessary: true,
  settings: true,
  social: true,
  statistics: true,
  targeting: true,
};

export default React.memo(function DataSettings() {
  const { getCookie, setCookie } = useCookie();
  const [showCookieDetails, setShowCookieDetails] = useState(false);
  const [hideOptions, setHideOptions] = useState(false);
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const [gdprCookie, setGdprCookie] = useState({});

  const [dataSettings, setDataSettings] = useState(DEFAULT_SETTINGS);

  const cleanUp = () => {
    closeModals("#dataSettingsModal");

    setShowCookieConsent(false);
  };

  const handleDisableAll = (e) => {
    e.preventDefault();

    setDataSettings(DEFAULT_SETTINGS);

    //Set GDPR Cookie for 1 year
    setCookie(
      COOKIE_NAME,
      JSON.stringify(DEFAULT_SETTINGS),
      COOKIE_EXPIRY_IN_DAYS
    );

    cleanUp();
  };

  const handleAcceptAll = (e) => {
    e.preventDefault();

    setDataSettings(ACCEPT_ALL_SETTINGS);

    //Set GDPR Cookie for 1 year
    setCookie(
      COOKIE_NAME,
      JSON.stringify(ACCEPT_ALL_SETTINGS),
      COOKIE_EXPIRY_IN_DAYS
    );

    cleanUp();
  };

  const handleSave = (e) => {
    e.preventDefault();

    //Set GDPR Cookie for 1 year
    setCookie(COOKIE_NAME, JSON.stringify(dataSettings), COOKIE_EXPIRY_IN_DAYS);

    cleanUp();
  };

  useEffect(() => {
    setGdprCookie(getCookie(COOKIE_NAME));
  }, []);

  useEffect(() => {
    if (!gdprCookie) {
      setShowCookieConsent(true);
    }

    if (gdprCookie && Object.keys(gdprCookie).length) {
      setDataSettings(gdprCookie);
    }
  }, [gdprCookie]);

  return (
    <>
      {showCookieConsent && (
        <CookieNotice
          onAllowAll={handleAcceptAll}
          onDisableAll={handleDisableAll}
        />
      )}

      <div
        className="modal fade font-size-s"
        id="dataSettingsModal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-vertical" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="fe fe-x" aria-hidden="true"></i>
            </button>

            <div className="modal-header line-height-fixed font-size-lg bg-primary">
              <h3>Privacy and Data Settings</h3>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-12 mb-5 text-center">
                  <h6 className="mx-auto">
                    Which features would you like to enable?
                  </h6>
                </div>
                <div className="col-12">
                  <p>
                    We respect your privacy and data protection guidelines. Some
                    components of our site require cookies or local storage that
                    handles personal information.
                    <br />
                    <br />
                    {!showCookieDetails && (
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setHideOptions((prevHideOptions) => !prevHideOptions);
                        }}
                      >
                        {hideOptions ? "Show options" : "Hide options"}
                      </a>
                    )}
                  </p>
                </div>
              </div>

              {!hideOptions && !showCookieDetails && (
                <div className="row">
                  <div className="col-12">
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="necessary"
                        checked={true}
                        disabled={true}
                        onChange={noop}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="necessary"
                        style={{ textDecoration: "none" }}
                      >
                        <span>
                          <b>Necessary</b>
                          <br />
                          These cookies are necessary for the website to
                          function and cannot be switched off in our systems.
                        </span>
                      </label>
                    </div>
                    <hr />
                  </div>
                  <div className="col-12">
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="settings"
                        checked={!!dataSettings.settings}
                        onChange={(e) =>
                          setDataSettings((prevDataSettings) => ({
                            ...prevDataSettings,
                            settings: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="settings"
                      >
                        <span>
                          <b>Settings</b>
                          <br />
                          These cookies enable the website to provide enhanced
                          functionality and personalisation. They may be set by
                          us or by third party providers whose services we have
                          added to our pages.
                        </span>
                      </label>
                    </div>
                    <hr />
                  </div>
                  <div className="col-12">
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="social"
                        checked={!!dataSettings.social}
                        onChange={(e) =>
                          setDataSettings((prevDataSettings) => ({
                            ...prevDataSettings,
                            social: e.target.checked,
                          }))
                        }
                      />
                      <label className="custom-control-label" htmlFor="social">
                        <span>
                          <b>Social Media</b>
                          <br />
                          These cookies help us use your interactions with us on
                          social media to personalize the site.
                        </span>
                      </label>
                    </div>
                    <hr />
                  </div>
                  <div className="col-12">
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="statistics"
                        checked={!!dataSettings.statistics}
                        onChange={(e) =>
                          setDataSettings((prevDataSettings) => ({
                            ...prevDataSettings,
                            statistics: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="statistics"
                      >
                        <span>
                          <b>Statistics</b>
                          <br />
                          Usage statistics that help us improve parts of our
                          site relevant to you.
                        </span>
                      </label>
                    </div>
                    <hr />
                  </div>
                  <div className="col-12">
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="targeting"
                        checked={!!dataSettings.targeting}
                        onChange={(e) =>
                          setDataSettings((prevDataSettings) => ({
                            ...prevDataSettings,
                            targeting: e.target.checked,
                          }))
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="targeting"
                      >
                        <span>
                          <b>Targeting</b>
                          <br />
                          These cookies may be set through our site by our
                          advertising partners. They may be used by those
                          companies to build a profile of your interests and
                          show you relevant adverts on other sites.
                        </span>
                      </label>
                    </div>
                    <hr />
                  </div>
                </div>
              )}
              <div className="row">
                {!hideOptions && (
                  <div className="col-12">
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowCookieDetails(
                          (prevShowCookieDetails) => !prevShowCookieDetails
                        );
                      }}
                    >
                      {showCookieDetails
                        ? "Back..."
                        : "Learn more about the details..."}
                    </a>
                  </div>
                )}

                {showCookieDetails && (
                  <>
                    <div className="col-12 mt-3">
                      <p>
                        Using Chicbeautybox.com requires the storage of some
                        personal data, as set out below. You can find additional
                        information in our{" "}
                        <a href="/privacy" target="_blank">
                          Privacy &amp; Cookie Policy
                        </a>
                        , and{" "}
                        <a href="/terms" target="_blank">
                          Terms and Conditions.
                        </a>{" "}
                      </p>

                      <p>
                        In some cases, data obtained from cookies is shared with
                        third parties and may be considered a “sale of personal
                        information” under the California Consumer Privacy Act
                        (CCPA). You can exercise your right to opt-out of that
                        sharing at any time by disabling cookies.
                      </p>

                      <p>
                        Please note that your data settings can be changed at
                        any time by clicking on the Data Settings link in the
                        footer at the bottom of our website.
                      </p>
                    </div>
                    <div className="col-12">
                      <h6>Necessary Data</h6>
                      <p>
                        These cookies are necessary for the website to function
                        and cannot be switched off in our systems. They are
                        usually only set in response to actions made by you
                        which amount to a request for services, such as setting
                        your privacy preferences, logging in or filling in
                        forms. You can set your browser to block or alert you
                        about these cookies, but some parts of the site will not
                        then work. These cookies do not store any personally
                        identifiable information.
                      </p>
                    </div>
                    <div className="col-12">
                      <h6>Settings Data</h6>
                      <p>
                        We offer a range of personal settings for your
                        convenience. Options include remembering your email
                        address during login, your beauty preferences, and more.
                        You can use our web page without storing this data, but
                        if you would like to have your individual settings
                        remembered we recommend enabling this feature. For
                        logged-in registered users this setting is mandatory to
                        store information about your privacy settings. As a
                        registered user we also store your data consent in these
                        settings.
                      </p>
                    </div>
                    <div className="col-12">
                      <h6>Social Media Data</h6>
                      <p>
                        We make it possible to share content in social networks
                        such as Facebook and Twitter. If you enable this option
                        social networks are able to store data in your cookies
                        or local storage for the purpose of these features. We
                        may also use the data from your interactions with us on
                        social media to personalize this site.
                      </p>
                    </div>
                    <div className="col-12">
                      <h6>Statistics Data</h6>
                      <p>
                        These cookies allow us to count visits and traffic
                        sources, so we can measure and improve the performance
                        of our site. They help us know which pages are the most
                        and least popular and see how visitors move around the
                        site. All information these cookies collect is
                        aggregated and therefore anonymous. If you do not allow
                        these cookies, we will not know when you have visited
                        our site and how to improve your experience.
                      </p>
                    </div>
                    <div className="col-12">
                      <h6>Targeting Data</h6>
                      <p>
                        These cookies may be set through our site by our
                        advertising partners. They may be used by those
                        companies to build a profile of your interests and show
                        you relevant adverts on other sites. They do not store
                        directly personal information, but are based on uniquely
                        identifying your browser and internet device. If you do
                        not allow these cookies, you will experience less
                        targeted advertising.
                      </p>
                    </div>
                    <div className="col-12">
                      <h6>Other Data</h6>
                      <p>
                        For registered users we store additional information
                        such as profile data, beauty preferences, and items and
                        subscriptions you have purchased. You can find this
                        information in your account. If you decide to contact
                        the support team a ticket is created with information
                        that includes your name and email address so that we can
                        respond to your concern. This data is processed in the
                        external service Zoho Desk. If you subscribe to a
                        newsletter or are registered we would like to send you
                        occasional updates via email. You can unsubscribe from
                        newsletters and as a registered user you can apply
                        several mail settings to control how your email address
                        is used. For newsletters we transfer your email address
                        and username to the external service MailChimp. If you
                        buy products or subscriptions on Chicbeautybox.com we
                        work with the payment service provider Stripe Inc.,
                        which collects your payment data and processes
                        information about the payment.
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              className="modal-footer d-flex justify-content-between"
              style={{ marginTop: "-3rem" }}
            >
              <button
                type="button"
                className="btn btn-outline-dark"
                onClick={handleAcceptAll}
              >
                <i className="fa fa-check"></i> Accept all
              </button>
              <button
                type="button"
                className="btn btn-dark"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
