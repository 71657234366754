import { cloneDeep } from "lodash";

export default function setActiveContentResponse({
  survey,
  activeContentId,
  response,
}) {
  const contents = survey.contents.map((content) => {
    if (content.id === activeContentId) {
      content.response = response;
    }

    return content;
  });

  survey.contents = contents;

  return cloneDeep(survey);
}
