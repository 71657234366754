import React, { useEffect, useState } from "react";
import closeModals from "../util/closeModals";
import { useReviewReport } from "../services/hooks/useReviewReport";
import { useGlobal } from "../services/hooks/useGlobal";
import Notice from "./Notice";
import openModal from "../util/openModal";

export default React.memo(function ReviewReportModal() {
  const { reviewIdToReport, setReviewIdToReport } = useGlobal();
  const [reason, setReason] = useState("");
  const [step, setStep] = useState(1);
  const [hasSigned, setHasSigned] = useState(false);

  const {
    reportReview,
    resetReportReview,
    reviewReport,
    reviewReportError,
    reviewReportStatus,
  } = useReviewReport();

  const REASON_ENUM = {
    1: "Contains Harmful or Illegal remark",
    2: "Contains personal information",
    3: "Contains advertisement or promotional material",
    4: "Not based on a genuine experience",
  };

  const handleReasonChange = (e) => {
    setReason(REASON_ENUM[e.target.value]);
  };

  const handleNextClick = (e) => {
    e.preventDefault();

    if (step === 1) {
      if (reason) {
        setStep((currentStep) => currentStep + 1);
      }
    }

    if (step === 2) {
      setStep((currentStep) => currentStep + 1);
    }

    if (step === 3 && reason && hasSigned) {
      reportReview({
        reviewId: reviewIdToReport,
        reason,
      });
    }
  };

  const handleBackClick = (e) => {
    e.preventDefault();

    setStep((currentStep) => currentStep - 1);
  };

  const handleSignatureConfirmation = (e) => {
    setHasSigned(e.target.checked);
  };

  const handleFinish = (e) => {
    e.preventDefault();

    reset();
    resetReportReview();
    closeModals();
  };

  const reset = () => {
    setReason("");
    setStep(1);
    setHasSigned(false);
    setReviewIdToReport({
      reviewIdToReport: null,
    });
  };

  useEffect(() => {
    openModal("#modalReportReview", {
      onHide: reset,
    });
  }, [reviewIdToReport]);

  useEffect(() => {
    if (reviewReportStatus === "ready" && reviewReport) {
      setStep((currentStep) => currentStep + 1);
    }
  }, [reviewReport, reviewReportStatus]);

  if (!reviewIdToReport) {
    return null;
  }

  return (
    <div
      className="modal fade"
      id="modalReportReview"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="fe fe-x" aria-hidden="true"></i>
          </button>

          <div className="modal-header line-height-fixed font-size-lg">
            {step === 4 ? (
              <strong>Thanks for flagging this review.</strong>
            ) : (
              <strong>Report or flag this rewiew</strong>
            )}
          </div>

          <div className="modal-body">
            {reviewReportError && (
              <div className="row">
                <div className="col-12">
                  <Notice message={reviewReportError} type="danger" />
                </div>
              </div>
            )}

            {step === 1 && (
              <>
                <div className="row">
                  <div className="col-12">
                    <p className="h6 text-gray-500 mb-3">
                      Please select a reason
                    </p>
                  </div>
                </div>

                {/* Options */}
                <div className="custom-control custom-radio mb-3">
                  <input
                    id="report-reason-1"
                    type="radio"
                    value={1}
                    name="report-reason"
                    className="custom-control-input"
                    onChange={handleReasonChange}
                    checked={reason === REASON_ENUM[1]}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="report-reason-1"
                  >
                    Harmful or illegal
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    id="report-reason-2"
                    type="radio"
                    value={2}
                    name="report-reason"
                    className="custom-control-input"
                    onChange={handleReasonChange}
                    checked={reason === REASON_ENUM[2]}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="report-reason-2"
                  >
                    Personal information
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    id="report-reason-3"
                    type="radio"
                    value={3}
                    name="report-reason"
                    className="custom-control-input"
                    onChange={handleReasonChange}
                    checked={reason === REASON_ENUM[3]}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="report-reason-3"
                  >
                    Advertising or promotional
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    id="report-reason-4"
                    type="radio"
                    value={4}
                    name="report-reason"
                    className="custom-control-input"
                    onChange={handleReasonChange}
                    checked={reason === REASON_ENUM[4]}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="report-reason-4"
                  >
                    Not based on a genuine experience
                  </label>
                </div>
              </>
            )}

            {step === 2 && reason === REASON_ENUM[1] && (
              <div className="row">
                <div className="col-12">
                  <h6>We can remove content that, for example:</h6>
                  <ol>
                    <li>
                      Attacks or uses derogatory or discriminatory language with
                      reference to a person or a group on the basis of who they
                      are, i.e., their religion, ethnicity, nationality, race,
                      color, descent, gender, or other identity factors
                    </li>
                    <li>Poses serious threats or threats of violence</li>
                    <li>
                      Urges offline action such as physical harassment or
                      destruction of property
                    </li>
                    <li>Spreads harmful propaganda</li>
                  </ol>

                  <h6>We don’t remove content just because:</h6>
                  <ol>
                    <li>You dislike or disagree with it</li>
                    <li>It criticizes someone</li>
                    <li>It includes swear words</li>
                    <li>It might be rude or offensive to some people</li>
                  </ol>
                </div>
              </div>
            )}

            {step === 2 && reason === REASON_ENUM[2] && (
              <div className="row">
                <div className="col-12">
                  <h6>We can remove content that contains, for example:</h6>
                  <ol>
                    <li>Your name, phone number, address or email address</li>
                    <li>Information that can be used to identify you</li>
                    <li>Text that breaches privacy laws</li>
                    <li>Relates to violent extremism</li>
                  </ol>

                  <h6>We don’t remove content if it contains:</h6>
                  <ol>
                    <li>Information that’s already in the public domain</li>
                  </ol>
                </div>
              </div>
            )}

            {step === 2 && reason === REASON_ENUM[3] && (
              <div className="row">
                <div className="col-12">
                  <h6>We can remove content that, for example:</h6>
                  <ol>
                    <li>Includes a promotional code</li>
                    <li>Advertises calls to action</li>
                    <li>
                      Advertises for a business other than Chic Beauty Box
                    </li>
                  </ol>

                  <h6>We don’t remove content just because:</h6>
                  <ol>
                    <li>
                      It mentions another business or product for a relevant,
                      non-promotional reason
                    </li>
                    <li>You disagree with the star rating or content</li>
                    <li>It’s short or not very detailed</li>
                  </ol>
                </div>
              </div>
            )}

            {step === 2 && reason === REASON_ENUM[4] && (
              <div className="row">
                <div className="col-12">
                  <h6>We can remove a review if, for example:</h6>
                  <ol>
                    <li>
                      It’s clear that the reviewer hasn’t had any interaction
                      with Chic Beauty Box
                    </li>
                    <li>The reviewer was paid to write this review</li>
                    <li>The review was written by a competitor</li>
                    <li>It’s a copy/paste of another review</li>
                  </ol>

                  <h6>We don’t remove a review just because:</h6>
                  <ol>
                    <li>You had a different experience with Chic Beauty Box</li>
                    <li>
                      The review isn’t as detailed, informative or well written
                      as you’d like
                    </li>
                  </ol>
                </div>
              </div>
            )}

            {step === 3 && (
              <div className="row mb-3">
                <div className="col-12">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="signature"
                      name="signature"
                      onChange={handleSignatureConfirmation}
                      checked={hasSigned}
                    />
                    <label className="custom-control-label" htmlFor="signature">
                      I confirm that the information I've provided here is true
                      and correct to the best of my knowledge:
                    </label>
                  </div>
                </div>
              </div>
            )}

            {step === 4 && (
              <div className="row mb-3">
                <div className="col-12">
                  <p className="font-size-lg">
                    Feedback like yours helps us keep our review system
                    trustworthy.
                  </p>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-12">
                <div className="button-group float-right">
                  {reviewReportStatus === "ready" && reviewReport ? (
                    <>
                      <button
                        className="btn btn-dark"
                        type="button"
                        role="button"
                        onClick={handleFinish}
                      >
                        Finish
                      </button>
                    </>
                  ) : (
                    <>
                      {step > 1 && (
                        <button
                          className="btn btn-outline-dark mr-3"
                          type="button"
                          role="button"
                          onClick={handleBackClick}
                          disabled={reviewReportStatus === "pending"}
                        >
                          Back
                        </button>
                      )}
                      <button
                        className="btn btn-dark"
                        type="button"
                        role="button"
                        onClick={handleNextClick}
                        disabled={reviewReportStatus === "pending"}
                      >
                        Next{" "}
                        {reviewReportStatus === "pending" ? (
                          <i className="fas fa-circle-notch fa-spin"></i>
                        ) : (
                          ""
                        )}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
