import React, { createContext, useReducer, useEffect, useState } from "react";
import surveysReducer from "../reducers/surveysReducer";
import prepareSurveyContents from "../../services/lib/surveys/prepareSurveyContents";
import SurveySideOut from "../../components/SurveySideOut";
import time from "../../services/time/time";
import { useRouter } from "next/router";
import { useRemember } from "../../services/hooks/useRemember";
import { useSnackbar } from "../../services/hooks/useSnackbar";
import { useAuth } from "../../services/hooks/useAuth";
import { useSurveysList } from "../../services/hooks/useSurveysList";
import { useSaveSurveyResponse } from "../../services/hooks/useSaveSurveyResponse";
import { useSurvey } from "../../services/hooks/useSurvey";
import { useTimezone } from "../../services/hooks/useTimezone";
import { CONTENT_RESTRICTION_MESSAGE } from "../../services/lib/restrictions/constants";

//Actions
export const ACTIONS = {
  SET_STATE: "SetState",
  SET_SURVEYS: "SetSurveys",
  SET_CANDIDATES: "SetCandidates",
  SET_ACTIVE_SURVEY_ID: "SetActiveSurveyId",
  SET_ACTIVE_SURVEY: "SetActiveSurvey",
  SET_CURRENT_STEP: "SetCurrentStep",
  SET_ACTIVE_CONTENT_ID: "SetActiveContentId",
  SET_ACTIVE_CONTENT: "SetActiveContent",
  SET_ACTIVE_CONTENT_RESPONSE: "SetActiveContentResponse",
  SET_ACTIVE_CONTENT_OPTION_IS_SELECTED: "SetActiveContentOptionIsSelected",
  SET_SHOW_SURVEY_SIDE_OUT: "SetShowSurveySideOut",
  SET_SURVEY_CONTENT_ERROR: "SetSurveyContentError",
  SET_SHOULD_SHOW_SURVEY_COUNTDOWN_TIMER: "SetShouldShowSurveyCountdownTimer",
  SET_SURVEY_START_TIME: "SetSurveyStartTime",
  SET_SURVEY_END_TIME: "SetSurveyEndTme",
};

// Initial state
const initialState = {
  activeSurveyId: null,
  activeSurvey: null,
  activeContentId: null,
  activeContent: null,
  surveys: [],
  candidates: [],
  responses: [],
  currentStep: null,
  showSurveySideOut: false,
  shouldShowSurveyCountdownTimer: false,
  surveyStartTime: null,
  surveyEndTime: null,
  surveyContentError: null,
};

// Create context
export const SurveysContext = createContext(initialState);

// Provider component
export const SurveysProvider = ({ children }) => {
  const [state, dispatch] = useReducer(surveysReducer, initialState);
  const { user, isSubscribed, authStatus } = useAuth();
  const { survey, surveyError, surveyStatus, fetchSurvey } = useSurvey();
  const { surveysList, fetchSurveysList } = useSurveysList();

  const [surveyDisplayMessage, setSurveyDisplayMessage] = useState(null);

  const {
    saveSurveyResponse,
    savedSurveyResponse,
    saveSurveyResponseStatus,
    saveSurveyResponseError,
    resetSaveSurveyResponse,
  } = useSaveSurveyResponse();

  const { rememberLocalValue, retrieveLocalValue, removeLocalValue } =
    useRemember();

  const router = useRouter();

  const { addSnackbar } = useSnackbar();

  const { timezone } = useTimezone();

  const setState = (payload) => {
    dispatch({
      type: ACTIONS.SET_STATE,
      payload,
    });
  };

  const setSurveys = (payload) => {
    dispatch({
      type: ACTIONS.SET_SURVEYS,
      payload,
    });
  };

  const setCandidates = (payload) => {
    dispatch({
      type: ACTIONS.SET_CANDIDATES,
      payload,
    });
  };

  const setShowSurveySideOut = (payload) => {
    dispatch({
      type: ACTIONS.SET_SHOW_SURVEY_SIDE_OUT,
      payload,
    });
  };

  const saveActiveSurveyResponse = () => {
    saveSurveyResponse({
      surveyId: state.activeSurvey.id,
      contents: state.activeSurvey.contents,
    });
  };

  const setSurveyContentError = (payload) => {
    dispatch({
      type: ACTIONS.SET_SURVEY_CONTENT_ERROR,
      payload,
    });
  };

  const setActiveSurveyId = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_SURVEY_ID,
      payload,
    });
  };

  const setActiveSurvey = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_SURVEY,
      payload,
    });
  };

  const setCurrentStep = (payload) => {
    dispatch({
      type: ACTIONS.SET_CURRENT_STEP,
      payload,
    });
  };

  const setActiveContentId = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_ID,
      payload,
    });
  };

  const setActiveContent = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT,
      payload,
    });
  };

  const setActiveContentResponse = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_RESPONSE,
      payload,
    });
  };

  const setActiveContentOptionIsSelected = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_OPTION_IS_SELECTED,
      payload,
    });
  };

  const setShouldShowSurveyCountdownTimer = (payload) => {
    dispatch({
      type: ACTIONS.SET_SHOULD_SHOW_SURVEY_COUNTDOWN_TIMER,
      payload,
    });
  };

  const setSurveyStartTime = (payload) => {
    dispatch({
      type: ACTIONS.SET_SURVEY_START_TIME,
      payload,
    });
  };

  const setSurveyEndTime = (payload) => {
    dispatch({
      type: ACTIONS.SET_SURVEY_END_TIME,
      payload,
    });
  };

  const isSurveyCandidate = (survey) => {
    const {
      surveyType,
      restrictions,
      triggers,
      maxResponses,
      schedule,
      responses,
    } = survey;

    if (surveyType === "survey") {
      const { startTime, endTime } = schedule;

      const currentTime = time().unix();

      //if survey period has'nt started
      if (startTime && currentTime < startTime) {
        return false;
      }
      //if survey period has ended
      if (endTime && currentTime > endTime) {
        return false;
      }

      //if the maximum number of responses have been collected
      if (maxResponses && responses.length >= maxResponses) {
        return false;
      }

      //if the current path does not match up with the "path" trigger
      if (triggers.path && !router.pathname.includes(triggers.path)) {
        return false;
      }

      //if the survey is restricted to only logged in users
      if (restrictions.loggedIn) {
        if (!user) {
          return false;
        }
      }

      //if the survey is restricted to only unsubscribed users
      if (restrictions.unsubscribed) {
        if (isSubscribed || !user) {
          return false;
        }
      }

      if (restrictions.subscribed) {
        if (!isSubscribed) {
          return false;
        }
      }

      if (restrictions.canceled) {
        if (!user || isSubscribed || !user?.subscriptions?.length) {
          return false;
        }
      }

      return true;
    }

    return false;
  };

  const isViewableSurvey = (survey) => {
    const { restrictions, maxResponses, schedule, responses } = survey;

    const { startTime, endTime } = schedule;

    const currentTime = time().unix();

    //if the survey is restricted to only logged in users
    if (restrictions.loggedIn) {
      if (!user) {
        return {
          isViewable: false,
          errorReason: CONTENT_RESTRICTION_MESSAGE.LOGGED_IN("survey"),
        };
      }
    }

    //if the survey is restricted to only unsubscribed users
    if (restrictions.unsubscribed) {
      if (isSubscribed || !user) {
        return {
          isViewable: false,
          errorReason: CONTENT_RESTRICTION_MESSAGE.UNSUBSCRIBED("survey"),
        };
      }
    }

    if (restrictions.subscribed) {
      if (!isSubscribed) {
        return {
          isViewable: false,
          errorReason: CONTENT_RESTRICTION_MESSAGE.SUBSCRIBED("survey"),
        };
      }
    }

    if (restrictions.canceled) {
      if (!user || isSubscribed || !user?.subscriptions?.length) {
        return {
          isViewable: false,
          errorReason: CONTENT_RESTRICTION_MESSAGE.CANCELED("survey"),
        };
      }
    }

    //if survey period has'nt started
    if (startTime && currentTime < startTime) {
      setShouldShowSurveyCountdownTimer({
        shouldShowSurveyCountdownTimer: true,
      });

      setSurveyStartTime({
        surveyStartTime: startTime,
      });

      return {
        isViewable: false,
        errorReason: `This survey hasn't started yet. This survey starts on ${time
          .unix(startTime)
          .tz(timezone)
          .format(
            "MMMM DD, YYYY [at] hh:mm a"
          )}\n\nPlease contact our support team for more information.`,
      };
    }
    //if survey period has ended
    if (endTime && currentTime > endTime) {
      setSurveyEndTime({
        surveyEndTime: endTime,
      });

      return {
        isViewable: false,
        errorReason: `This survey has ended. This survey ended on ${time
          .unix(endTime)
          .tz(timezone)
          .format(
            "MMMM DD, YYYY [at] hh:mm a"
          )}\n\nPlease contact our support team for more information.`,
      };
    }

    //if the maximum number of responses have been collected
    if (maxResponses && responses.length >= maxResponses) {
      return {
        isViewable: false,
        errorReason: `You can no longer view or respond to this survey because the maximum number of responses have been collected.
          \n\nPlease contact our support team for more information.`,
      };
    }

    return {
      isViewable: true,
      errorReason: null,
    };
  };

  const randomlySelectSurveyFromCandidates = (candidates) => {
    return candidates[Math.floor(Math.random() * candidates.length)];
  };

  const decideIfSurveySideOutShouldBeShown = () => {
    //If not on a survey page
    if (router.pathname.includes("/surveys")) {
      return false;
    }

    const dismissedSurveys = retrieveLocalValue("dismissedSurveys") ?? [];

    const hasDismissedSurvey = dismissedSurveys.some(
      (surveyId) => surveyId === state.activeSurvey.id
    );

    if (hasDismissedSurvey) {
      return false;
    }

    //if the user has already responded to the selected candidate
    if (user) {
      const hasResponded = state.activeSurvey.responses.some(
        ({ userId }) => userId === user.id
      );

      if (hasResponded) {
        return false;
      }
    }

    const surveysRespondedTo = retrieveLocalValue("surveysRespondedTo") ?? [];

    const hasResponded = surveysRespondedTo.some(
      (surveyId) => surveyId === state.activeSurvey.id
    );

    if (hasResponded) {
      return false;
    }

    if (!state.activeSurvey.restrictions.allowPopup) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (typeof window !== "undefined" && authStatus === "ready") {
      if (router.pathname.includes("/surveys")) {
        const { surveyId } = router.query;

        if (surveyId) {
          fetchSurvey(surveyId);
        }
      } else {
        fetchSurveysList({ limit: "all" });
      }

      return () => {
        setState(initialState);
      };
    }
  }, [router, authStatus]);

  useEffect(() => {
    if (survey && authStatus === "ready") {
      const { isViewable, errorReason } = isViewableSurvey(survey);

      setSurveyDisplayMessage(errorReason);

      if (isViewable) {
        setActiveSurvey({
          activeSurvey: prepareSurveyContents(survey),
        });
      }
    }
  }, [survey, authStatus]);

  useEffect(() => {
    if (surveysList) {
      setSurveys({
        surveys: surveysList,
      });
    }
  }, [surveysList]);

  useEffect(() => {
    if (state.surveys.length) {
      const surveys = state.surveys;

      const candidates = [];

      for (const survey of surveys) {
        if (isSurveyCandidate(survey)) {
          candidates.push(survey.id);
        }
      }

      setCandidates({
        candidates,
      });
    }
  }, [user, isSubscribed, state.surveys]);

  useEffect(() => {
    if (state.candidates) {
      const selectedCandidate = randomlySelectSurveyFromCandidates(
        state.candidates
      );

      if (selectedCandidate) {
        setActiveSurveyId({
          activeSurveyId: selectedCandidate,
        });
      }
    }
  }, [state.candidates]);

  useEffect(() => {
    if (state.activeSurveyId) {
      const localActiveSurvey = retrieveLocalValue("activeSurvey");

      const candidate = state.surveys.filter(
        (survey) => survey.id === state.activeSurveyId
      )[0];

      if (localActiveSurvey) {
        if (
          localActiveSurvey?.id === state?.activeSurveyId &&
          localActiveSurvey?.publishedAt === candidate?.publishedAt
        ) {
          setActiveSurvey({
            activeSurvey: prepareSurveyContents(localActiveSurvey),
          });
        } else {
          removeLocalValue("activeSurvey");

          setActiveSurvey({
            activeSurvey: prepareSurveyContents(candidate),
          });
        }
      } else {
        setActiveSurvey({
          activeSurvey: prepareSurveyContents(candidate),
        });
      }
    } else {
      setActiveSurvey({
        activeSurvey: null,
      });
    }

    resetSaveSurveyResponse();
  }, [state.activeSurveyId]);

  useEffect(() => {
    if (state.activeSurvey) {
      rememberLocalValue({
        key: "activeSurvey",
        value: state.activeSurvey,
        ttl: 6.048e8, //7 days
      });

      setShowSurveySideOut({
        showSurveySideOut: decideIfSurveySideOutShouldBeShown(),
      });
    }
  }, [state.activeSurvey]);

  useEffect(() => {
    if (state.activeContentId && state.activeSurvey) {
      setActiveContent({
        activeContent: state.activeSurvey.contents.filter(
          (content) => content.id === state.activeContentId
        )[0],
      });
    }
  }, [state.activeContentId, state.activeSurvey]);

  useEffect(() => {
    setSurveyContentError({
      surveyContentError: null,
    });
  }, [state.currentStep]);

  useEffect(() => {
    if (savedSurveyResponse) {
      if (state.currentStep < state.activeSurvey.contents.length) {
        setCurrentStep({
          currentStep: state.currentStep + 1,
        });
      }

      const surveysRespondedTo = retrieveLocalValue("surveysRespondedTo") ?? [];

      surveysRespondedTo.push(
        savedSurveyResponse.surveyId ?? state.activeSurvey.id
      );

      rememberLocalValue({
        key: "surveysRespondedTo",
        value: surveysRespondedTo,
      });
    }
  }, [savedSurveyResponse]);

  useEffect(() => {
    if (saveSurveyResponseError) {
      addSnackbar(saveSurveyResponseError, {
        type: "danger",
      });
    }
  }, [saveSurveyResponseError]);

  const value = {
    ...state,
    isSavingActiveSurveyResponse: saveSurveyResponseStatus === "pending",
    surveyError,
    surveyStatus,
    surveyDisplayMessage,
    saveActiveSurveyResponse,
    setSurveyContentError,
    setCurrentStep,
    setActiveContentId,
    setActiveContent,
    setActiveContentResponse,
    setActiveContentOptionIsSelected,
  };

  return (
    <SurveysContext.Provider value={value}>
      {children}

      {state.showSurveySideOut && <SurveySideOut />}
    </SurveysContext.Provider>
  );
};
