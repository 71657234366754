import React from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "../services/validations/resolvers/joiResolver";
import recoveryPasswordSchema from "../services/validations/recoveryPasswordSchema";

export default React.memo(function ChangePasswordForm({
  onChangePassword,
  isProcessing,
}) {
  const { handleSubmit, register, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: joiResolver(recoveryPasswordSchema),
  });

  const onSubmit = (data) => {
    onChangePassword(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group">
        <label className="sr-only" htmlFor="password">
          New Password
        </label>
        <input
          ref={register}
          className={`form-control form-control-sm ${
            errors.password ? "is-invalid" : ""
          }`}
          id="password"
          type="password"
          name="password"
          placeholder="New Password"
        />
        {errors.password && (
          <span className="input-error">Please enter a 6-digit password</span>
        )}
      </div>

      <div className="form-group">
        <label className="sr-only" htmlFor="confirmPassword">
          Confirm Password
        </label>
        <input
          ref={register}
          className={`form-control form-control-sm ${
            errors.confirmPassword ? "is-invalid" : ""
          }`}
          id="confirmPassword"
          type="password"
          name="confirmPassword"
          placeholder="Confirm Password"
        />
        {errors.confirmPassword && (
          <span className="input-error">Passwords do not match</span>
        )}
      </div>

      <button
        className="btn btn-sm btn-dark"
        type="submit"
        disabled={isProcessing}
      >
        {isProcessing ? "Please wait..." : "Reset password"}
      </button>
    </form>
  );
});
