export default function storeInLocalStorage(key, value, ttl) {
	if (typeof window === 'undefined') return;

	const now = new Date();

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire

	let item = {
		value
	};

	if (ttl) {
		item.expiry = now.getTime() + ttl;
	}

	localStorage.setItem(key, JSON.stringify(item));
}
