export const BUTTON_TEXT_MAX_LENGTH = 24;

export const IMAGE_ALIGNMENT_OPTIONS = {
  TOP: "top",
  MIDDLE: "middle",
  LEFT: "left",
  RIGHT: "right",
};

export const SURVEY_CONTENT_TYPES = {
  WELCOME_SCREEN: "welcome-screen",
  MULTIPLE_CHOICE: "multiple-choice",
  SHORT_TEXT: "short-text",
  LONG_TEXT: "long-text",
  STATEMENT: "statement",
  PICTURE_CHOICE: "picture-choice",
  EMAIL: "email",
  ENDING_SCREEN: "ending-screen",
};
