import { useEffect, useState } from "react";
import { useApiCall } from "./useApiCall";

export const useAddNewsletterSubscriber = () => {
  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();
  const [
    addingNewsletterSubscriberStatus,
    setAddingNewsletterSubscriberStatus,
  ] = useState("idle");
  const [addedNewsletterSubscriber, setAddedNewsletterSubscriber] = useState();
  const [addNewsletterSubscriberError, setAddNewsletterSubscriberError] =
    useState();

  const addNewsletterSubscriber = (contactInfo) => {
    setAddNewsletterSubscriberError();
    setAddedNewsletterSubscriber();

    let url = `/newsletter`;

    const requestOptions = {
      url,
      method: "POST",
      data: contactInfo,
    };

    setAddingNewsletterSubscriberStatus("pending");
    executeApiCall(requestOptions);
  };

  useEffect(() => {
    switch (apiCallStatus) {
      case "success":
        if (apiCallResponse.data.error) {
          setAddNewsletterSubscriberError(
            apiCallResponse.data.error.userMessage
          );
          setAddingNewsletterSubscriberStatus("ready");
        } else {
          setAddNewsletterSubscriberError();

          if (apiCallResponse.data.data) {
            const { isAddedToNewsletter } = apiCallResponse.data.data;

            setAddingNewsletterSubscriberStatus("ready");
            setAddedNewsletterSubscriber(isAddedToNewsletter);
          }
        }
        break;
      case "error":
        setAddingNewsletterSubscriberStatus("ready");
    }
  }, [apiCallStatus, apiCallResponse]);

  return {
    addingNewsletterSubscriberStatus,
    addNewsletterSubscriber,
    addedNewsletterSubscriber,
    addNewsletterSubscriberError,
  };
};
