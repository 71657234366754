import React from "react";
import { useGlobal } from "../services/hooks/useGlobal";
import { IMAGE_ALIGNMENT_OPTIONS } from "../services/lib/surveys/constants";

export default function SurveyContentPictureChoice(props) {
  const {
    content,
    image,
    imageAlignment,
    allowMultipleSelection,
    options,
    error,
    onSelectOption,
    showLabels,
  } = props;

  const { cdnUrl } = useGlobal();

  const handleClickSelectOption = (e) => {
    const isOption = e.target.matches("[data-option-id]");

    if (isOption) {
      const optionId = e?.target?.dataset?.optionId;

      const isSelectable = e?.target?.dataset?.isSelectable === "yes";

      if (isSelectable) {
        onSelectOption({
          optionId,
          isSelected: e.target.checked ?? true,
        });
      }
    }
  };

  if (image.src) {
    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.TOP) {
      return (
        <div className="survey-content" onClick={handleClickSelectOption}>
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-img">
              <img src={image.src} />
            </div>

            <div className="survey-content-td">
              {content.text && <h6>{content.text}</h6>}

              {content.description && (
                <p className="text-muted">{content.description}</p>
              )}
            </div>

            {allowMultipleSelection && (
              <p className="text-muted d-block">Select all that apply</p>
            )}

            {error && <span className="input-error mb-3">{error}</span>}

            <div className="survey-content-picture-choice">
              {options.map((option) => (
                <div
                  key={option.id}
                  className={`custom-control custom-control-img picture-choice ${
                    option.isSelected ? "is-selected" : ""
                  }`}
                  data-option-id={option.id}
                  data-is-selectable={option.isSelectable ? "yes" : "no"}
                >
                  <input
                    type={allowMultipleSelection ? "checkbox" : "radio"}
                    className="custom-control-input"
                    name="picture-choice"
                    data-option-id={option.id}
                    data-is-selectable={option.isSelectable ? "yes" : "no"}
                    id={`${option.id}-scpc`}
                    disabled={!option.isSelectable}
                    checked={option.isSelected}
                  />
                  <label
                    className={`custom-control-label ${
                      option.isSelectable ? "is-selectable" : ""
                    }`}
                    htmlFor={`${option.id}-scpc`}
                    data-option-id={option.id}
                    data-is-selectable={option.isSelectable ? "yes" : "no"}
                  >
                    {option.isSelected && (
                      <div className="picture-choice-selected-icon">
                        <div className="icon-wrap">
                          <span className="picture-choice-icon">
                            <svg height="13" width="16">
                              <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                    )}

                    {option.image.src ? (
                      <span
                        className="embed-responsive embed-responsive-1by1 bg-cover"
                        style={{
                          backgroundImage: `url(${option.image.src})`,
                        }}
                        data-option-id={option.id}
                        data-is-selectable={option.isSelectable ? "yes" : "no"}
                      />
                    ) : (
                      <span
                        className="embed-responsive embed-responsive-1by1 bg-cover"
                        style={{
                          backgroundImage: `url(${cdnUrl}/surveys/content-images/default.png)`,
                        }}
                        data-option-id={option.id}
                        data-is-selectable={option.isSelectable ? "yes" : "no"}
                      />
                    )}
                  </label>

                  {showLabels && option.label && (
                    <div
                      className="picture-choice-label"
                      data-option-id={option.id}
                      data-is-selectable={option.isSelectable ? "yes" : "no"}
                      onClick={
                        option.isSelectable ? handleClickSelectOption : () => {}
                      }
                    >
                      {option.label}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }

    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.MIDDLE) {
      return (
        <div className="survey-content" onClick={handleClickSelectOption}>
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-td">
              {content.text && <h6>{content.text}</h6>}

              {content.description && (
                <p className="text-muted">{content.description}</p>
              )}
            </div>

            <div className="survey-content-img">
              <img src={image.src} />
            </div>

            {allowMultipleSelection && (
              <p className="text-muted d-block">Select all that apply</p>
            )}

            {error && <span className="input-error mb-3">{error}</span>}

            <div className="survey-content-picture-choice">
              {options.map((option) => (
                <div
                  key={option.id}
                  className={`custom-control custom-control-img picture-choice ${
                    option.isSelected ? "is-selected" : ""
                  }`}
                  data-option-id={option.id}
                  data-is-selectable={option.isSelectable ? "yes" : "no"}
                >
                  <input
                    type={allowMultipleSelection ? "checkbox" : "radio"}
                    className="custom-control-input"
                    name="picture-choice"
                    data-option-id={option.id}
                    data-is-selectable={option.isSelectable ? "yes" : "no"}
                    id={`${option.id}-scpc`}
                    disabled={!option.isSelectable}
                    checked={option.isSelected}
                  />
                  <label
                    className={`custom-control-label ${
                      option.isSelectable ? "is-selectable" : ""
                    }`}
                    htmlFor={`${option.id}-scpc`}
                  >
                    {option.isSelected && (
                      <div className="picture-choice-selected-icon">
                        <div className="icon-wrap">
                          <span className="picture-choice-icon">
                            <svg height="13" width="16">
                              <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                    )}

                    {option.image.src ? (
                      <span
                        className="embed-responsive embed-responsive-1by1 bg-cover"
                        style={{
                          backgroundImage: `url(${option.image.src})`,
                        }}
                      />
                    ) : (
                      <span
                        className="embed-responsive embed-responsive-1by1 bg-cover"
                        style={{
                          backgroundImage: `url(${cdnUrl}/surveys/content-images/default.png)`,
                        }}
                      />
                    )}
                  </label>

                  {showLabels && option.label && (
                    <div
                      className="picture-choice-label"
                      data-option-id={option.id}
                      data-is-selectable={option.isSelectable ? "yes" : "no"}
                      onClick={
                        option.isSelectable ? handleClickSelectOption : () => {}
                      }
                    >
                      {option.label}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }

    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.LEFT) {
      return (
        <div className="survey-content" onClick={handleClickSelectOption}>
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-img">
              <img src={image.src} />
            </div>

            <div className="survey-content-td-left">
              <div className="survey-content-td">
                {content.text && <h6>{content.text}</h6>}

                {content.description && (
                  <p className="text-muted">{content.description}</p>
                )}

                {allowMultipleSelection && (
                  <p className="text-muted d-block">Select all that apply</p>
                )}

                {error && <span className="input-error mb-3">{error}</span>}
              </div>

              <div className="survey-content-picture-choice">
                {options.map((option) => (
                  <div
                    key={option.id}
                    className={`custom-control custom-control-img picture-choice ${
                      option.isSelected ? "is-selected" : ""
                    }`}
                    data-option-id={option.id}
                    data-is-selectable={option.isSelectable ? "yes" : "no"}
                  >
                    <input
                      type={allowMultipleSelection ? "checkbox" : "radio"}
                      className="custom-control-input"
                      name="picture-choice"
                      data-option-id={option.id}
                      data-is-selectable={option.isSelectable ? "yes" : "no"}
                      id={`${option.id}-scpc`}
                      disabled={!option.isSelectable}
                      checked={option.isSelected}
                    />
                    <label
                      className={`custom-control-label ${
                        option.isSelectable ? "is-selectable" : ""
                      }`}
                      htmlFor={`${option.id}-scpc`}
                    >
                      {option.isSelected && (
                        <div className="picture-choice-selected-icon">
                          <div className="icon-wrap">
                            <span className="picture-choice-icon">
                              <svg height="13" width="16">
                                <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                              </svg>
                            </span>
                          </div>
                        </div>
                      )}

                      {option.image.src ? (
                        <span
                          className="embed-responsive embed-responsive-1by1 bg-cover"
                          style={{
                            backgroundImage: `url(${option.image.src})`,
                          }}
                        />
                      ) : (
                        <span
                          className="embed-responsive embed-responsive-1by1 bg-cover"
                          style={{
                            backgroundImage: `url(${cdnUrl}/surveys/content-images/default.png)`,
                          }}
                        />
                      )}
                    </label>

                    {showLabels && option.label && (
                      <div
                        className="picture-choice-label"
                        data-option-id={option.id}
                        data-is-selectable={option.isSelectable ? "yes" : "no"}
                        onClick={
                          option.isSelectable
                            ? handleClickSelectOption
                            : () => {}
                        }
                      >
                        {option.label}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.RIGHT) {
      return (
        <div className="survey-content" onClick={handleClickSelectOption}>
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-td-right">
              <div className="survey-content-td">
                {content.text && <h6>{content.text}</h6>}

                {content.description && (
                  <p className="text-muted">{content.description}</p>
                )}

                {allowMultipleSelection && (
                  <p className="text-muted d-block">Select all that apply</p>
                )}

                {error && <span className="input-error mb-3">{error}</span>}
              </div>

              <div className="survey-content-picture-choice">
                {options.map((option) => (
                  <div
                    key={option.id}
                    className={`custom-control custom-control-img picture-choice ${
                      option.isSelected ? "is-selected" : ""
                    }`}
                    data-option-id={option.id}
                    data-is-selectable={option.isSelectable ? "yes" : "no"}
                  >
                    <input
                      type={allowMultipleSelection ? "checkbox" : "radio"}
                      className="custom-control-input"
                      name="picture-choice"
                      data-option-id={option.id}
                      data-is-selectable={option.isSelectable ? "yes" : "no"}
                      id={`${option.id}-scpc`}
                      disabled={!option.isSelectable}
                      checked={option.isSelected}
                    />
                    <label
                      className={`custom-control-label ${
                        option.isSelectable ? "is-selectable" : ""
                      }`}
                      htmlFor={`${option.id}-scpc`}
                    >
                      {option.isSelected && (
                        <div className="picture-choice-selected-icon">
                          <div className="icon-wrap">
                            <span className="picture-choice-icon">
                              <svg height="13" width="16">
                                <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                              </svg>
                            </span>
                          </div>
                        </div>
                      )}

                      {option.image.src ? (
                        <span
                          className="embed-responsive embed-responsive-1by1 bg-cover"
                          style={{
                            backgroundImage: `url(${option.image.src})`,
                          }}
                        />
                      ) : (
                        <span
                          className="embed-responsive embed-responsive-1by1 bg-cover"
                          style={{
                            backgroundImage: `url(${cdnUrl}/surveys/content-images/default.png)`,
                          }}
                        />
                      )}
                    </label>

                    {showLabels && option.label && (
                      <div
                        className="picture-choice-label"
                        data-option-id={option.id}
                        data-is-selectable={option.isSelectable ? "yes" : "no"}
                        onClick={
                          option.isSelectable
                            ? handleClickSelectOption
                            : () => {}
                        }
                      >
                        {option.label}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="survey-content-img">
              <img src={image.src} />
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="survey-content" onClick={handleClickSelectOption}>
      <div className="survey-content-td-img">
        <div className="survey-content-td">
          {content.text && <h6>{content.text}</h6>}

          {content.description && (
            <p className="text-muted">{content.description}</p>
          )}

          {allowMultipleSelection && (
            <p className="text-muted d-block">Select all that apply</p>
          )}

          {error && <span className="input-error mb-3">{error}</span>}
        </div>

        <div className="survey-content-picture-choice">
          {options.map((option) => (
            <div
              key={option.id}
              className={`custom-control custom-control-img picture-choice ${
                option.isSelected ? "is-selected" : ""
              }`}
              data-option-id={option.id}
              data-is-selectable={option.isSelectable ? "yes" : "no"}
            >
              <input
                type={allowMultipleSelection ? "checkbox" : "radio"}
                className="custom-control-input"
                name="picture-choice"
                data-option-id={option.id}
                data-is-selectable={option.isSelectable ? "yes" : "no"}
                id={`${option.id}-scpc`}
                disabled={!option.isSelectable}
                checked={option.isSelected}
              />
              <label
                className={`custom-control-label ${
                  option.isSelectable ? "is-selectable" : ""
                }`}
                htmlFor={`${option.id}-scpc`}
              >
                {option.isSelected && (
                  <div className="picture-choice-selected-icon">
                    <div className="icon-wrap">
                      <span className="picture-choice-icon">
                        <svg height="13" width="16">
                          <path d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                )}

                {option.image.src ? (
                  <span
                    className="embed-responsive embed-responsive-1by1 bg-cover"
                    style={{
                      backgroundImage: `url(${option.image.src})`,
                    }}
                  />
                ) : (
                  <span
                    className="embed-responsive embed-responsive-1by1 bg-cover"
                    style={{
                      backgroundImage: `url(${cdnUrl}/surveys/content-images/default.png)`,
                    }}
                  />
                )}
              </label>

              {showLabels && option.label && (
                <div
                  className="picture-choice-label"
                  data-option-id={option.id}
                  data-is-selectable={option.isSelectable ? "yes" : "no"}
                  onClick={
                    option.isSelectable ? handleClickSelectOption : () => {}
                  }
                >
                  {option.label}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
