import React, { useEffect } from "react";
import Loader from "./Loader";
import AppCrest from "./AppCrest";
import AppBase from "./AppBase";
import GlobalHead from "./GlobalHead";
import { useAuth } from "../services/hooks/useAuth";
import { enableXssWarning } from "../public/js/core";
import { useGlobal } from "../services/hooks/useGlobal";

export default React.memo(function AppShell({ children }) {
  const { authStatus } = useAuth();
  const { pageDescription, pageTitle } = useGlobal();

  useEffect(() => {
    enableXssWarning();
  }, []);

  if (authStatus !== "ready") {
    return (
      <>
        <GlobalHead pageDescription={pageDescription} pageTitle={pageTitle} />
        <Loader absoluteCenter />
      </>
    );
  }

  return (
    <>
      <AppCrest />

      {children}

      <AppBase />
    </>
  );
});
