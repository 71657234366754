export default function parseCouponMetadata(options) {
  try {
    const { metadata } = options;

    if (
      metadata &&
      metadata.applies_to &&
      typeof metadata.applies_to === "string"
    ) {
      metadata.applies_to = JSON.parse(metadata.applies_to);
    }

    return {
      ...options,
      metadata,
    };
  } catch (e) {
    throw e;
  }
}
