import React, { createContext, useReducer, useEffect } from "react";
import quizReducer from "../reducers/quizReducer";
import { useGlobal } from "../../services/hooks/useGlobal";
import { useAuth } from "../../services/hooks/useAuth";
import roundNumber from "../../util/roundNumber";

//Actions
export const ACTIONS = {
  SET_STATE: "SetState",
  SET_RESPONSES: "SetResponses",
  SET_SHOULD_SAVE_FOR_NEW_USER: "SetShouldSaveForNewUser",
  SET_SKIN_TONE: "SetSkinTone",
  SET_EYE_COLOR: "SetEyeColor",
  SET_HAIR_COLOR: "SetHairColor",
  SET_SKIN_TYPE: "SetSkinType",
  SET_SKIN_CONCERN: "SetSkinConcern",
  SET_MAKEUP_LEVEL: "SetMakeupLevel",
  SET_MAKEUP_STYLE: "SetMakeupStyle",
  SET_FAVORITE_MAKEUP: "SetFavoriteMakeup",
  SET_FAVORITE_SKINCARE: "SetFavoriteSkincare",
  SET_FAVORITE_HAIRCARE: "SetFavoriteHaircare",
  SET_FAVORITE_TOOL: "SetFavoriteTool",
  SET_MONTHLY_BEAUTY_BUDGET: "SetMonthlyBeautyBudget",
  SET_ETHNICITY: "SetEthnicity",
  SET_REFERRER: "SetReferrer",
  SET_PERCENT_COMPLETE: "SetPercentComplete",
  REMOVE_SKIN_CONCERN: "RemoveSkinConcern",
  REMOVE_FAVORITE_MAKEUP: "RemoveFavoriteMakeup",
  REMOVE_FAVORITE_SKINCARE: "RemoveFavoriteSkincare",
  REMOVE_FAVORITE_HAIRCARE: "RemoveFavoriteHaircare",
  REMOVE_FAVORITE_TOOL: "RemoveFavoriteTool",
  REMOVE_REFERRER: "RemoveReferrer",
};

// Initial state
const initialState = {
  quizResponses: {
    skinTone: "",
    eyeColor: "",
    hairColor: "",
    skinType: "",
    skinConcerns: [],
    makeupLevel: "",
    makeupStyle: "",
    favoriteMakeup: [],
    favoriteSkincare: [],
    favoriteHaircare: [],
    favoriteTools: [],
    monthlyBeautyBudget: "",
    ethnicity: "",
    referrers: [],
  },
  quizPercentComplete: 0,
  shouldSaveForNewUser: false,
};

// Create context
export const QuizContext = createContext(initialState);

// Provider component
export const QuizProvider = ({ children }) => {
  const [state, dispatch] = useReducer(quizReducer, initialState);
  const { apiBaseUrl } = useGlobal();
  const { user, accessToken, authStatus } = useAuth();

  const setAnswer = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const setResponses = (payload) => {
    dispatch({
      type: ACTIONS.SET_RESPONSES,
      payload,
    });
  };

  const setPercentComplete = (payload) => {
    dispatch({
      type: ACTIONS.SET_PERCENT_COMPLETE,
      payload,
    });
  };

  const setShouldSaveForNewUser = (payload) => {
    dispatch({
      type: ACTIONS.SET_SHOULD_SAVE_FOR_NEW_USER,
      payload,
    });
  };

  const savePreferences = async () => {
    if (authStatus === "ready" && user) {
      await fetch(`${apiBaseUrl}/users/${user.id}/preferences`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
        credentials: "include",
        body: JSON.stringify(state.quizResponses),
      });

      setShouldSaveForNewUser({
        shouldSaveForNewUser: false,
      });
    }
  };

  const fetchPreferences = async () => {
    if (user) {
      const response = await fetch(
        `${apiBaseUrl}/users/${user.id}/preferences`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: accessToken,
          },
          credentials: "include",
        }
      );

      const { data } = await response.json();

      if (data) {
        const { preferences } = data;

        if (Object.keys(preferences).length) {
          setResponses({
            quizResponses: preferences,
          });
        }
      }
    }
  };

  const resetQuiz = () => {
    dispatch({
      type: ACTIONS.SET_STATE,
      payload: initialState,
    });
  };

  useEffect(async () => {
    if (authStatus === "ready" && user && !state.shouldSaveForNewUser) {
      await fetchPreferences();
    }

    if (authStatus === "ready" && user && state.shouldSaveForNewUser) {
      await savePreferences();
    }
  }, [authStatus, user, state.shouldSaveForNewUser]);

  useEffect(() => {
    if (!state.quizResponses) {
      return;
    }

    let totalKeys = Object.keys(state.quizResponses).length;
    let totalHasContent = 0;

    if (totalKeys === 0) {
      return;
    }

    for (const key in state.quizResponses) {
      if (typeof state.quizResponses[key] === "string") {
        if (state.quizResponses[key] !== "") {
          totalHasContent++;
        }
      } else if (Array.isArray(state.quizResponses[key])) {
        if (state.quizResponses[key].length > 0) {
          totalHasContent++;
        }
      }
    }

    setPercentComplete({
      quizPercentComplete: roundNumber((totalHasContent / totalKeys) * 100),
    });
  }, [state.quizResponses]);

  const value = {
    ...state,
    fetchPreferences,
    setAnswer,
    setShouldSaveForNewUser,
    savePreferences,
    resetQuiz,
  };

  return <QuizContext.Provider value={value}>{children}</QuizContext.Provider>;
};
