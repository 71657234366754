import {
  ITEM_QUANTITY_LIMIT,
  CART_ITEMS_LIMIT,
} from "../../../contexts/cartState";

export default function assertCartLimit(cartItems, payload) {
  const productId = payload?.product?.id ?? payload?.productId;

  const totalQuantity =
    cartItems.reduce((acc, { quantity }) => acc + quantity, 0) +
    payload?.quantity;

  if (totalQuantity > CART_ITEMS_LIMIT) {
    return false;
  }

  for (const { product } of cartItems) {
    if (product.id === productId && payload?.quantity > ITEM_QUANTITY_LIMIT) {
      return false;
    }
  }

  return true;
}
