import Joi from "joi";

const schema = Joi.object({
  firstName: Joi.string(),
  lastName: Joi.string(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .lowercase()
    .required(),
});

export default schema;
