import React, { useState, useEffect } from "react";

const TYPE_REGEX =
  /^gray|gray-dark|primary|secondary|success|info|warning|danger|light|dark|checkout|unavailable$/;

const Overlay = React.memo(({ type, disablePointer, fixPosition }) => {
  const [overlayClass, setOverlayClass] = useState();

  const style = {
    pointerEvents: disablePointer ? "none" : "auto",
    position: fixPosition ? "fixed" : "absolute",
  };

  useEffect(() => {
    setOverlayClass(
      TYPE_REGEX.test(type) ? `overlay-${type}` : `overlay-default`
    );
  }, [type]);

  return (
    <>
      <div className={overlayClass} style={style}></div>
    </>
  );
});

export default Overlay;
