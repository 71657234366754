import React from "react";
import SurveyContentWelcomeScreen from "./SurveyContentWelcomeScreen";
import SurveyContentMultipleChoice from "./SurveyContentMultipleChoice";
import SurveyContentShortText from "./SurveyContentShortText";
import SurveyContentLongText from "./SurveyContentLongText";
import SurveyContentStatement from "./SurveyContentStatement";
import SurveyContentPictureChoice from "./SurveyContentPictureChoice";
import SurveyContentEmail from "./SurveyContentEmail";
import SurveyContentEndingScreen from "./SurveyContentEndingScreen";
import { SURVEY_CONTENT_TYPES } from "../services/lib/surveys/constants";

export default React.memo(function SurveyContent(props) {
  const { type } = props;

  switch (type) {
    case SURVEY_CONTENT_TYPES.WELCOME_SCREEN: {
      return <SurveyContentWelcomeScreen {...props} />;
    }

    case SURVEY_CONTENT_TYPES.MULTIPLE_CHOICE: {
      return <SurveyContentMultipleChoice {...props} />;
    }

    case SURVEY_CONTENT_TYPES.SHORT_TEXT: {
      return <SurveyContentShortText {...props} />;
    }

    case SURVEY_CONTENT_TYPES.LONG_TEXT: {
      return <SurveyContentLongText {...props} />;
    }

    case SURVEY_CONTENT_TYPES.STATEMENT: {
      return <SurveyContentStatement {...props} />;
    }

    case SURVEY_CONTENT_TYPES.PICTURE_CHOICE: {
      return <SurveyContentPictureChoice {...props} />;
    }

    case SURVEY_CONTENT_TYPES.EMAIL: {
      return <SurveyContentEmail {...props} />;
    }

    case SURVEY_CONTENT_TYPES.ENDING_SCREEN: {
      return <SurveyContentEndingScreen {...props} />;
    }
  }

  return null;
});
