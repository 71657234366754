import roundNumber from "../../../../util/roundNumber";

export default function getSalesTaxAmount(
  cartSubTotal,
  shippingFee,
  salesTaxRate,
  discount
) {
  const salesTaxAmount = roundNumber(
    (cartSubTotal + shippingFee - discount) * salesTaxRate,
    2
  );

  return salesTaxAmount;
}
