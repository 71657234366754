import { cloneDeep } from "lodash";
import shuffleArray from "../../../util/shuffleArray";
import { defaultSurveyEndingScreen } from "./defaultSurveyEndingScreen";
import { SURVEY_CONTENT_TYPES } from "./constants";

export default function prepareSurveyContents(survey) {
  if (survey?.contents?.length) {
    //handle randomize options
    survey.contents = survey.contents.map((content) => {
      if (content && content.randomizeOptions && content.options) {
        content.options = shuffleArray(content.options);
      }

      return content;
    });

    //add default ending screen if none was provided
    const lastContent = survey.contents[survey.contents.length - 1];

    if (lastContent.type !== SURVEY_CONTENT_TYPES.ENDING_SCREEN) {
      //insert default ending screen
      survey.contents.push(defaultSurveyEndingScreen);
    }
  }

  return cloneDeep(survey);
}
