import React, { useState, useEffect } from "react";
import { useScrollTo } from "../services/hooks/useScrollTo";
import { useInView } from "react-intersection-observer";

const TYPE_REGEX =
  /^gray|gray-dark|primary|secondary|success|info|warning|danger|light|dark$/;
const STYLE_REGEX = /^notice|light|outline|shadow$/;

const Alert = React.memo(
  ({
    type = "info",
    style = "light",
    icon,
    message,
    size,
    link,
    callToAction,
    dismissable = true,
    onDismiss,
    scroll = true,
  }) => {
    const [messageFragments, setMessageFragments] = useState([]);
    const [iconClass, setIconClass] = useState("");
    const [alertId] = useState(`alert-${Math.ceil(Math.random() * 10000)}`);

    const [isDismissed, setIsDissmised] = useState(false);

    const { scrollTo } = useScrollTo();

    const { ref, inView } = useInView();

    const handleDismiss = (e) => {
      e.preventDefault();

      setIsDissmised(true);

      if (typeof onDismiss === "function") {
        onDismiss();
      }
    };

    useEffect(() => {
      if (alertId && scroll) {
        if (!inView) {
          scrollTo(`#${alertId}`);
        }
      }
    }, [alertId, scroll]);

    useEffect(() => {
      if (typeof message === "string") {
        setMessageFragments(message.split("\n"));
      }
    }, [message]);

    useEffect(() => {
      if (!icon) {
        switch (type) {
          case "success": {
            return setIconClass("fas fa-check-circle");
          }

          case "info": {
            return setIconClass("fas fa-info");
          }

          case "danger": {
            return setIconClass("fas fa-exclamation-circle");
          }

          case "warning": {
            return setIconClass("fas fa-exclamation-triangle");
          }

          default: {
            return setIconClass("fas fa-exclamation");
          }
        }
      } else {
        setIconClass(`fas fa-${icon}`);
      }
    }, [type]);

    if (isDismissed) {
      return null;
    }

    return (
      <div
        ref={ref}
        id={alertId}
        className={`fade show mb-5 m-2 alert alert-custom ${
          style === "notice"
            ? `alert-notice alert-outline-${
                TYPE_REGEX.test(type) ? type : "info"
              }`
            : `alert-${STYLE_REGEX.test(style) ? style : "light"}-${
                TYPE_REGEX.test(type) ? type : "info"
              }`
        }`}
        role="alert"
      >
        <div className={`alert-icon`}>
          <i className={iconClass} />
        </div>
        <div className="alert-text">
          {messageFragments.map((messageFragment, index) => (
            <p key={index}>{messageFragment}</p>
          ))}
          {link && (
            <a
              href={link}
              className={`float-right btn btn-sm btn-${
                TYPE_REGEX.test(type) ? type : "info"
              }`}
            >
              {callToAction ? callToAction : "Go"}
            </a>
          )}
        </div>
        {dismissable && (
          <div className="alert-close">
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={handleDismiss}
            >
              <span aria-hidden="true">
                <i className="fa fa-times" />
              </span>
            </button>
          </div>
        )}
      </div>
    );
  }
);

export default Alert;
