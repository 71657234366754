export default function getShippingMultiple(cartItems) {
  let highestMultiple = 1;

  for (const { product } of cartItems) {
    const currentMultiple = product?.shipping?.multiple;

    if (currentMultiple && currentMultiple > highestMultiple) {
      highestMultiple = currentMultiple;
    }
  }

  return highestMultiple;
}
