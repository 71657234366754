import React from "react";
import { IMAGE_ALIGNMENT_OPTIONS } from "../services/lib/surveys/constants";

import calculateSurveyTimeToComplete from "../util/calculateSurveyTimeToComplete";
import { useSurveys } from "../services/hooks/useSurveys";

export default function SurveyContentWelcomeScreen(props) {
  const { content, image, imageAlignment, calculateTimeToComplete } = props;
  const { activeSurvey } = useSurveys();

  if (image.src) {
    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.TOP) {
      return (
        <div className="survey-content">
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-img">
              <img src={image.src} />
            </div>

            <div className="survey-content-td">
              {content.text && <h6>{content.text}</h6>}

              {content.description && (
                <p className="text-muted">{content.description}</p>
              )}
            </div>

            {calculateTimeToComplete && (
              <div className="mt-3 text-muted">
                <i className="fa fa-clock" />{" "}
                <span>
                  Takes{" "}
                  {calculateSurveyTimeToComplete({ survey: activeSurvey })} min
                </span>
              </div>
            )}
          </div>
        </div>
      );
    }

    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.MIDDLE) {
      return (
        <div className="survey-content">
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-td">
              {content.text && <h6>{content.text}</h6>}

              {content.description && (
                <p className="text-muted">{content.description}</p>
              )}
            </div>

            <div className="survey-content-img">
              <img src={image.src} />
            </div>

            {calculateTimeToComplete && (
              <div className="mt-3 text-muted">
                <i className="fa fa-clock" />{" "}
                <span>
                  Takes{" "}
                  {calculateSurveyTimeToComplete({ survey: activeSurvey })} min
                </span>
              </div>
            )}
          </div>
        </div>
      );
    }

    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.LEFT) {
      return (
        <div className="survey-content">
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-img">
              <img src={image.src} />
            </div>

            <div className="survey-content-td-left">
              <div className="survey-content-td">
                {content.text && <h6>{content.text}</h6>}

                {content.description && (
                  <p className="text-muted">{content.description}</p>
                )}
              </div>

              {calculateTimeToComplete && (
                <div className="mt-3 text-muted">
                  <i className="fa fa-clock" />{" "}
                  <span>
                    Takes{" "}
                    {calculateSurveyTimeToComplete({ survey: activeSurvey })}{" "}
                    min
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.RIGHT) {
      return (
        <div className="survey-content">
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-td-right">
              <div className="survey-content-td">
                {content.text && <h6>{content.text}</h6>}

                {content.description && (
                  <p className="text-muted">{content.description}</p>
                )}
              </div>

              {calculateTimeToComplete && (
                <div className="mt-3 text-muted">
                  <i className="fa fa-clock" />{" "}
                  <span>
                    Takes{" "}
                    {calculateSurveyTimeToComplete({ survey: activeSurvey })}{" "}
                    min
                  </span>
                </div>
              )}
            </div>

            <div className="survey-content-img">
              <img src={image.src} />
            </div>
          </div>
        </div>
      );
    }
  }
  return (
    <div className="survey-content">
      <div className="survey-content-td-img">
        <div className="survey-content-td">
          {content.text && <h6>{content.text}</h6>}

          {content.description && (
            <p className="text-muted">{content.description}</p>
          )}
        </div>

        {calculateTimeToComplete && (
          <div className="mt-3 text-muted">
            <i className="fa fa-clock" />{" "}
            <span>
              Takes {calculateSurveyTimeToComplete({ survey: activeSurvey })}{" "}
              min
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
