import { useEffect, useState } from "react";
import { useApiCall } from "./useApiCall";

export const useSaveSurveyResponse = () => {
  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();
  const [saveSurveyResponseStatus, setSaveSurveyResponseStatus] =
    useState("idle");
  const [savedSurveyResponse, setSavedSurveyResponse] = useState();
  const [saveSurveyResponseError, setSaveSurveyResponseError] = useState();

  const saveSurveyResponse = (data) => {
    setSaveSurveyResponseError();
    setSavedSurveyResponse();

    const requestOptions = {
      url: `/surveys/responses`,
      method: "POST",
      data,
    };

    setSaveSurveyResponseStatus("pending");
    executeApiCall(requestOptions);
  };

  const resetSaveSurveyResponse = () => {
    setSaveSurveyResponseStatus("idle");
    setSavedSurveyResponse();
    setSaveSurveyResponseError();
  };

  useEffect(() => {
    switch (apiCallStatus) {
      case "success":
        if (apiCallResponse.data.error) {
          setSaveSurveyResponseError(apiCallResponse.data.error.userMessage);
          setSaveSurveyResponseStatus("ready");
        } else {
          setSaveSurveyResponseError();

          if (apiCallResponse.data.data) {
            const { response } = apiCallResponse.data.data;

            setSaveSurveyResponseStatus("ready");
            setSavedSurveyResponse(response);
          }
        }
        break;
      case "error":
        setSaveSurveyResponseStatus("ready");
    }
  }, [apiCallStatus, apiCallResponse]);

  return {
    saveSurveyResponse,
    savedSurveyResponse,
    saveSurveyResponseError,
    saveSurveyResponseStatus,
    resetSaveSurveyResponse,
  };
};
