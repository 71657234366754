/**
 * Error Codes
 * All UI error codes are to be prefixed with "UI" to indicate they are
 * client errors and not server or api errors
 *
 * 1000 - 2000 : Indicates network errors
 * 2000 - 3000: Validation Errors
 * @param {*} code
 */

export default function errorCodes(code) {
  switch (code) {
    case "UI-1001":
      return {
        message:
          "An network error occurred. The user might not have access to an internet connection. The error could also have occurred because of the application could not handle the associated data.",
        type: "NetworkError",
        rpc: "NETWORK_ERROR",
        code,
        retry: false,
        shortString: "network_error",
        userMessage: "A network error occurred",
        userTitle: "Network Error",
      };
    case "UI-2001":
      return {
        message:
          "The payload provided could not be validated. Some invalid arguments must have been provided.",
        type: "ValidationError",
        rpc: "INVALID_ARGUMENT",
        code,
        retry: false,
        shortString: "validation_error",
        userMessage: "A validation error occurred",
        userTitle: "Validation Error",
      };
    default:
      return {
        message:
          "An unexpected error occurred. There is no explanation for this type of error. Assume something weird happened.",
        type: "UnexpectedError",
        rpc: "APPLICATION_ERROR",
        code,
        retry: true,
        shortString: "unexpected_error",
        userMessage: "Oops! Looks like something went wrong",
        userTitle: "Application Error",
      };
  }
}
