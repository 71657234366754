import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../state/contexts/authState";
import { useApiCall } from "./useApiCall";
import isValidUser from "../../util/isValidUser";
import isValidToken from "../../util/isValidToken";

export const useLogin = () => {
  const { setUser, setAccessToken } = useContext(AuthContext);
  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();
  const [loginError, setLoginError] = useState();
  const [loginStatus, setLoginStatus] = useState("idle");

  const login = (data) => {
    const requestOptions = {
      url: "/auth/authenticate",
      data,
      method: "POST",
    };

    executeApiCall(requestOptions);

    setLoginStatus("pending");
    setLoginError();
  };

  useEffect(() => {
    switch (apiCallStatus) {
      case "success": {
        if (apiCallResponse?.data?.data) {
          const { user, token } = apiCallResponse?.data?.data;

          setLoginError();

          if (isValidUser(user)) {
            setUser({
              user,
            });
          }

          if (isValidToken(token)) {
            setAccessToken({
              accessToken: token,
            });
          }
        } else {
          setLoginError(apiCallResponse?.data?.error?.userMessage);
        }

        setLoginStatus("ready");

        break;
      }

      case "error": {
        setLoginStatus("ready");
      }
    }
  }, [apiCallResponse, apiCallStatus]);

  return {
    login,
    loginError,
    loginStatus,
  };
};
