import { ACTIONS } from "../contexts/accountState";

const accountReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_STATE: {
      return action.payload;
    }

    case ACTIONS.SET_TOTAL_REFERRALS: {
      const { totalReferrals } = action.payload;

      return { ...state, totalReferrals };
    }

    case ACTIONS.SET_PERCENT_COMPLETE: {
      const { preferencesPercentComplete } = action.payload;

      return { ...state, preferencesPercentComplete };
    }

    case ACTIONS.SET_LOYALTY_POINTS: {
      const { loyaltyPoints } = action.payload;

      return { ...state, loyaltyPoints };
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
