import React, { useState, useEffect } from "react";
import Notice from "./Notice";
import { useCookie } from "../services/hooks/useCookie";
import { useTimeout } from "../services/hooks/useTimeout";
import { useForm } from "react-hook-form";
import { useAddNewsletterSubscriber } from "../services/hooks/useAddNewsletterSubscriber";
import { joiResolver } from "../services/validations/resolvers/joiResolver";
import { useRecaptcha } from "../services/hooks/useRecaptcha";
import schema from "../services/validations/newsletterSignUpSchema";
import openModal from "../util/openModal";
import closeModals from "../util/closeModals";

export default function NewsletterModal() {
  const { getCookie, setCookie } = useCookie();

  const {
    addNewsletterSubscriber,
    addedNewsletterSubscriber,
    addNewsletterSubscriberError,
    addingNewsletterSubscriberStatus,
  } = useAddNewsletterSubscriber();

  const { handleSubmit, register, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: joiResolver(schema),
  });

  const { executeRecaptcha, isSafeRequest, recaptchaStatus } = useRecaptcha();

  const [hasAcceptedNewsletterInvite, setHasSignedUpForNewsletterInvite] =
    useState(false);
  const [hasRejectedNewsletterInvite, setHasRejectedNewsletterInvite] =
    useState(false);

  const [isEligibleToDisplayInvitation, setIsEligibleToDisplayInvitation] =
    useState(false);

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [contactInfo, setContactInfo] = useState();

  const NEWSLETTER_INVITATION_VIEWED_COOKIE_NAME = "cbb-v-nl"; //Chic Beauty Box - View - Newsletter
  const NEWSLETTER_INVITATION_REJECTED_COOKIE_NAME = "cbb-r-nl"; //Chic Beauty Box - Reject - Newsletter
  const NEWSLETTER_INVITATION_ACCEPTED_COOKIE_NAME = "cbb-a-nl"; //Chic Beauty Box - Accepted - Newsletter

  const TIME_TO_ELAPSE_BEFORE_SHOWING_INVITATION_IN_MS = 45000; //45 secs

  const { startTimeout, stopTimeout } = useTimeout(
    () => setIsEligibleToDisplayInvitation(true),
    TIME_TO_ELAPSE_BEFORE_SHOWING_INVITATION_IN_MS
  );

  const handleRejection = (e) => {
    e.preventDefault();

    setHasRejectedNewsletterInvite(true);

    setCookie(
      NEWSLETTER_INVITATION_REJECTED_COOKIE_NAME,
      JSON.stringify({ success: true }),
      30
    );

    closeModals("#newsletter-modal");
  };

  const onSubmit = (data) => {
    executeRecaptcha("add_to_newsletter_request");

    setContactInfo(data);
  };

  useEffect(() => {
    const rejectionCookie = getCookie(
      NEWSLETTER_INVITATION_REJECTED_COOKIE_NAME
    );

    const acceptanceCookie = getCookie(
      NEWSLETTER_INVITATION_ACCEPTED_COOKIE_NAME
    );

    if (rejectionCookie) {
      setHasRejectedNewsletterInvite(
        typeof rejectionCookie === "string"
          ? JSON.parse(rejectionCookie).success
          : rejectionCookie.success
      );
    }

    if (acceptanceCookie) {
      setHasSignedUpForNewsletterInvite(
        typeof acceptanceCookie === "string"
          ? JSON.parse(acceptanceCookie).success
          : acceptanceCookie.success
      );
    }

    startTimeout();

    return () => stopTimeout();
  }, []);

  useEffect(() => {
    if (
      !hasRejectedNewsletterInvite &&
      !hasAcceptedNewsletterInvite &&
      isEligibleToDisplayInvitation
    ) {
      openModal("#newsletter-modal");

      setCookie(
        NEWSLETTER_INVITATION_VIEWED_COOKIE_NAME,
        JSON.stringify({ success: true }),
        365
      );
    }
  }, [
    hasAcceptedNewsletterInvite,
    hasRejectedNewsletterInvite,
    isEligibleToDisplayInvitation,
  ]);

  useEffect(() => {
    if (recaptchaStatus === "ready" && isSafeRequest && contactInfo) {
      //send newsletter data
      addNewsletterSubscriber(contactInfo);
    }
  }, [recaptchaStatus]);

  useEffect(() => {
    setIsSubmittingForm(
      addingNewsletterSubscriberStatus === "pending" ||
        recaptchaStatus === "pending" ||
        (recaptchaStatus === "ready" &&
          addingNewsletterSubscriberStatus === "idle")
    );
  }, [recaptchaStatus, addingNewsletterSubscriberStatus]);

  useEffect(() => {
    if (addedNewsletterSubscriber === true) {
      setCookie(
        NEWSLETTER_INVITATION_ACCEPTED_COOKIE_NAME,
        JSON.stringify({ success: true }),
        730
      );

      closeModals("#newsletter-modal");
    }
  }, [addedNewsletterSubscriber]);

  return (
    <div
      className="modal fade"
      id="newsletter-modal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          {/* Close */}
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="fe fe-x" aria-hidden="true" />
          </button>
          {/* Content */}
          <div className="row no-gutters">
            <div className="col-12 d-flex flex-column px-md-8">
              {/* Body */}
              <div className="modal-body my-auto py-5 text-center">
                {/* Heading */}
                <h4 className="text-uppercase">
                  Most Emails Suck,
                  <br />
                  <span className="text-primary">Ours Don't!</span>
                </h4>
                {/* Text */}
                <p className="mb-7 font-size-lg">
                  Get insights, sneak peaks, special offers and more.
                </p>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    {addNewsletterSubscriberError && (
                      <div className="col-12">
                        <Notice
                          message={addNewsletterSubscriberError}
                          type="danger"
                        />
                      </div>
                    )}
                    <div className="col-12">
                      {/* Input */}
                      <label className="sr-only" htmlFor="newsletter-email">
                        Enter your email here
                      </label>
                      <input
                        ref={register}
                        className="form-control form-control-sm"
                        id="newsletter-email"
                        type="email"
                        name="email"
                        placeholder="Enter your email here"
                      />

                      {errors.email && (
                        <span className="input-error">
                          Please enter a valid email address
                        </span>
                      )}
                    </div>
                    <div className="col-12 mt-5 text-uppercase">
                      <button
                        type="submit"
                        className="btn btn-dark btn-block"
                        disabled={isSubmittingForm}
                      >
                        {isSubmittingForm ? "Please wait..." : "YES, PLEASE!"}
                      </button>
                    </div>
                    <div className="col-12 mt-2 text-center">
                      <a
                        href="#"
                        className="styled-underlined-link"
                        onClick={handleRejection}
                      >
                        I don't like good things
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
