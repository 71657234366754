import { ACTIONS } from "../contexts/surveysState";
import setActiveContentResponse from "./functions/surveys/setActiveContentResponse";
import setActiveContentOptionIsSelected from "./functions/surveys/setActiveContentOptionIsSelected";

const surveysReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_STATE: {
      return action.payload;
    }
    case ACTIONS.SET_SURVEYS: {
      const { surveys } = action.payload;

      return { ...state, surveys };
    }

    case ACTIONS.SET_CANDIDATES: {
      const { candidates } = action.payload;

      return { ...state, candidates };
    }

    case ACTIONS.SET_SURVEY_CONTENT_ERROR: {
      const { surveyContentError } = action.payload;

      return { ...state, surveyContentError };
    }

    case ACTIONS.SET_ACTIVE_SURVEY_ID: {
      const { activeSurveyId } = action.payload;

      return { ...state, activeSurveyId };
    }

    case ACTIONS.SET_ACTIVE_SURVEY: {
      const { activeSurvey } = action.payload;

      return { ...state, activeSurvey };
    }

    case ACTIONS.SET_SHOW_SURVEY_SIDE_OUT: {
      const { showSurveySideOut } = action.payload;

      return { ...state, showSurveySideOut };
    }

    case ACTIONS.SET_CURRENT_STEP: {
      const { currentStep } = action.payload;

      return { ...state, currentStep };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_ID: {
      const { activeContentId } = action.payload;

      return { ...state, activeContentId };
    }

    case ACTIONS.SET_ACTIVE_CONTENT: {
      const { activeContent } = action.payload;

      return { ...state, activeContent };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_RESPONSE: {
      const { response } = action.payload;

      const activeSurvey = setActiveContentResponse({
        survey: state.activeSurvey,
        activeContentId: state.activeContent.id,
        response,
      });

      return { ...state, activeSurvey };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_OPTION_IS_SELECTED: {
      const { optionId, isSelected } = action.payload;

      const activeSurvey = setActiveContentOptionIsSelected({
        survey: state.activeSurvey,
        activeContentId: state.activeContent.id,
        optionId,
        isSelected,
      });

      return { ...state, activeSurvey };
    }

    case ACTIONS.SET_SHOULD_SHOW_SURVEY_COUNTDOWN_TIMER: {
      const { shouldShowSurveyCountdownTimer } = action.payload;

      return { ...state, shouldShowSurveyCountdownTimer };
    }

    case ACTIONS.SET_SURVEY_START_TIME: {
      const { surveyStartTime } = action.payload;

      return { ...state, surveyStartTime };
    }

    case ACTIONS.SET_SURVEY_END_TIME: {
      const { surveyEndTime } = action.payload;

      return { ...state, surveyEndTime };
    }

    default: {
      return state;
    }
  }
};

export default surveysReducer;
