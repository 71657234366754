import { useState, useEffect } from "react";
import { useCookie } from "./useCookie";

export const useTimezone = () => {
  const [timezone, setTimezone] = useState(
    Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : ""
  );
  const { setCookie } = useCookie();

  useEffect(() => {
    if (timezone) {
      setCookie("tz", timezone, 30);
    }
  }, [timezone]);

  return {
    timezone,
    setTimezone,
  };
};
