"use strict";

export const enableXssWarning = () => {
  //XSS Warning
  if (navigator && navigator.userAgent) {
    var userAgent = navigator.userAgent.match(
      /opera|chrome|safari|firefox|msie|trident(?=\/)/i
    );

    if (userAgent && userAgent[0].search(/trident|msie/i) < 0) {
      window.console.log(
        "%cSTOP!",
        "color:red;font-size:xx-large;font-weight:bold;"
      ),
        void window.console.log(
          "%cThis is a browser feature intended for developers. Do not enter or paste code which you don't understand. It may allow attackers to steal your information or impersonate you.\nSee https://en.wikipedia.org/wiki/Self-XSS for more details",
          "font-size:large;"
        );
    }
  } else {
    window.console.log(
      "STOP!\nThis is a browser feature intended for developers. Do not enter or paste code which you don't understand. It may allow attackers to steal your information or impersonate you.\nSee https://en.wikipedia.org/wiki/Self-XSS for more details"
    );
  }
};

export const enableTooltip = (
  selector = '[data-toggle="tooltip"]',
  options = {}
) => {
  $(selector).tooltip(options);
};

export const disposeTooltip = (selector) => {
  if (!selector) {
    return;
  }

  $(selector).tooltip("dispose");
};

export const enablePopover = function (
  selector = '[data-toggle="popover"]',
  options = {}
) {
  $(selector).popover(options);
};

export const enableCardCollapse = () => {
  var $toggle = $('[data-toggle="card-collapse"]');
  var $collapse = $(".card-collapse");

  $toggle.on({
    mouseenter: function () {
      var $this = $(this);
      var $collapse = $this.find(".card-collapse");

      $collapse.collapse("show");
    },
    mouseleave: function () {
      var $this = $(this);
      var $collapse = $this.find(".card-collapse");
      var isCollapsing = $collapse.hasClass("collapsing");

      if (isCollapsing) {
        setTimeout(function () {
          $collapse.collapse("hide");
        }, 350);
      } else {
        $collapse.collapse("hide");
      }
    },
  });

  $collapse.on({
    "show.bs.collapse": function () {
      var $this = $(this);
      var $parent = $this.closest(".card-collapse-parent");
      var collapseHeight = $this.outerHeight(true);

      $parent.css({
        "-webkit-transform": "translateY(-" + collapseHeight + "px)",
        transform: "translateY(-" + collapseHeight + "px)",
      });
    },
    "hide.bs.collapse": function () {
      var $this = $(this);
      var $parent = $this.closest(".card-collapse-parent");

      $parent.css({
        "-webkit-transform": "",
        transform: "",
      });
    },
  });
};

export const enableCollapse = () => {
  var $toggle = $('[data-toggle="collapse"][data-action]');

  $toggle.on("click", function (e) {
    e.stopPropagation();

    var $this = $(this);
    var action = $this.data("action");
    var target = $this.data("target");
    var $target = $(target);

    $target.collapse(action);
  });
};

export const configureFlickity = () => {
  // Flickity defaults
  Flickity.defaults.pageDots = false;
  Flickity.defaults.prevNextButtons = false;
  Flickity.defaults.imagesLoaded = true;
  Flickity.defaults.initialIndex = 0;
  Flickity.defaults.wrapAround = true;
  Flickity.defaults.cellAlign = "left";

  //Toggle
  var $toggle = $('[data-toggle="flickity"]');

  $toggle.on("click", function () {
    var $this = $(this);
    var slide = $this.data("slide");
    var target = $this.data("target");
    var $target = $(target);

    $target.flickity("select", slide, true, true);
  });

  // iOS temporary fix fix

  (function () {
    var touchingCarousel = false,
      touchStartCoords;

    document.body.addEventListener("touchstart", function (e) {
      if (e.target.closest(".flickity-slider")) {
        touchingCarousel = true;
      } else {
        touchingCarousel = false;
        return;
      }

      touchStartCoords = {
        x: e.touches[0].pageX,
        y: e.touches[0].pageY,
      };
    });

    document.body.addEventListener(
      "touchmove",
      function (e) {
        if (!(touchingCarousel && e.cancelable)) {
          return;
        }

        var moveVector = {
          x: e.touches[0].pageX - touchStartCoords.x,
          y: e.touches[0].pageY - touchStartCoords.y,
        };

        if (Math.abs(moveVector.x) > 7) e.preventDefault();
      },
      {
        passive: false,
      }
    );
  })();
};

export const enableFlickity = (selector, options = {}) => {
  return new Flickity(selector, options);
};
