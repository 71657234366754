import React, { useState } from "react";

import { IMAGE_ALIGNMENT_OPTIONS } from "../services/lib/surveys/constants";

export default function SurveyContentLongText(props) {
  const {
    content,
    image,
    imageAlignment,
    response,
    maxCharacters,
    error,
    onResponse,
  } = props;

  const [characters, setCharacters] = useState(0);

  const handleResponseChange = (e) => {
    if (
      maxCharacters.shouldLimitMaxCharacters &&
      typeof maxCharacters.limit === "number"
    ) {
      if (e.target.value.length > maxCharacters.limit) {
        return;
      }
    }

    onResponse(e.target.value);

    setCharacters(e.target.value.length);
  };

  const handleTextAreaInput = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  if (image.src) {
    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.TOP) {
      return (
        <div className="survey-content">
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-img">
              <img src={image.src} />
            </div>

            <div className="survey-content-td">
              {content.text && <h6>{content.text}</h6>}

              {content.description && (
                <p className="text-muted">{content.description}</p>
              )}
            </div>

            <div className="survey-content-input">
              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder="Type your answer here..."
                  value={response ?? ""}
                  rows={1}
                  maxLength={
                    maxCharacters?.shouldLimitMaxCharacters
                      ? typeof maxCharacters.limit === "number"
                        ? maxCharacters.limit
                        : ""
                      : ""
                  }
                  onChange={handleResponseChange}
                  onInput={handleTextAreaInput}
                />

                <span
                  className={`d-block float-right text-muted ${
                    typeof maxCharacters.limit === "number" &&
                    characters > maxCharacters.limit
                      ? "input-error"
                      : ""
                  }`}
                >
                  {characters ? characters : ""}{" "}
                  {maxCharacters?.shouldLimitMaxCharacters
                    ? typeof maxCharacters.limit === "number"
                      ? `/ ${maxCharacters.limit}`
                      : ""
                    : ""}
                </span>

                {error && <span className="input-error">{error}</span>}
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.MIDDLE) {
      return (
        <div className="survey-content">
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-td">
              {content.text && <h6>{content.text}</h6>}

              {content.description && (
                <p className="text-muted">{content.description}</p>
              )}
            </div>

            <div className="survey-content-img">
              <img src={image.src} />
            </div>

            <div className="survey-content-input">
              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder="Type your answer here..."
                  value={response ?? ""}
                  rows={1}
                  maxLength={
                    maxCharacters?.shouldLimitMaxCharacters
                      ? typeof maxCharacters.limit === "number"
                        ? maxCharacters.limit
                        : ""
                      : ""
                  }
                  onChange={handleResponseChange}
                  onInput={handleTextAreaInput}
                />

                <span
                  className={`d-block float-right text-muted ${
                    typeof maxCharacters.limit === "number" &&
                    characters > maxCharacters.limit
                      ? "input-error"
                      : ""
                  }`}
                >
                  {characters ? characters : ""}{" "}
                  {maxCharacters?.shouldLimitMaxCharacters
                    ? typeof maxCharacters.limit === "number"
                      ? `/ ${maxCharacters.limit}`
                      : ""
                    : ""}
                </span>

                {error && <span className="input-error">{error}</span>}
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.LEFT) {
      return (
        <div className="survey-content">
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-img">
              <img src={image.src} />
            </div>

            <div className="survey-content-td-left">
              <div className="survey-content-td">
                {content.text && <h6>{content.text}</h6>}

                {content.description && (
                  <p className="text-muted">{content.description}</p>
                )}
              </div>

              <div className="survey-content-input">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder="Type your answer here..."
                    value={response ?? ""}
                    rows={1}
                    maxLength={
                      maxCharacters?.shouldLimitMaxCharacters
                        ? typeof maxCharacters.limit === "number"
                          ? maxCharacters.limit
                          : ""
                        : ""
                    }
                    onChange={handleResponseChange}
                    onInput={handleTextAreaInput}
                  />

                  <span
                    className={`d-block float-right text-muted ${
                      typeof maxCharacters.limit === "number" &&
                      characters > maxCharacters.limit
                        ? "input-error"
                        : ""
                    }`}
                  >
                    {characters ? characters : ""}{" "}
                    {maxCharacters?.shouldLimitMaxCharacters
                      ? typeof maxCharacters.limit === "number"
                        ? `/ ${maxCharacters.limit}`
                        : ""
                      : ""}
                  </span>

                  {error && <span className="input-error">{error}</span>}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.RIGHT) {
      return (
        <div className="survey-content">
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-td-right">
              <div className="survey-content-td">
                {content.text && <h6>{content.text}</h6>}

                {content.description && (
                  <p className="text-muted">{content.description}</p>
                )}
              </div>

              <div className="survey-content-input">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder="Type your answer here..."
                    value={response ?? ""}
                    rows={1}
                    maxLength={
                      maxCharacters?.shouldLimitMaxCharacters
                        ? typeof maxCharacters.limit === "number"
                          ? maxCharacters.limit
                          : ""
                        : ""
                    }
                    onChange={handleResponseChange}
                    onInput={handleTextAreaInput}
                  />

                  <span
                    className={`d-block float-right text-muted ${
                      typeof maxCharacters.limit === "number" &&
                      characters > maxCharacters.limit
                        ? "input-error"
                        : ""
                    }`}
                  >
                    {characters ? characters : ""}{" "}
                    {maxCharacters?.shouldLimitMaxCharacters
                      ? typeof maxCharacters.limit === "number"
                        ? `/ ${maxCharacters.limit}`
                        : ""
                      : ""}
                  </span>

                  {error && <span className="input-error">{error}</span>}
                </div>
              </div>
            </div>

            <div className="survey-content-img">
              <img src={image.src} />
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="survey-content">
      <div className="survey-content-td-img">
        <div className="survey-content-td">
          {content.text && <h6>{content.text}</h6>}

          {content.description && (
            <p className="text-muted">{content.description}</p>
          )}
        </div>

        <div className="survey-content-input">
          <div className="form-group">
            <textarea
              className="form-control"
              placeholder="Type your answer here..."
              value={response ?? ""}
              rows={1}
              maxLength={
                maxCharacters?.shouldLimitMaxCharacters
                  ? typeof maxCharacters.limit === "number"
                    ? maxCharacters.limit
                    : ""
                  : ""
              }
              onChange={handleResponseChange}
              onInput={handleTextAreaInput}
            />

            <span
              className={`d-block float-right text-muted ${
                typeof maxCharacters.limit === "number" &&
                characters > maxCharacters.limit
                  ? "input-error"
                  : ""
              }`}
            >
              {characters ? characters : ""}{" "}
              {maxCharacters?.shouldLimitMaxCharacters
                ? typeof maxCharacters.limit === "number"
                  ? `/ ${maxCharacters.limit}`
                  : ""
                : ""}
            </span>

            {error && <span className="input-error">{error}</span>}
          </div>
        </div>
      </div>
    </div>
  );
}
