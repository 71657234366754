import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";

const time = dayjs;

time.extend(utc);
time.extend(timezone);
time.extend(relativeTime);

export default time;
