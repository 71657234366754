import { useState, useEffect } from "react";
import { useApiCall } from "./useApiCall";

export const useRecaptcha = () => {
  const [recaptcha, setRecaptcha] = useState();
  const [recaptchaStatus, setRecaptchaStatus] = useState("idle");
  const [recaptchaError, setRecaptchaError] = useState();
  const [isSafeRequest, setIsSafeRequest] = useState();

  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();

  const executeRecaptcha = async (action) => {
    try {
      setRecaptchaStatus("pending");

      const token = await recaptcha.execute(
        process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY,
        { action: action ?? "CBB Client Request" }
      );

      const requestOptions = {
        url: "/auth/recaptcha",
        data: { token },
        method: "POST",
      };

      executeApiCall(requestOptions);
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    if (typeof grecaptcha !== "undefined") {
      setRecaptcha(grecaptcha);
    }
  }, []);

  useEffect(() => {
    switch (apiCallStatus) {
      case "success":
        if (apiCallResponse) {
          const { data: requestData } = apiCallResponse;

          const { error, success, data } = requestData;

          if (error) {
            setRecaptchaError(error.userMessage);
          }

          if (success === true) {
            setRecaptchaError("");

            const { safeRequest } = data;

            setIsSafeRequest(!!safeRequest);
          }

          setRecaptchaStatus("ready");
        }
        break;
    }
  }, [apiCallResponse, apiCallStatus]);

  return {
    executeRecaptcha,
    recaptchaError,
    recaptchaStatus,
    isSafeRequest,
  };
};
