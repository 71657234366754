import React from "react";
import { IMAGE_ALIGNMENT_OPTIONS } from "../services/lib/surveys/constants";

export default function SurveyContentMultipleChoice(props) {
  const {
    content,
    image,
    imageAlignment,
    allowMultipleSelection,
    options,
    error,
    onSelectOption,
  } = props;

  const handleSelectOption = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();

    const optionId = e.target.dataset.optionId;

    onSelectOption({
      optionId,
      isSelected: e.target.checked ?? true,
    });
  };

  if (image.src) {
    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.TOP) {
      return (
        <div className="survey-content">
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-img">
              <img src={image.src} />
            </div>

            <div className="survey-content-td">
              {content.text && <h6>{content.text}</h6>}

              {content.description && (
                <p className="text-muted">{content.description}</p>
              )}
            </div>

            <div className="survey-content-multiple-choice">
              {allowMultipleSelection && (
                <p className="text-muted">Select all that apply</p>
              )}

              {options.map((option) => (
                <div
                  key={option.id}
                  className="custom-control custom-control-color mb-5"
                  data-option-id={option.id}
                  onClick={handleSelectOption}
                >
                  <input
                    type={allowMultipleSelection ? "checkbox" : "radio"}
                    className="custom-control-input"
                    name="multiple-choice"
                    data-option-id={option.id}
                    id={`${option.id}-scmc`}
                    disabled={!option.isSelectable}
                    checked={option.isSelected}
                    onChange={handleSelectOption}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`${option.id}-scmc`}
                    style={{ color: option.isSelected ? "#fc90bd" : "#dedcdc" }}
                  >
                    <span className="text-gray-450">{option.value}</span>
                  </label>
                </div>
              ))}

              {error && <span className="input-error">{error}</span>}
            </div>
          </div>
        </div>
      );
    }

    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.MIDDLE) {
      return (
        <div className="survey-content">
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-td">
              {content.text && <h6>{content.text}</h6>}

              {content.description && (
                <p className="text-muted">{content.description}</p>
              )}
            </div>

            <div className="survey-content-img">
              <img src={image.src} />
            </div>

            <div className="survey-content-multiple-choice">
              {allowMultipleSelection && (
                <p className="text-muted">Select all that apply</p>
              )}

              {options.map((option) => (
                <div
                  key={option.id}
                  className="custom-control custom-control-color mb-5"
                  data-option-id={option.id}
                  onClick={handleSelectOption}
                >
                  <input
                    type={allowMultipleSelection ? "checkbox" : "radio"}
                    className="custom-control-input"
                    name="multiple-choice"
                    data-option-id={option.id}
                    id={`${option.id}-scmc`}
                    disabled={!option.isSelectable}
                    checked={option.isSelected}
                    onChange={handleSelectOption}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`${option.id}-scmc`}
                    style={{ color: option.isSelected ? "#fc90bd" : "#dedcdc" }}
                  >
                    <span className="text-gray-450">{option.value}</span>
                  </label>
                </div>
              ))}

              {error && <span className="input-error">{error}</span>}
            </div>
          </div>
        </div>
      );
    }

    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.LEFT) {
      return (
        <div className="survey-content">
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-img">
              <img src={image.src} />
            </div>

            <div className="survey-content-td-left">
              <div className="survey-content-td">
                {content.text && <h6>{content.text}</h6>}

                {content.description && (
                  <p className="text-muted">{content.description}</p>
                )}
              </div>

              <div className="survey-content-multiple-choice">
                {allowMultipleSelection && (
                  <p className="text-muted">Select all that apply</p>
                )}

                {options.map((option) => (
                  <div
                    key={option.id}
                    className="custom-control custom-control-color mb-5"
                    data-option-id={option.id}
                    onClick={handleSelectOption}
                  >
                    <input
                      type={allowMultipleSelection ? "checkbox" : "radio"}
                      className="custom-control-input"
                      name="multiple-choice"
                      data-option-id={option.id}
                      id={`${option.id}-scmc`}
                      disabled={!option.isSelectable}
                      checked={option.isSelected}
                      onChange={handleSelectOption}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`${option.id}-scmc`}
                      style={{
                        color: option.isSelected ? "#fc90bd" : "#dedcdc",
                      }}
                    >
                      <span className="text-gray-450">{option.value}</span>
                    </label>
                  </div>
                ))}

                {error && <span className="input-error">{error}</span>}
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (imageAlignment === IMAGE_ALIGNMENT_OPTIONS.RIGHT) {
      return (
        <div className="survey-content">
          <div className={`survey-content-td-img img-${imageAlignment}`}>
            <div className="survey-content-td-right">
              <div className="survey-content-td">
                {content.text && <h6>{content.text}</h6>}

                {content.description && (
                  <p className="text-muted">{content.description}</p>
                )}
              </div>

              <div className="survey-content-multiple-choice">
                {allowMultipleSelection && (
                  <p className="text-muted">Select all that apply</p>
                )}

                {options.map((option) => (
                  <div
                    key={option.id}
                    className="custom-control custom-control-color mb-5"
                    data-option-id={option.id}
                    onClick={handleSelectOption}
                  >
                    <input
                      type={allowMultipleSelection ? "checkbox" : "radio"}
                      className="custom-control-input"
                      name="multiple-choice"
                      data-option-id={option.id}
                      id={`${option.id}-scmc`}
                      disabled={!option.isSelectable}
                      checked={option.isSelected}
                      onChange={handleSelectOption}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`${option.id}-scmc`}
                      style={{
                        color: option.isSelected ? "#fc90bd" : "#dedcdc",
                      }}
                    >
                      <span className="text-gray-450">{option.value}</span>
                    </label>
                  </div>
                ))}

                {error && <span className="input-error">{error}</span>}
              </div>
            </div>

            <div className="survey-content-img">
              <img src={image.src} />
            </div>
          </div>
        </div>
      );
    }
  }
}
