import React, { useEffect, useState, useRef } from "react";
import { CodeInput, getSegmentCssWidth } from "rci";
import { useIsInputFocused } from "../services/hooks/useIsInputFocused";

export default React.memo(function VerifyRecoveryCodeForm({
  onVerifyCode,
  isProcessing,
  recoveryCodeError,
}) {
  const [inputState, setInputState] = useState("input");

  const inputRef = useRef(null);
  const focused = useIsInputFocused(inputRef);

  const PADDING = "10px";
  const WIDTH = getSegmentCssWidth(PADDING);
  const INPUT_LENGTH = 6;

  const getClassName = (base, { focused, state }) => {
    return [base, focused && "-focused", `-state-${state}`]
      .filter((a) => a)
      .join(" ");
  };

  const handleContinue = (recoveryCode) => {
    setInputState("loading");

    onVerifyCode(recoveryCode);
  };

  useEffect(() => {
    if (recoveryCodeError) {
      setInputState("error");

      setTimeout(() => {
        setInputState("input");
        inputRef.current.value = "";
        inputRef.current.dispatchEvent(new Event("input"));
        inputRef.current.focus();
      }, 500);
    }
  }, [recoveryCodeError]);

  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <label htmlFor="code-input">Enter code:</label>

        <CodeInput
          id="code-input"
          className={getClassName("recovery-code-input", {
            focused,
            state: inputState,
          })}
          autoFocus
          length={INPUT_LENGTH}
          disabled={isProcessing}
          inputRef={inputRef}
          padding={PADDING}
          spacing={PADDING}
          spellCheck={false}
          inputMode="numeric"
          pattern="[0-9]*"
          autoComplete="one-time-code"
          onChange={({ currentTarget: input }) => {
            // only accept numbers
            input.value = input.value.replace(/\D+/g, "");

            // auto submit on input fill
            if (input.value.length === INPUT_LENGTH) {
              handleContinue(input.value);
            }
          }}
          renderSegment={({ state, index }) => (
            <div
              key={index}
              className="segment"
              data-state={state}
              style={{ width: WIDTH, height: "100%" }}
              children={<div />}
            />
          )}
        />

        <button
          role="button"
          type="button"
          className="btn btn-sm btn-dark mt-5"
          disabled={isProcessing}
        >
          {isProcessing ? "Please wait..." : "Continue"}
        </button>
      </div>
    </>
  );
});
