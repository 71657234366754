import React from "react";
import { useCart } from "../services/hooks/useCart";
import CartItems from "./CartItems";
import Link from "next/link";
import currency from "currency.js";

export default React.memo(function SidebarCart() {
  //state
  const { cartItems, cartSubTotal } = useCart();

  return (
    <div
      className="modal fixed-right fade"
      id="modalShoppingCart"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-vertical" role="document">
        {cartItems.length ? (
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="fe fe-x" aria-hidden="true" />
            </button>

            <div className="modal-header line-height-fixed font-size-lg">
              <strong className="mx-auto">
                Your Cart ({cartItems.length})
              </strong>
            </div>

            <CartItems />

            <div className="modal-footer line-height-fixed font-size-sm bg-light mt-auto">
              <strong>Subtotal</strong>{" "}
              <strong className="ml-auto">
                {currency(cartSubTotal).format()}
              </strong>
            </div>

            <div className="modal-body">
              <Link href="/checkout">
                <a className="btn btn-block btn-dark">Continue to Checkout</a>
              </Link>
              <Link href="/cart">
                <a className="btn btn-block btn-outline-dark">View Cart</a>
              </Link>
            </div>
          </div>
        ) : (
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="fe fe-x" aria-hidden="true" />
            </button>

            <div className="modal-header line-height-fixed font-size-lg">
              <strong className="mx-auto">
                Your Cart ({cartItems.length})
              </strong>
            </div>

            <div className="modal-body flex-grow-0 my-auto">
              <h6 className="mb-7 text-center">Your cart is empty 😞</h6>

              <Link href="/stores/add-ons">
                <a className="btn btn-block btn-outline-dark">
                  Continue Shopping
                </a>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
