import Joi from "joi";

const USERNAME_REGEX = /^[a-z0-9_-]{3,16}$/im;

const schema = Joi.object({
  id: Joi.number().positive(),
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  phoneNumber: Joi.string()
    .pattern(/^\+[1-9]\d{1,14}$/)
    .allow(null, "")
    .required(),
  customerId: Joi.string().allow(null, "").required(),
  avatar: Joi.object({
    id: Joi.string().required(),
    storageKey: Joi.string().required(),
    src: Joi.string().required(),
    type: Joi.string().required(),
    created: Joi.number().required(),
    lastUpdateTimestamp: Joi.number().required(),
  })
    .allow(null)
    .required(),
  username: Joi.string()
    .pattern(USERNAME_REGEX)
    .lowercase()
    .allow(null, "")
    .required(),
  active: Joi.boolean().required(),
  addresses: Joi.array().required(),
  created: Joi.date().timestamp("unix").required(),
  lastLoginTimestamp: Joi.date().timestamp("unix").required(),
  lastUpdateTimestamp: Joi.date().timestamp("unix").required(),
  orders: Joi.array().required(),
  wishlistId: Joi.string().allow(null, "").required(),
  paymentMethods: Joi.array().required(),
  giftCards: Joi.array().required(),
  subscriptions: Joi.array().required(),
  choices: Joi.array().required(),
  preferenceId: Joi.string().allow(null, "").required(),
  passwordRecovery: Joi.object().required(),
  loyaltyPoints: Joi.number().allow(0).required(),
  loyaltyId: Joi.string().allow(null, "").required(),
});

export default schema;
