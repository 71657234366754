import React, { useRef } from "react";
import { useSearch } from "../services/hooks/useSearch";
import Loader from "./Loader";
import Link from "next/link";
import currency from "currency.js";
import TypeAheadInput from "./TypeAheadInput";

export default function SearchModal() {
  const searchInputRef = useRef();

  const { searchResults, isSearching, hasResults, hasSearched, searchQueries } =
    useSearch(searchInputRef);

  return (
    <>
      {/* Search */}
      <div
        className="modal fixed-right fade"
        id="search-modal"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-vertical" role="document">
          <div className="modal-content">
            {/* Close */}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="fe fe-x" aria-hidden="true" />
            </button>
            {/* Header*/}
            <div className="modal-header line-height-fixed font-size-lg">
              <strong className="mx-auto">Search shop</strong>
            </div>
            {/* Body: Form */}
            <div className="modal-body">
              <TypeAheadInput
                inputRef={searchInputRef}
                className="form-control"
                options={searchQueries}
                placeholder="What would you want to shop for?"
              />
            </div>

            <div
              className={`modal-body ${
                searchResults?.products?.length ? "border-top font-size-sm" : ""
              }`}
            >
              {isSearching ? (
                <Loader />
              ) : hasResults ? (
                <>
                  {/* Heading */}
                  <p>Search Results:</p>
                  {/* Items */}
                  {searchResults.products.map((product) => (
                    <div
                      key={product.id}
                      className="row align-items-center position-relative mb-5"
                    >
                      <div className="col-4 col-md-3">
                        {/* Image */}
                        <img
                          className="img-fluid"
                          src={product.images[0].src}
                          alt={product.name}
                        />
                      </div>
                      <div className="col position-static">
                        {/* Text */}
                        <p className="mb-0">
                          <Link href={`/products/${product.handle}`}>
                            <a className="stretched-link text-body d-block mb-1">
                              <span className="d-block font-weight-bold text-uppercase">
                                {product.brand}
                              </span>
                              <span className="d-block">{product.name}</span>
                            </a>
                          </Link>{" "}
                          {product.compareAtPrice && (
                            <span className="font-size-xs text-gray-350 text-decoration-line-through">
                              {currency(product.compareAtPrice).format()}
                            </span>
                          )}
                          <span className="text-primary">
                            {currency(product.price).format()}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}

                  {/* Button */}
                  <Link href="/stores/add-ons">
                    <a className="btn btn-link px-0 text-reset">
                      Shop all <i className="fe fe-arrow-right ml-2" />
                    </a>
                  </Link>
                </>
              ) : hasSearched ? (
                <>
                  {/* Text */}
                  <p className="mb-1 text-center h1">🔍</p>
                  <p className="mb-0 text-center">
                    Nothing matches your search
                  </p>
                </>
              ) : (
                <>
                  {/* Text */}
                  <p className="mb-1 text-center h1">🔭</p>
                  <p className="mb-0 text-center">
                    Your search results will appear here
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
