import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "../services/validations/resolvers/joiResolver";
import recoveryPasswordEmailSchema from "../services/validations/recoveryPasswordEmailSchema";
import { usePhoneNumberInput } from "../services/hooks/usePhoneNumberInput";

export default React.memo(function RequestRecoveryCodeForm({
  onRequestCode,
  isProcessing,
}) {
  const { handleSubmit, register, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: joiResolver(recoveryPasswordEmailSchema),
  });

  const [hasSelectedOption, setHasSelectedOption] = useState(false);
  const [shouldSendThroughEmail, setShouldSendThroughEmail] = useState(true);

  const { phoneNumberInput, phoneNumber, isPhoneNumberValid } =
    usePhoneNumberInput({
      required: true,
    });

  const handleSendThroughEmail = (e) => {
    e.preventDefault();

    setHasSelectedOption(true);
    setShouldSendThroughEmail(true);
  };

  const handleSendThroughPhone = (e) => {
    e.preventDefault();

    setHasSelectedOption(true);
    setShouldSendThroughEmail(false);
  };

  const handleBack = (e) => {
    e.preventDefault();

    setHasSelectedOption(false);
  };

  const onSubmit = (data) => {
    const { email } = data;

    if (!shouldSendThroughEmail && !isPhoneNumberValid) {
      return;
    }

    const options = {
      email: shouldSendThroughEmail ? email : null,
      phoneNumber: shouldSendThroughEmail ? null : phoneNumber,
    };

    onRequestCode(options);
  };

  if (!hasSelectedOption) {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <button
              className="btn btn-lg btn-outline-dark btn-block"
              type="button"
              role="button"
              onClick={handleSendThroughEmail}
            >
              <i className="fa fa-envelope mr-2"></i> Send code to my email
            </button>{" "}
            <button
              className="btn btn-lg btn-outline-dark btn-block"
              type="button"
              role="button"
              onClick={handleSendThroughPhone}
            >
              <i className="fa fa-phone mr-2"></i> Send code to my phone
            </button>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <a
        href="#"
        className="styled-underlined-link mb-5 float-left"
        onClick={handleBack}
      >
        <i className="fa fa-arrow-left"></i> back
      </a>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label className="sr-only">
            {shouldSendThroughEmail ? "Email" : "Phone Number"}
          </label>
          {shouldSendThroughEmail ? (
            <>
              <input
                ref={register}
                className={`form-control form-control-sm ${
                  errors.email ? "is-invalid" : ""
                }`}
                type="email"
                name="email"
                placeholder="Email address"
              />
              {errors.email && (
                <span className="input-error">
                  Please enter a valid email address
                </span>
              )}
            </>
          ) : (
            phoneNumberInput
          )}
        </div>

        <button
          className="btn btn-sm btn-dark"
          type="submit"
          disabled={isProcessing}
        >
          {isProcessing ? "Sending..." : "Send me the code"}
        </button>
        <span className="text-muted d-block mt-2">
          Standard messages and data rates may apply.
        </span>
      </form>
    </>
  );
});
