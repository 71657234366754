import React, { useEffect, useState } from "react";
import { useApiCall } from "./useApiCall";
import { useAuth } from "./useAuth";

export const useConfirmPassword = () => {
  const { user } = useAuth();
  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();
  const [isConfirmingPassword, setIsConfirmingPassword] = useState(false);
  const [confirmedPassword, setConfirmedPassword] = useState();
  const [confirmPasswordError, setConfirmPasswordError] = useState();

  const confirmPassword = (password) => {
    setConfirmPasswordError();

    if (user) {
      const requestOptions = {
        url: `/auth/confirm-password`,
        method: "POST",
        data: { password },
      };

      setIsConfirmingPassword(true);
      executeApiCall(requestOptions);
    }
  };

  const resetConfirmPassword = () => {
    setIsConfirmingPassword(false);
    setConfirmedPassword();
    setConfirmPasswordError();
  };

  useEffect(() => {
    switch (apiCallStatus) {
      case "success":
        if (apiCallResponse.data.error) {
          setConfirmPasswordError(apiCallResponse.data.error.userMessage);
          setIsConfirmingPassword(false);
        } else {
          setConfirmPasswordError();

          if (apiCallResponse.data.data) {
            const { isConfirmed } = apiCallResponse.data.data;

            setIsConfirmingPassword(false);
            setConfirmedPassword(isConfirmed);
          }
        }
        break;
      case "error":
        setIsConfirmingPassword(false);
    }
  }, [apiCallStatus, apiCallResponse]);

  return {
    isConfirmingPassword,
    confirmPassword,
    confirmedPassword,
    confirmPasswordError,
    resetConfirmPassword,
  };
};
