import roundNumber from "../../../../util/roundNumber";

export default function determineDiscountAmount(
  cartSubTotal,
  shippingFee,
  coupon
) {
  const total = cartSubTotal + shippingFee;

  if (coupon.amount_off) {
    if (total > coupon.amount_off / 100) {
      return coupon.amount_off / 100;
    } else {
      return total;
    }
  }

  if (coupon.percent_off) {
    return roundNumber(total * (coupon.percent_off / 100));
  }

  return 0;
}
