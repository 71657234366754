import storeInLocalStorage from "../../../../util/storeInLocalStorage";

export default function removeItemFromCart(cartItems, payload) {
  cartItems = cartItems.filter(
    (cartItem) => cartItem?.product?.id !== payload.productId
  );

  storeInLocalStorage("cartItems", cartItems);

  return cartItems;
}
