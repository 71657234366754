import persistCartItems from "./persistCartItems";
import assertCartLimits from "./assertCartLimits";
import updateCartItemQty from "./updateCartItemQuantity";

export default function addItemToCart(cartItems, payload) {
  if (!assertCartLimits(cartItems, payload)) {
    return persistCartItems(cartItems);
  }

  const productId = payload?.product?.id ?? payload?.productId;

  /**
   *  This loop ensures that if there exists a
   * subscription item already in the cart, another cannot be added.
   */
  for (const [index, { product }] of cartItems.entries()) {
    if (product?.billingPeriod && payload?.product?.billingPeriod) {
      cartItems[index] = {
        ...payload,
        error: null,
      };

      //Persist cartItems
      return persistCartItems(cartItems);
    }
  }

  /**
   * This ensures that if the product is already in the cart,
   * the product is not readded, instead the quantity is
   * increased by the provided quantity
   */

  for (const { product, quantity } of cartItems) {
    if (product?.id === productId) {
      return updateCartItemQty(cartItems, {
        productId,
        quantity: quantity + payload.quantity,
      });
    }
  }

  cartItems = [{ ...payload, error: null }, ...cartItems];

  //Persist cartItems
  return persistCartItems(cartItems);
}
