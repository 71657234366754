import { SURVEY_CONTENT_TYPES } from "./constants";
export const defaultSurveyEndingScreen = () => {
  return {
    type: SURVEY_CONTENT_TYPES.ENDING_SCREEN,
    content: {
      text: "Thanks for completing this survey.",
      description:
        "If you have any questions about this survey, please contact our customer service team.",
    },
    hasButton: true,
    buttonHasLink: true,
    buttonLink: "https://chicbeautybox.com",
    image: {
      src: null,
      name: null,
      altText: null,
      type: null,
    },
    imageAlignment: "middle",
    ui: {
      admin: {
        icon: "sign-out-alt",
        iconBackgroundColor: "#f8f6f1",
        textPlaceholder: "Say goodbye!",
        descriptionPlaceholder: "Description (optional)",
      },
      client: {},
      button: {
        default: "Go Home",
        text: "Go Home",
        canUpdate: true,
      },
    },
  };
};
