import roundNumber from "../../../../util/roundNumber";
import storeInLocalStorage from "../../../../util/storeInLocalStorage";

export default function getCartSubTotal(cartItems) {
  const cartSubTotal = roundNumber(
    cartItems.reduce(
      (total, { quantity, product }) => total + quantity * product?.price,
      0
    ),
    2
  );

  storeInLocalStorage("cartSubTotal", cartSubTotal);

  return cartSubTotal;
}
