export const updateSnackbar = (snackbars, options) => {
  const { id } = options;

  return snackbars.map((snackbar) => {
    const { id: snackbarId } = snackbar;

    if (id === snackbarId) {
      return { ...options };
    }

    return snackbar;
  });
};
