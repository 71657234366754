export default function getCookie(cname) {
  try {
    if (typeof window !== "undefined") {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(";");

      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];

        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }

        if (c.indexOf(name) == 0) {
          return JSON.parse(c.substring(name.length, c.length));
        }
      }
      return "";
    }
  } catch (e) {
    console.error(`Error reading cookie ${cname}`);
  }
}
