export const useScrollTo = () => {
  const scrollTo = (selector) => {
    const element = document.querySelector(selector);

    const headerOffset =
      document.querySelector("#site-nav-header").offsetHeight;

    const elementRect = element.getBoundingClientRect().top;

    const bodyRect = document.body.getBoundingClientRect().top;

    const elementPosition = elementRect - bodyRect;

    const offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  return {
    scrollTo,
  };
};
