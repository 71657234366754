import React, { createContext, useReducer, useEffect } from "react";
import globalReducer from "../reducers/globalReducer";

//Actions
export const ACTIONS = {
  SET_STATE: "SetState",
  SET_PAGE_TRANSITION: "SetPageTransition",
  SET_GEOLOCATION: "SetGeolocation",
  SHOW_OVERLAY_LOADER: "ShowOverlayLoader",
  HIDE_OVERLAY_LOADER: "HideOverlayLoader",
  START_API_REQUEST: "StartApiRequest",
  COMPLETE_API_REQUEST: "CompleteApiRequest",
  CLEAR_API_REQUESTS: "ClearApiRequests",
  SET_PAGE_DESCRIPTION: "SetPageDescription",
  SET_PAGE_TITLE: "SetPageTitle",
  SET_SHOW_NAV: "SetShowNav",
  SET_REVIEW_ID_TO_REPORT: "SetReviewIdToReport",
  SET_CREATE_SHIPMENT_MODAL_ARGS: "SetCreateShipmentModalArgs",
  SET_IS_SHOWING_CREATE_SHIPMENT_MODAL: "SetIsShowingCreateShipmentModal",
  SET_CONFIRM_PASSWORD_MODAL_ARGS: "SetConfirmPasswordModalArgs",
  SET_CONFIRM_PASSWORD_MODAL_EXECUTION_STATUS:
    "SetConfirmPasswordModalExecutionStatus",
  SET_CONFIRM_ACTION_MODAL_EXECUTION_STATUS:
    "SetConfirmActionModalExecutionStatus",
  SET_IS_SHOWING_CONFIRM_PASSWORD_MODAL: "SetIsShowingConfirmPasswordModal",
  SET_CONFIRM_ACTION_MODAL_ARGS: "SetConfirmActionModalArgs",
  SET_IS_SHOWING_CONFIRM_ACTION_MODAL: "SetIsShowingConfirmActionModal",
};

// Initial state
const initialState = {
  baseUrl: process.env.NEXT_PUBLIC_BASE_URL,
  apiBaseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
  apiUploadUrl: `${process.env.NEXT_PUBLIC_API_BASE_URL}/upload`,
  cdnUrl: process.env.NEXT_PUBLIC_CDN_BASE_URL,
  pageDescription: null,
  pageTitle: null,
  numApiRequestsPending: 0,
  numApiRequestsCompleted: 0,
  transitionColor: "#000",
  loyaltyPointsDollarEquivalent: 0.1,
  geolocation: {},
  reviewIdToReport: null,
  isShowingOverlayLoader: false,
  overlayLoaderMessage: null,
  overlayLoaderSubMessage: null,
  overlayLoaderSubMessageTransitionDelay: null,
  overlayLoaderType: null,
  showNav: true,
  createShipmentModalArgs: {},
  confirmPasswordModalArgs: {},
  confirmActionModalArgs: {},
  isShowingCreateShipmentModal: false,
  isShowingConfirmPasswordModal: false,
  isShowingConfirmActionModal: false,
};

// Create context
export const GlobalContext = createContext(initialState);

// Provider component
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);

  const setPageTransition = (payload) => {
    dispatch({
      type: ACTIONS.SET_PAGE_TRANSITION,
      payload,
    });
  };

  const setGeolocation = (payload) => {
    dispatch({
      type: ACTIONS.SET_GEOLOCATION,
      payload,
    });
  };

  const showOverlayLoader = (payload) => {
    dispatch({
      type: ACTIONS.SHOW_OVERLAY_LOADER,
      payload,
    });
  };

  const hideOverlayLoader = (payload) => {
    dispatch({
      type: ACTIONS.HIDE_OVERLAY_LOADER,
      payload,
    });
  };

  const startApiRequest = (payload) => {
    dispatch({
      type: ACTIONS.START_API_REQUEST,
      payload,
    });
  };

  const completeApiRequest = (payload) => {
    dispatch({
      type: ACTIONS.COMPLETE_API_REQUEST,
      payload,
    });
  };

  const clearApiRequests = (payload) => {
    dispatch({
      type: ACTIONS.CLEAR_API_REQUESTS,
      payload,
    });
  };

  const setPageDescription = (payload) => {
    dispatch({
      type: ACTIONS.SET_PAGE_DESCRIPTION,
      payload,
    });
  };

  const setPageTitle = (payload) => {
    dispatch({
      type: ACTIONS.SET_PAGE_TITLE,
      payload,
    });
  };

  const resetGlobal = () => {
    dispatch({
      type: ACTIONS.SET_STATE,
      payload: initialState,
    });
  };

  const setShowNav = (payload) => {
    dispatch({
      type: ACTIONS.SET_SHOW_NAV,
      payload,
    });
  };

  const setReviewIdToReport = (payload) => {
    dispatch({
      type: ACTIONS.SET_REVIEW_ID_TO_REPORT,
      payload,
    });
  };

  const setCreateShipmentModalArgs = (payload) => {
    dispatch({
      type: ACTIONS.SET_CREATE_SHIPMENT_MODAL_ARGS,
      payload,
    });
  };

  const setIsShowingCreateShipmentModal = (payload) => {
    dispatch({
      type: ACTIONS.SET_IS_SHOWING_CREATE_SHIPMENT_MODAL,
      payload,
    });
  };

  const setConfirmPasswordModalArgs = (payload) => {
    dispatch({
      type: ACTIONS.SET_CONFIRM_PASSWORD_MODAL_ARGS,
      payload,
    });
  };

  const setConfirmPasswordModalExecutionStatus = (payload) => {
    dispatch({
      type: ACTIONS.SET_CONFIRM_PASSWORD_MODAL_EXECUTION_STATUS,
      payload,
    });
  };

  const setIsShowingConfirmPasswordModal = (payload) => {
    dispatch({
      type: ACTIONS.SET_IS_SHOWING_CONFIRM_PASSWORD_MODAL,
      payload,
    });
  };

  const setConfirmActionModalArgs = (payload) => {
    dispatch({
      type: ACTIONS.SET_CONFIRM_ACTION_MODAL_ARGS,
      payload,
    });
  };

  const setConfirmActionModalExecutionStatus = (payload) => {
    dispatch({
      type: ACTIONS.SET_CONFIRM_ACTION_MODAL_EXECUTION_STATUS,
      payload,
    });
  };

  const setIsShowingConfirmActionModal = (payload) => {
    dispatch({
      type: ACTIONS.SET_IS_SHOWING_CONFIRM_ACTION_MODAL,
      payload,
    });
  };

  useEffect(async () => {
    const response = await fetch(`${state.apiBaseUrl}/geo`, {
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });

    const { data } = await response.json();

    if (data) {
      const { geo } = data;

      setGeolocation({
        geolocation: geo,
      });
    }
  }, []);

  const value = {
    ...state,
    setPageTransition,
    showOverlayLoader,
    hideOverlayLoader,
    startApiRequest,
    completeApiRequest,
    clearApiRequests,
    setPageDescription,
    setPageTitle,
    resetGlobal,
    setShowNav,
    setReviewIdToReport,
    setCreateShipmentModalArgs,
    setIsShowingCreateShipmentModal,
    setConfirmPasswordModalArgs,
    setConfirmPasswordModalExecutionStatus,
    setIsShowingConfirmPasswordModal,
    setConfirmActionModalArgs,
    setConfirmActionModalExecutionStatus,
    setIsShowingConfirmActionModal,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
