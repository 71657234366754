import React, { useEffect, useState } from "react";

//COMPONENTS
import Notice from "./Notice";
import Alert from "./Alert";

//HOOKS
import { useGlobal } from "../services/hooks/useGlobal";
import { useConfirmPassword } from "../services/hooks/useConfirmPassword";
import { useForm } from "react-hook-form";

//PLUGINS

//HELPERS
import { joiResolver } from "../services/validations/resolvers/joiResolver";
import schema from "../services/validations/confirmPasswordSchema";
import openModal from "../util/openModal";
import closeModals from "../util/closeModals";

export default React.memo(function ConfirmPasswordModal() {
  const {
    isShowingConfirmPasswordModal,
    confirmPasswordModalArgs,
    setIsShowingConfirmPasswordModal,
  } = useGlobal();

  const { executionFn, executionArgs, executionStatus, warningMessage } =
    confirmPasswordModalArgs;

  const [isProcessing, setIsProcessing] = useState(false);

  const {
    confirmPassword,
    confirmPasswordError,
    isConfirmingPassword,
    confirmedPassword,
    resetConfirmPassword,
  } = useConfirmPassword();

  const { handleSubmit, register, errors, formState, setValue } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: joiResolver(schema),
  });

  const { isValid } = formState;

  const handleConfirmPassword = (data) => {
    if (isValid) {
      const { password } = data;

      confirmPassword(password);
    }
  };

  useEffect(() => {
    if (isShowingConfirmPasswordModal) {
      openModal("#confirm-password-modal", {
        onHide: () => {
          setIsShowingConfirmPasswordModal({
            isShowingConfirmPasswordModal: false,
          });
        },
      });
    } else {
      closeModals("#confirm-password-modal");
    }
  }, [isShowingConfirmPasswordModal]);

  useEffect(() => {
    if (typeof executionStatus === "boolean") {
      return setIsProcessing(isConfirmingPassword || executionStatus);
    }

    if (typeof executionStatus === "string") {
      return setIsProcessing(
        isConfirmingPassword || executionStatus === "pending"
      );
    }

    return setIsProcessing(isConfirmingPassword);
  }, [executionStatus, isConfirmingPassword]);

  useEffect(() => {
    if (confirmedPassword === true && typeof executionFn === "function") {
      executionFn(executionArgs);
    }
  }, [confirmedPassword, executionFn, executionArgs]);

  useEffect(() => {
    if (!isProcessing && !confirmPasswordError) {
      setValue("password", "");
      closeModals("#confirm-password-modal");
      resetConfirmPassword();
    }
  }, [isProcessing]);

  if (!isShowingConfirmPasswordModal) {
    return null;
  }

  return (
    <div
      className="modal fade"
      id="confirm-password-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="fe fe-x" aria-hidden="true"></i>
          </button>

          <div className="modal-header line-height-fixed font-size-lg">
            <strong className="mx-auto">Password Confirmation</strong>
          </div>

          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                {warningMessage && (
                  <Alert
                    type="warning"
                    icon="warning"
                    message={warningMessage}
                    center={true}
                  />
                )}

                <p className="font-size-sm text-center text-gray-500">
                  Please confirm your password to continue
                </p>
              </div>
            </div>
            <form onSubmit={handleSubmit(handleConfirmPassword)}>
              {confirmPasswordError && (
                <Notice
                  type="danger"
                  message={confirmPasswordError}
                  scroll={false}
                />
              )}

              <div className="form-row mb-2">
                <div className="col-12">
                  <div className="form-group">
                    <label className="sr-only" htmlFor="password">
                      Password
                    </label>
                    <input
                      ref={register}
                      className={`form-control form-control-sm ${
                        errors.password ? "is-invalid" : ""
                      }`}
                      id="password"
                      type="password"
                      name="password"
                      placeholder="Current Password"
                    />
                    {errors.password && (
                      <span className="input-error">
                        Please enter a password
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <button
                    className="btn btn-dark"
                    type="submit"
                    disabled={isProcessing}
                  >
                    {isProcessing ? "Confirming..." : "Continue"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});
