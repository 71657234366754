import { useState, useEffect, useContext } from "react";
import { useApiCall } from "./useApiCall";
import { useAuth } from "./useAuth";
import { useRemember } from "./useRemember";

export const useRecoverCart = () => {
  const [recoverCartError, setRecoverCartError] = useState();
  const [recoverCartStatus, setRecoverCartStatus] = useState("idle");
  const [recoveredCartItems, setRecoveredCartItems] = useState();

  const { user } = useAuth();

  const { retrieveLocalValue } = useRemember();

  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();

  const recoverCart = () => {
    setRecoverCartError();

    if (!user) {
      setRecoveredCartItems(retrieveLocalValue("cartItems") || []);
      setRecoverCartStatus("ready");
      return;
    }

    if (user) {
      const requestOptions = {
        url: `/carts`,
      };

      setRecoverCartStatus("pending");
      executeApiCall(requestOptions);
    }
  };

  useEffect(() => {
    switch (apiCallStatus) {
      case "success": {
        if (apiCallResponse?.data?.data) {
          const { cartItems } = apiCallResponse?.data?.data || null;

          if (cartItems) {
            setRecoveredCartItems(cartItems);
          }

          setRecoverCartStatus("ready");
        } else {
          setRecoverCartError(apiCallResponse?.data?.error?.userMessage);
          setRecoverCartStatus("ready");
        }
        break;
      }

      case "error": {
        setRecoverCartStatus("ready");
        break;
      }
    }
  }, [apiCallStatus, apiCallResponse]);

  return {
    recoverCart,
    recoverCartError,
    recoverCartStatus,
    recoveredCartItems,
  };
};
