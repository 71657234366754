import React, { useEffect, useState } from "react";
import Overlay from "./Overlay";
import Loader from "./Loader";
import { useGlobal } from "../services/hooks/useGlobal";

const OverlayLoader = React.memo(({ textColor = "#000" }) => {
  const {
    isShowingOverlayLoader,
    overlayLoaderMessage,
    overlayLoaderSubMessage,
    overlayLoaderType,
    overlayLoaderSubMessageTransitionDelay,
  } = useGlobal();

  const [subMessageIndex, setSubMessageIndex] = useState(0);
  const [subMessage, setSubMessage] = useState("");

  useEffect(() => {
    if (
      isShowingOverlayLoader &&
      Array.isArray(overlayLoaderSubMessage) &&
      subMessageIndex < overlayLoaderSubMessage.length
    ) {
      const intervalId = setInterval(() => {
        setSubMessageIndex((subMessageIndex) => ++subMessageIndex);
      }, overlayLoaderSubMessageTransitionDelay ?? 5000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isShowingOverlayLoader, overlayLoaderSubMessage]);

  useEffect(() => {
    if (Array.isArray(overlayLoaderSubMessage)) {
      if (subMessageIndex < overlayLoaderSubMessage.length) {
        setSubMessage(overlayLoaderSubMessage[subMessageIndex]);
      }
    } else {
      setSubMessage(overlayLoaderSubMessage);
    }
  }, [overlayLoaderSubMessage, subMessageIndex]);

  useEffect(() => {
    if (!isShowingOverlayLoader) {
      setSubMessageIndex(0);
    }
  }, [isShowingOverlayLoader]);

  if (isShowingOverlayLoader) {
    return (
      <div id="#overlay-loader">
        <Overlay fixPosition type={overlayLoaderType} />
        <Loader fixedCenter />
        {overlayLoaderMessage && (
          <p
            className="fixed-center mt-12 mt-md-10 text-center"
            style={{ color: textColor }}
          >
            <span className="d-none d-md-block font-size-h3">
              {overlayLoaderMessage}
            </span>
            <span className="d-md-none font-size-h5">
              {overlayLoaderMessage}
            </span>
          </p>
        )}

        {subMessage && (
          <div
            className="fixed-center mt-14 mt-md-12 text-muted text-center"
            style={{ fontSize: "0.9rem" }}
          >
            {subMessage}
          </div>
        )}
      </div>
    );
  }

  return null;
});

export default OverlayLoader;
