import React, { createContext, useReducer, useState, useEffect } from "react";
import cartReducer from "../reducers/cartReducer";
import hasSubscription from "../../services/lib/cart/cartHasSubscription";
import isValidCoupon from "../../services/lib/coupons/isValidCoupon";
import { useGlobal } from "../../services/hooks/useGlobal";
import { useIsWaitlisting } from "../../services/hooks/useIsWaitlisting";
import { useRecoverCart } from "../../services/hooks/useRecoverCart";
import { useAuth } from "../../services/hooks/useAuth";
import { useSnackbar } from "../../services/hooks/useSnackbar";
import { useRouter } from "next/router";

//Actions
export const ACTIONS = {
  SET_STATE: "SetState",
  SET_CART_ITEMS: "SetCartItems",
  SET_CART_TOTAL: "SetCartTotal",
  ADD_TO_CART: "AddItemToCart",
  REMOVE_ITEM_FROM_CART: "RemoveItemFromCart",
  EMPTY_CART: "EmptyCart",
  UPDATE_CART_ITEM_QUANTITY: "UpdateCartItemQty",
  APPLY_COUPON: "ApplyCoupon",
  REMOVE_COUPON: "RemoveCoupon",
  SET_SALES_TAX_RATE: "SetSalesTaxRate",
  SET_SHIPPING_FEE: "SetShippingFee",
  SET_FREE_SHIPPING: "SetFreeShipping",
  SET_SHIPPING_MULTIPLE: "SetShippingMultiple",
  SET_WAITLIST_TOTAL_DUE: "SetWaitlistTotalDue",
  SET_IS_VALID_CART: "SetIsValidCart",
  SET_CART_ERRORS: "SetCartItemError",
  SET_HAS_INITIALIZED_CART: "SetHasInitializedCart",
  SET_CART_VALIDATION_WORKER: "SetCartValidationWorker",
  SET_CART_RETENTION_WORKER: "SetCartRetentionWorker",
  SET_CART_HAS_SUBSCRIPTION: "SetCartHasSubscription",
  SET_IS_LOYALTY_POINTS_APPLIED: "SetIsLoyaltyPointsApplied",
  SET_HAS_UPDATED_LOYALTY_POINTS_BALANCE: "SetHasUpdatedLoyaltyPointsBalance",
};

const WAITLIST_MESSAGE = `Due to limited availability, you're going to be placed on a Waitlist. You'll not be charged yet for the subscription plan in your cart. Any other item(s) in your cart will be processed and may be shipped out separately. We'll attempt to charge the payment method you provided once a spot opens up and you'll be notified.`;

export const ITEM_QUANTITY_LIMIT = 3;
export const CART_ITEMS_LIMIT = 20;

// Initial state
export const initialState = {
  cartItems: [],
  cartErrors: {},
  cartSubTotal: 0,
  cartTotal: 0,
  shippingFee: 0,
  shippingMultiple: 1,
  freeShipping: false,
  salesTaxRate: 0,
  salesTaxAmount: 0,
  salesTaxId: "",
  discount: 0,
  couponCode: {},
  maxProductLimit: 3,
  waitlistMessage: WAITLIST_MESSAGE,
  waitlistTotalDue: 0,
  isValidCart: true,
  hasInitializedCart: false,
  cartValidationWorker: null,
  cartRetentionWorker: null,
  cartHasSubscription: false,
  isLoyaltyPointsApplied: false,
  hasUpdatedLoyaltyPointsBalance: false,
};

// Create context
export const CartContext = createContext(initialState);

// Provider component
export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);
  const { apiBaseUrl } = useGlobal();
  const { user, accessToken, authStatus } = useAuth();
  const { isWaitlisting } = useIsWaitlisting();
  const { recoverCart, recoverCartStatus, recoveredCartItems } =
    useRecoverCart();
  const { addSnackbar } = useSnackbar();
  const router = useRouter();

  const setCartItems = (payload) => {
    dispatch({
      type: ACTIONS.SET_CART_ITEMS,
      payload,
    });
  };

  const addToCart = (payload) => {
    dispatch({
      type: ACTIONS.ADD_TO_CART,
      payload,
    });
  };

  const removeFromCart = (payload) => {
    dispatch({
      type: ACTIONS.REMOVE_ITEM_FROM_CART,
      payload,
    });
  };

  const emptyCart = (payload) => {
    dispatch({
      type: ACTIONS.EMPTY_CART,
      payload,
    });
  };

  const updateCartItemQuantity = (payload) => {
    dispatch({
      type: ACTIONS.UPDATE_CART_ITEM_QUANTITY,
      payload,
    });
  };

  const applyCoupon = (payload) => {
    dispatch({
      type: ACTIONS.APPLY_COUPON,
      payload,
    });
  };

  const removeCoupon = (payload) => {
    dispatch({
      type: ACTIONS.REMOVE_COUPON,
      payload,
    });
  };

  const setSalesTaxRate = (payload) => {
    dispatch({
      type: ACTIONS.SET_SALES_TAX_RATE,
      payload,
    });
  };

  const setShippingFee = (payload) => {
    dispatch({
      type: ACTIONS.SET_SHIPPING_FEE,
      payload,
    });
  };

  const setFreeShipping = (payload) => {
    dispatch({
      type: ACTIONS.SET_FREE_SHIPPING,
      payload,
    });
  };

  const setShippingMultiple = (payload) => {
    dispatch({
      type: ACTIONS.SET_SHIPPING_MULTIPLE,
      payload,
    });
  };

  const setWaitlisting = (payload) => {
    dispatch({
      type: ACTIONS.SET_WAITLISTING,
      payload,
    });
  };

  const setWaitlistTotalDue = (payload) => {
    dispatch({
      type: ACTIONS.SET_WAITLIST_TOTAL_DUE,
      payload,
    });
  };

  const setIsValidCart = (payload) => {
    dispatch({
      type: ACTIONS.SET_IS_VALID_CART,
      payload,
    });
  };

  const setCartErrors = (payload) => {
    dispatch({
      type: ACTIONS.SET_CART_ERRORS,
      payload,
    });
  };

  const setHasInitializedCart = (payload) => {
    dispatch({
      type: ACTIONS.SET_HAS_INITIALIZED_CART,
      payload,
    });
  };

  const setCartValidationWorker = (payload) => {
    dispatch({
      type: ACTIONS.SET_CART_VALIDATION_WORKER,
      payload,
    });
  };

  const setCartRetentionWorker = (payload) => {
    dispatch({
      type: ACTIONS.SET_CART_RETENTION_WORKER,
      payload,
    });
  };

  const setCartHasSubscription = (payload) => {
    dispatch({
      type: ACTIONS.SET_CART_HAS_SUBSCRIPTION,
      payload,
    });
  };

  const setIsLoyaltyPointsApplied = (payload) => {
    dispatch({
      type: ACTIONS.SET_IS_LOYALTY_POINTS_APPLIED,
      payload,
    });
  };

  const setHasUpdatedLoyaltyPointsBalance = (payload) => {
    dispatch({
      type: ACTIONS.SET_HAS_UPDATED_LOYALTY_POINTS_BALANCE,
      payload,
    });
  };

  const resetCart = () => {
    dispatch({
      type: ACTIONS.SET_STATE,
      payload: initialState,
    });
  };

  useEffect(() => {
    recoverCart();

    if (!state.cartValidationWorker) {
      setCartValidationWorker({
        cartValidationWorker: new Worker("/js/workers/cartValidationWorker.js"),
      });
    }

    if (!state.cartRetentionWorker) {
      setCartRetentionWorker({
        cartRetentionWorker: new Worker("/js/workers/cartRetentionWorker.js"),
      });
    }

    if (!state.hasInitializedCart) {
      setHasInitializedCart({
        hasInitializedCart: true,
      });
    }
  }, []);

  useEffect(() => {
    if (user && authStatus === "ready") {
      recoverCart();
    }
  }, [user, authStatus]);

  useEffect(() => {
    if (recoveredCartItems) {
      setCartItems({ cartItems: recoveredCartItems });
    }
  }, [recoveredCartItems]);

  useEffect(() => {
    setIsValidCart({
      isValidCart: !Object.keys(state.cartErrors).length,
    });
  }, [state.cartErrors]);

  useEffect(() => {
    setCartHasSubscription({
      cartHasSubscription: hasSubscription(state.cartItems),
    });
  }, [state.cartItems]);

  useEffect(() => {
    if (state.couponCode?.id) {
      const { error } = isValidCoupon(state.couponCode, {
        user,
        cartItems: state.cartItems,
      });

      if (error) {
        addSnackbar(`${error} The coupon has been removed from your cart.`, {
          type: "danger",
        });

        removeCoupon();
      }
    }
  }, [state.couponCode, state.cartItems]);

  useEffect(() => {
    if (state.couponCode?.id) {
      setIsLoyaltyPointsApplied({
        isLoyaltyPointsApplied: false,
      });

      setHasUpdatedLoyaltyPointsBalance({
        hasUpdatedLoyaltyPointsBalance: false,
      });
    }
  }, [state.couponCode]);

  useEffect(async () => {
    const { apc } = router.query;

    if (!state.couponCode?.id && apc) {
      const response = await fetch(`${apiBaseUrl}/coupons/${apc}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const { data } = await response.json();

      if (data) {
        const { coupon } = data;

        const { error } = isValidCoupon(coupon, {
          user,
          cartItems: state.cartItems,
        });

        if (error) {
          addSnackbar(error, {
            type: "danger",
          });
        } else {
          applyCoupon({
            couponCode: coupon,
          });
        }
      }
    }

    if (state.couponCode?.id && apc) {
      if (state.couponCode.id.toLowerCase() === apc.toLowerCase()) {
        addSnackbar(
          `Coupon code ${state.couponCode.id.toUpperCase()} was automatically applied.`,
          {
            type: "success",
          }
        );
      }
    }
  }, [router, state.couponCode]);

  useEffect(() => {
    if (state.hasInitializedCart && recoverCartStatus === "ready") {
      if (state.cartValidationWorker) {
        state.cartValidationWorker.postMessage({ cartItems: state.cartItems });

        state.cartValidationWorker.onmessage = (e) => {
          const { cartErrors } = e.data;

          if (cartErrors) {
            setCartErrors({
              cartErrors,
            });
          }
        };
      }
    }
  }, [
    state.cartValidationWorker,
    state.cartItems,
    state.hasInitializedCart,
    recoverCartStatus,
  ]);

  useEffect(() => {
    if (state.hasInitializedCart && recoverCartStatus === "ready") {
      if (state.cartRetentionWorker && accessToken) {
        state.cartRetentionWorker.postMessage({
          cartItems: state.cartItems,
          accessToken,
        });
      }
    }
  }, [
    state.cartRetentionWorker,
    state.cartItems,
    state.hasInitializedCart,
    recoverCartStatus,
    accessToken,
  ]);

  const value = {
    ...state,
    isWaitlisting,
    setCartItems,
    addToCart,
    removeFromCart,
    emptyCart,
    updateCartItemQuantity,
    applyCoupon,
    removeCoupon,
    setSalesTaxRate,
    setShippingFee,
    setFreeShipping,
    setShippingMultiple,
    setWaitlistTotalDue,
    setIsLoyaltyPointsApplied,
    setHasUpdatedLoyaltyPointsBalance,
    setIsValidCart,
    setCartErrors,
    setHasInitializedCart,
    setCartValidationWorker,
    setCartRetentionWorker,
    setCartHasSubscription,
    resetCart,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
