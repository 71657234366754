import React, { useState } from "react";

export default function TypeAheadInput({
  inputRef,
  options = [],
  className = "",
  placeholder = "Search",
}) {
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    const value = e.target.value;

    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, `i`);

      setSuggestions(options.sort().filter((v) => regex.test(v)));
    }

    setInputValue(value);
  };

  const handleInputFocus = () => {
    setSuggestions(options);
  };

  const handleInputBlur = () => {
    setSuggestions([]);
  };

  const handleSelectSuggestion = (e) => {
    const suggestion = e.target.dataset.searchSuggestion;

    if (inputRef?.current && suggestion) {
      const valueSetter = Object.getOwnPropertyDescriptor(
        inputRef.current,
        "value"
      ).set;

      const prototype = Object.getPrototypeOf(inputRef.current);

      const prototypeValueSetter = Object.getOwnPropertyDescriptor(
        prototype,
        "value"
      ).set;

      if (valueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter.call(inputRef.current, suggestion);
      } else {
        valueSetter.call(inputRef.current, suggestion);
      }

      inputRef.current.dispatchEvent(new Event("input", { bubbles: true }));
    }

    setSuggestions([]);
  };

  return (
    <div className="form-group">
      <input
        ref={inputRef}
        className={className}
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />

      {suggestions.length ? (
        <ul className="list-group">
          {suggestions.map((suggestion) => (
            <li
              key={suggestion}
              className="list-group-item clickable"
              data-search-suggestion={suggestion}
              onMouseDown={handleSelectSuggestion}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
    </div>
  );
}
