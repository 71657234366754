import { useState, useEffect } from "react";
import { useApiCall } from "./useApiCall";

export const useReviewReport = () => {
  const [reviewReport, setReviewReport] = useState();
  const [reviewReportError, setReviewReportError] = useState();
  const [reviewReportStatus, setReviewReportStatus] = useState("idle");

  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();

  const reportReview = (report) => {
    let url = `/reviews/${report.reviewId}/report`;

    const requestOptions = {
      url,
      method: "POST",
      data: report,
    };

    executeApiCall(requestOptions);

    setReviewReportError();
    setReviewReportStatus("pending");
  };

  const resetReportReview = () => {
    setReviewReport();
    setReviewReportError();
    setReviewReportStatus("idle");
  };

  useEffect(() => {
    switch (apiCallStatus) {
      case "success":
        if (apiCallResponse.data.data) {
          const { reported } = apiCallResponse.data.data;

          setReviewReport(reported);
          setReviewReportStatus("ready");
        } else {
          setReviewReportError(apiCallResponse.data.error.userMessage);
          setReviewReportStatus("ready");
        }
        break;
      case "error":
        setReviewReportStatus("ready");
        break;
    }
  }, [apiCallStatus, apiCallResponse]);

  return {
    reviewReport,
    reviewReportError,
    reviewReportStatus,
    reportReview,
    resetReportReview,
  };
};
