import React from "react";
import Link from "next/link";

const CookieNotice = React.memo(({ onDisableAll, onAllowAll }) => {
  return (
    <div className="card">
      <div
        className="card-body d-flex flex-column fixed-bottom align-items-center bg-dark text-white"
        style={{ maxWidth: "1700px" }}
      >
        <p>
          By clicking “Allow All”, you agree to the storing of cookies on your
          device to enhance site navigation, analyze site usage, and assist in
          our marketing efforts.{" "}
          <Link href="/privacy">
            <a>Privacy &amp; Cookie Policy</a>
          </Link>
        </p>

        <div className="btn-group" role="group">
          <button
            type="button"
            role="button"
            className="btn btn-sm btn-secondary mx-2"
            data-toggle="modal"
            data-target="#dataSettingsModal"
          >
            Customize
          </button>
          <button
            type="button"
            role="button"
            className="btn btn-sm btn-primary mx-2"
            onClick={onAllowAll}
          >
            Allow All
          </button>
        </div>
      </div>
    </div>
  );
});

export default CookieNotice;
