import { useContext } from "react";
import { CartContext } from "../../state/contexts/cartState";
import { useSnackbar } from "./useSnackbar";
import openModal from "../../util/openModal";
import closeModals from "../../util/closeModals";

export const useCart = () => {
  const {
    cartItems,
    addToCart,
    removeFromCart,
    setIsLoyaltyPointsApplied,
    setHasUpdatedLoyaltyPointsBalance,
    ...rest
  } = useContext(CartContext);

  const { addSnackbar } = useSnackbar();

  const isInCart = (productId) => {
    return cartItems.some(({ product }) => product.id === productId);
  };

  const openCart = () => {
    openModal("#modalShoppingCart");
  };

  const closeCart = () => {
    closeModals("#modalShoppingCart");
  };

  const handleAddToCart = (payload, options = {}) => {
    addToCart(payload);

    const { silent } = options;

    if (!silent) {
      addSnackbar("Added to cart", {
        type: "success",
        callToAction: "View cart",
        callToActionFn: openCart,
        callToActionBtnType: "light",
      });
    }
  };

  const handleRemoveFromCart = (payload, options = {}) => {
    removeFromCart(payload);

    const { silent } = options;

    if (!silent) {
      addSnackbar("Removed from cart", {
        type: "success",
        callToAction: "View cart",
        callToActionFn: openCart,
        callToActionBtnType: "light",
      });
    }
  };

  return {
    cartItems,
    openCart,
    closeCart,
    isInCart,
    addToCart: handleAddToCart,
    removeFromCart: handleRemoveFromCart,
    setIsLoyaltyPointsApplied,
    setHasUpdatedLoyaltyPointsBalance,
    ...rest,
  };
};
