import Joi from "joi";

const schema = Joi.object({
  email: Joi.alternatives()
    .conditional("email", {
      is: Joi.string(),
      then: Joi.string()
        .email({ tlds: { allow: false } })
        .lowercase()
        .required(),
      otherwise: Joi.optional().allow(null, ""),
    })
    .optional(),
  password: Joi.string().required(),
});

export default schema;
